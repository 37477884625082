/* eslint-disable no-async-promise-executor */
<template>
  <div>
    <div v-html="upgradedContent" v-if="upgradedContent !== undefined"></div>
    <button
      class="button is-small is-blz-blue lab-container-shadow"
      @click="downloadPDF(entry.metaData.fileName)"
      v-if="entry.metaData.entry_type === 10"
    >
      <span class="icon is-medium">
        <i class="mdi mdi-download"></i>
      </span>
      <span><strong> Open File </strong></span>
    </button>
  </div>
</template>
<script>
import { parse as htmlParser, stringify } from 'himalaya';
import XhrClient from '@/services/XhrClient';
export default {
  name: 'LabBookEntry',
  data: () => ({
    upgradedContent: undefined,
  }),
  props: ['entry'],
  created() {
    this.upgradeEntry().then((content) => {
      this.upgradedContent = content;
    });
  },
  mounted() {
    this.$nextTick(() => {
      this.addClickEvent();
    });
  },
  methods: {
    upgradeEntry() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        if (typeof this.entry.metaData.content !== 'undefined') {
          let workingContent = `${this.entry.metaData.content}`;
          const isImage = workingContent.includes('<img');
          try {
            workingContent = await this.formatTimestamp(workingContent);
            if (isImage) {
              resolve(workingContent);
            } else {
              workingContent = await this.generateLinks(workingContent);
              if (typeof workingContent !== 'undefined') {
                workingContent = await this.refineUrls(workingContent);
                resolve(workingContent);
              } else {
                resolve(workingContent);
              }
            }
          } catch (err) {
            console.error('ERROR PROCESSING ENTRY', err, this.entry);
          }
        } else {
          console.error('ENTRY CONTENT IS UNDEFINED', this.entry);
          resolve('<p>****************************************</p>');
        }
      });
    },
    formatTimestamp(workingContent) {
      return new Promise((resolve) => {
        let dt;
        if (typeof this.entry.timestamp === 'undefined') {
          dt = new Date();
        } else {
          dt = new Date(this.entry.timestamp);
        }
        let vs = dt.toLocaleString();
        let localContent;
        try {
          localContent = workingContent.replace('{lab_book_timestamp}', vs);
        } catch (err) {
          localContent = workingContent;
        }
        resolve(localContent);
      });
    },
    generateLinks(workingContent) {
      return new Promise((resolve) => {
        // eslint-disable-next-line no-useless-escape
        const exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
        const text1 = workingContent.replace(
          exp,
          "<a target='_blank' href='$1'>$1</a>",
        );
        // eslint-disable-next-line no-useless-escape
        const exp2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
        let linkedContent = text1.replace(
          exp2,
          '$1<a target="_blank" href="http://$2">$2</a>',
        );
        resolve(linkedContent);
      });
    },
    refineUrls(workingContent) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        try {
          const json = htmlParser(workingContent);
          if (typeof json !== 'undefined' && json.length > 0) {
            if (typeof json[0].children !== 'undefined') {
              let updated = [];
              while (json[0].children.length > 0) {
                let x = json[0].children.shift();
                if (x.type === 'element' && x.tagName === 'a') {
                  x = await this.getTitle(x);
                }
                updated.push(x);
              }
              json[0].children = updated;
            }
          }
          resolve(stringify(json));
        } catch (err) {
          console.error(
            '********************** HERE IS AN ERROR IN REFINEURLS',
            err,
          );
          resolve(workingContent);
        }
      });
    },
    getTitle(element) {
      return new Promise((resolve) => {
        for (let x = 0; x < element.attributes.length; x++) {
          const attribute = element.attributes[x];
          if (
            typeof attribute.key !== 'undefined' &&
            attribute.key === 'href'
          ) {
            let url = new URL(attribute.value);
            if (url.hostname.includes('presets')) {
              let params = {
                url: `https://presets-api.staging-beyondlabz.com/lab-book-resolve/preset`,
                body: {
                  id: `${url.pathname}`.replace('/', ''),
                },
                sendAuthHeader: false,
                method: 'POST',
              };
              XhrClient.request(params).then(
                (data) => {
                  if (typeof data.error !== 'undefined') {
                    resolve(element);
                  } else {
                    if (typeof data[0].object !== 'undefined') {
                      let decoded = JSON.parse(data[0].object);
                      element.children[0].content = decoded.name;
                    }
                    resolve(element);
                  }
                },
                (err) => {
                  console.error('ERROR TRYING TO GET PRESET', err);
                  resolve(element);
                },
              );
            } else if (url.hostname.includes('worksheets.beyondlabz.com')) {
              let params = {
                url: `https://presets-api.staging-beyondlabz.com/lab-book-resolve/worksheet`,
                body: {
                  id: `${attribute.value}`,
                },
                sendAuthHeader: false,
                method: 'POST',
              };
              XhrClient.request(params).then(
                (data) => {
                  if (typeof data.error !== 'undefined') {
                    resolve(element);
                  } else {
                    if (typeof data[0].name !== 'undefined') {
                      element.children[0].content = data[0].name;
                    } else {
                    }
                    resolve(element);
                  }
                },
                (err) => {
                  console.error('ERROR TRYING TO GET PRESET', err);
                  resolve(element);
                },
              );
            } else {
              resolve(element);
            }
          }
        }
      });
    },
    htmlContent(entry) {
      let dt;
      if (typeof entry.timestamp === 'undefined') {
        dt = new Date();
      } else {
        dt = new Date(entry.timestamp);
      }
      let vs = dt.toLocaleString();
      let string;
      try {
        string = entry.metaData.content.replace('{lab_book_timestamp}', vs);
      } catch (err) {
        console.error('ERROR REPLACING TIMESTAMP', entry);
        string = entry.metaData.content;
      }

      return string;
    },
    convertUrls(text) {
      // eslint-disable-next-line no-useless-escape
      const exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
      const text1 = text.replace(exp, "<a target='_blank' href='$1'>$1</a>");
      // eslint-disable-next-line no-useless-escape
      const exp2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;

      let hotLinked = text1.replace(
        exp2,
        '$1<a target="_blank" href="http://$2">$2</a>',
      );
      const json = htmlParser(hotLinked);
      let updated = [];
      if (json.length > 0) {
        while (json[0].children.length > 0) {
          let x = json[0].children.shift();
          updated.push(x);
        }
        json[0].children = updated;
      }
      return hotLinked;
    },
    updateUrl() {
      return new Promise((resolve) => {
        resolve();
      });
    },
    downloadPDF(fileName) {
      window.open(
        `https://assets-staging.api-beyondlabz.com/${fileName}`,
        '_blank',
      );
    },
    addClickEvent() {
      document.addEventListener('click', (e) => {
        if (
          e.target &&
          (e.target.id == 'copyButton' ||
            e.target.id == 'copyImg' ||
            e.target.id == 'copyText')
        ) {
          // this.animateButton(e);
          let tableElement =
            e.target.parentNode.nextSibling.nextSibling.firstChild.firstChild;
          this.copyTableData(tableElement);
        }
      });
    },
    copyTableData(el) {
      var body = document.body;
      var range;
      var sel;
      if (document.createRange && window.getSelection) {
        range = document.createRange();
        sel = window.getSelection();
        sel.removeAllRanges();
        try {
          range.selectNodeContents(el);
          sel.addRange(range);
        } catch (e) {
          range.selectNode(el);
          sel.addRange(range);
        }
        document.execCommand('copy');
      } else if (body.createTextRange) {
        range = body.createTextRange();
        range.moveToElementText(el);
        range.select();
        range.execCommand('Copy');
      }
      sel.removeAllRanges();
    },
  },
};
</script>
<style scoped>
#labBookWrapper .title-container .ripple {
  background-position: center center;
  transition: background 0.8s ease 0s;
}

.lab-container-shadow {
  box-shadow: 0 3px 3px #00000029;
}

#labBookWrapper .title-container .copy-data {
  display: flex;
  flex-direction: row;
  color: rgb(23, 70, 158);
  height: 27px;
  margin-left: auto;
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-color: initial;
  border-image: initial;
  border-radius: 5px;
  padding: 3px;
  outline: none;
  overflow: hidden;
  transition: background 0.4s ease 0s;
}

.is-blz-blue {
  background-color: rgba(23, 70, 158, 1) !important;
  color: #fff;
}
</style>
