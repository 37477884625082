<template>
  <section class="wrapper" :style="cssprops">
    <div id="tabs" :class="presetsTab" role="tablist">
      <div class="tabCell leftCell" @click="setPresetTab(constants.SYSTEM)" tabindex="0"
        @keyup.enter="setPresetTab(constants.SYSTEM)" id="systemTab" type="button" role="tab" :aria-selected="presetTab == 'SYSTEM'" aria-controls="systemPresets">
        {{ labPresets }}
      </div>
      <div class="tabCell rightCell" @click="setPresetTab(constants.USER)" tabindex="0"
        @keyup.enter="setPresetTab(constants.USER)" id="userTab" type="button" role="tab" :aria-selected="presetTab == 'USER'" aria-controls="userPresets">
        {{ mySavedLabs }}
      </div>
      <div class="tabSlider"></div>
    </div>
    <div class="tab-contents" role="tabpanel" tabindex="0" :aria-labeledby="presetTab == 'SYSTEM' ? 'systemTab' : 'userTab'">
      <ul v-if="presetsTab === constants.SYSTEM" id="systemPresets" :aria-selected="presetTab === constants.SYSTEM">
        <li v-for="(preset, index) in presetsSystem" :key="index" class="link-container">
          <div class="link" @click.exact="emitRestore(preset.object)" tabindex="0" role="button"
            @keyup.enter="emitRestore(preset.object)" :title="preset.object.name">
            {{ preset.object.name }}
          </div>
        </li>
      </ul>
      <ul v-if="presetsTab === constants.USER" id="userPresets" :aria-selected="presetTab === constants.USER">
        <li v-for="(link, index) in presetsUser" :key="index" class="link-container">
          <form @submit.prevent="editLink(index, link.object.name, false)">
            <input v-model="link.object.name" @blur="editLink(index, link.object.name)" class="input-box not-visible"
              :id="`input-${index}`" ref="linkInput"
              :aria-label="getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.PRESET_NAME)" title="Edit Preset Name"
              aria-label="Edit Preset Name" />
          </form>
          <div :id="`link-${index}`" class="link" @click.exact="emitRestore(link.object)" role="button"
            @click.shift.exact="updateSavedData(link)" tabindex="0" @keyup.enter="emitRestore(link.object)"
            :title="link.object.name">
            {{ link.object.name }}
          </div>

          <BLTooltip :text="editTooltip" align="right">
            <div class="icon-wrapper" @click="editPresetLink(index, link.object.name)" tabindex="0" role="button"
              @keyup.enter="editPresetLink(index, link.object.name)">
              <img src="../assets/nav_tray_icons/edit-24px.svg" class="edit-btn" alt="" role="button" />
            </div>
          </BLTooltip>
          <BLTooltip :text="copyTooltip" align="right">
            <div class="icon-wrapper" @click="copyPresetLink(link)" tabindex="0" role="button" @keyup.enter="copyPresetLink(link)">
              <img src="../assets/nav_tray_icons/Lab_Book_Icon_Copy.svg" class="copy-btn" alt="" role="button" />
            </div>
          </BLTooltip>
          <BLTooltip :text="deleteTooltip" align="right">
            <div class="icon-wrapper" @click="deletePresetLink(link)" tabindex="0" role="button" @keyup.enter="deletePresetLink(link)">
              <img src="../assets/nav_tray_icons/delete_forever-24px.svg" class="delete-btn" alt="" role="button" />
            </div>
          </BLTooltip>
        </li>
      </ul>
      <textarea v-if="isDebugging" name="saved-data" id="save-data" v-model="savedData" cols="50" rows="10"
        autocomplete="off"></textarea>
      <div v-if="isDebugging" @click="updateData()" outlined color="indigo" class="submit-btn" small>
        {{ submitPracticalLabel }}
      </div>
    </div>
    <div class="footer-wrapper">
      <div class="footer" id="footer" v-if="presetsTab === constants.USER">
        <div class="footer-content">
          <BLTooltip :text="addCopiedPresetTooltip" align="left">
            <div class="icon-wrapper">
              <img src="../assets/nav_tray_icons/add-24px.svg" class="add-btn" alt="add" @click="showInputBox()"
                tabindex="0" @keyup.enter="showInputBox()" />
            </div>
          </BLTooltip>
          <div id="add-link-text" :class="{ 'not-visible': isAddButtonClicked }">
            {{ addSavedLinkText }}
          </div>
          <div id="new-link-box" class="add-new-link-container" :class="{ 'not-visible': !isAddButtonClicked }">
            <div class="add-new-link-wrapper">
              <form @submit.prevent="addPreset()" id="newLinkForm" class="add-preset-form">
                <input class="add-link-input-box" id="new-link" :placeholder="inputboxPlaceholder" @input="fetchName()"
                  @blur="removeInputBox" @keydown.esc="removeInputBox" />
              </form>
              <BLTooltip :text="sendTooltip" align="right">
                <button type="submit" form="newLinkForm" class="icon-wrapper">
                  <img src="../assets/nav_tray_icons/Lab_Book_Icon_Send.svg" class="send-btn" @mousedown="addPreset()"
                    tabindex="0" @keyup.enter="addPreset()" new-link-form alt="send" />
                </button>
              </BLTooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex';
import * as StoreTypes from '../store/store-types';
import BiologyLabEnum from '../enums/BiologyLabEnum';
import Constants from '../enums/Constants';
import BLTooltip from './BLTooltip.vue';
import MicroscopyMiddlewareServices from '../services/MicroscopyMiddlewareServices';
import NotificationMixin from '../mixins/NotificationMixin.js';

export default {
  name: 'Presets',
  components: { BLTooltip },
  mixins: [NotificationMixin],
  data: function () {
    return {
      BiologyLabEnum,
      Constants,
      titleWidth: 0,
      savedData: '',
      clickedLink: '',
      oldLink: '',
      newLink: '',
      isAddButtonClicked: false,
      isDebugging: false,
      labPresets: this.getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.LAB_PRESET),
      mySavedLabs: this.getLocaleData(
        BiologyLabEnum.MICROSCOPY,
        Constants.MY_SAVED_LAB,
      ),
      editTooltip: this.getLocaleData(
        BiologyLabEnum.MICROSCOPY,
        Constants.EDIT_TITLE,
      ),
      copyTooltip: this.getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.COPY_LINK),
      deleteTooltip: this.getLocaleData(
        BiologyLabEnum.MICROSCOPY,
        Constants.DELETE_PRESET,
      ),
      sendTooltip: this.getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.SEND),
      addCopiedPresetTooltip: this.getLocaleData(
        BiologyLabEnum.MICROSCOPY,
        Constants.ADD_COPIED_PRESET,
      ),
      inputboxPlaceholder: this.getLocaleData(
        BiologyLabEnum.MICROSCOPY,
        Constants.ENTER_VALID_LINK,
      ),
      submitPracticalLabel: this.getLocaleData(
        BiologyLabEnum.MICROSCOPY,
        Constants.SUBMIT_PRACTICAL,
      ),
      addSavedLinkText: this.getLocaleData(
        BiologyLabEnum.MICROSCOPY,
        Constants.ADD_COPIED_PRESET_MESSAGE,
      ),
      constants: Constants,
      copyNotificationText: this.getLocaleData(
        BiologyLabEnum.MICROSCOPY,
        Constants.PRESET_COPIED,
      ),
      otherSavedLab: this.getLocaleData(
        BiologyLabEnum.MICROSCOPY,
        Constants.OTHER_SAVED_LAB,
      ),
      validLink: this.getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.VALID_LINK),
      invalidSavedLab: this.getLocaleData(
        BiologyLabEnum.MICROSCOPY,
        Constants.INVALID_SAVED_LAB,
      ),
    };
  },
  mounted: function () { },
  destroyed: function () { },
  computed: {
    cssprops: function () {
      return {
        '--input-box-width': this.titleWidth + 'px',
      };
    },
    ...mapGetters({
      userUUID: StoreTypes.GET_USER_UUID,
      presetsTab: StoreTypes.GET_ACTIVE_TAB,
      presetsUser: StoreTypes.PRESETS_USER,
      presetsSystem: StoreTypes.PRESETS_SYSTEM,
      presetsAutoLoad: StoreTypes.PRESETS_AUTOLOAD,
    }),
  },
  methods: {
    setPresetTab(tab) {
      this.$store.dispatch(StoreTypes.SET_PRESET_TAB_ACTION, tab);
      this.showPSNotification("Preset tab changed to " + tab);
    },
    deletePresetLink: function (presetObj) {
      let uuid = presetObj.uuid;
      MicroscopyMiddlewareServices.deletePreset(uuid).then(() => {
        MicroscopyMiddlewareServices.getPresetList(self.userUUID).then((data) => {
          this.$store.dispatch(StoreTypes.PRESET_LIST_ACTION, data);
        });
      });
    },
    emitRestore: function (labState) {
      this.$store.dispatch(
        StoreTypes.PRESET_RESTORE_ACTION,
        JSON.parse(JSON.stringify(labState)),
      );
      this.$store.dispatch(StoreTypes.RESTORE_LAB_ACTION, true);
    },
    updateSavedData: function (link) {
      this.isDebugging = true;
      this.savedData = JSON.stringify(link.object);
      this.clickedLink = link;
    },
    updateData: function () {
      let self = this;
      MicroscopyMiddlewareServices.savePreset(
        this.clickedLink.uuid,
        this.savedData,
      ).then(function (data) {
        self.$store.dispatch(StoreTypes.UPDATE_PRESET_ACTION, data);
        self.$emit(Constants.RESTORE_LAB_STATE, JSON.parse(data.object));
      });
      this.isDebugging = false;
    },
    copyPresetLink: function (presetObj) {
      let uuid = presetObj.uuid;
      const elem = document.createElement('textarea');
      elem.value = `${Constants.PRESET_URL}${uuid}`;
      let host = (document.referrer + '').replace('https://', '').split('.')[0];
      if (
        !window.location.href.includes('localhost') &&
        host != 'gibbs' &&
        host != ''
      ) {
        elem.value += `/${host}`;
      }
      document.body.appendChild(elem);
      elem.select();
      document.execCommand('copy');
      document.body.removeChild(elem);
      this.showNotification(this.copyNotificationText);
    },
    showInputBox: function () {
      this.isAddButtonClicked = true;
      this.$nextTick(() => {
        document.getElementById(Constants.NEW_HYPHEN_LINK).focus();
      });
    },
    removeInputBox: function () {
      document.getElementById(Constants.NEW_HYPHEN_LINK).value = '';
      this.isAddButtonClicked = false;
      this.presetToLoad = null;
    },
    fetchName: function () {
      const elemInput = document.getElementById(Constants.NEW_HYPHEN_LINK);
      const linkValue = elemInput.value.trim();
      this.presetToLoad = null;
      if (linkValue) {
        let uuid = linkValue.split(Constants.PRESET_URL)[1] || linkValue;
        uuid = uuid.split("/")[0] || uuid;
        let self = this;
        MicroscopyMiddlewareServices.fetchPreset(uuid).then(function (data) {
          if (!data.error) {
            let presetObj = JSON.parse(data.object);
            if (presetObj.labName !== Constants.MICROSCOPY) {
              self.showNotification(self.otherSavedLab);
            } else {
              elemInput.value = presetObj.name;
              self.presetToLoad = data;
              self.showNotification(self.validLink);
            }
          } else {
            self.showErrorNotification(self.invalidSavedLab);
          }
        });
      }
    },
    addPreset: function () {
      if (this.presetToLoad) {
        let self = this;
        MicroscopyMiddlewareServices.savePreset(
          undefined,
          self.presetToLoad.object,
        ).then(function (data) {
          self.$store.dispatch(StoreTypes.UPDATE_PRESET_ACTION, data);
        });
        this.removeInputBox();
      }
    },
    editPresetLink: function (index, link) {
      this.oldLink = link;
      this.$nextTick(() => {
        let inputBox = document.getElementById(Constants.INPUT_HYPHEN + index);
        const linkTitle = document.getElementById(
          `${Constants.LINK_HYPHEN}${Constants.ZERO}`,
        );
        if (linkTitle) {
          this.titleWidth = linkTitle.clientWidth;
        }
        inputBox.classList.remove(Constants.NOT_VISIBLE_CLASS);
        inputBox.focus();
        document
          .getElementById(Constants.LINK_HYPHEN + index)
          .classList.add(Constants.NOT_VISIBLE_CLASS);
      });
    },
    editLink: function (index, link, isAllowed = true) {
      if (isAllowed) {
        if (link) {
          this.newLink = link;
          this.replaceLinkInStorage(index);
        }
      }
      document
        .getElementById(Constants.INPUT_HYPHEN + index)
        .classList.add(Constants.NOT_VISIBLE_CLASS);
      document
        .getElementById(Constants.LINK_HYPHEN + index)
        .classList.remove(Constants.NOT_VISIBLE_CLASS);
    },
    replaceLinkInStorage: function (index) {
      if (this.oldLink !== this.newLink) {
        let currentPreset = this.presetsUser[index];
        currentPreset.object.name = this.newLink;
        let self = this;
        MicroscopyMiddlewareServices.savePreset(
          currentPreset.uuid,
          JSON.stringify(currentPreset.object),
        ).then(function (data) {
          self.$store.dispatch(StoreTypes.UPDATE_PRESET_ACTION, data);
        });
      }
    },
  },
};

</script>
<style scoped src="../styles/TrayPanelPresets.scss" lang="scss"></style>
