import * as Middleware from '../middleware';
// import ErrorAndWarningConstants from '../enums/ErrorAndWarningConstants';

const MicroscopyMiddlewareServices = {
  get_media: function(species) {
    return Middleware.get_media(species);
  },
  get_media_details: function(media) {
    return Middleware.get_media_details(media);
  },
  get_media_object: function(id, speciesID) {
    return Middleware.get_media_object(id, speciesID);
  },
  get_species_object: function(id) {
    return Middleware.get_species_object(id);
  },
  get_media_by_id: function(ids) {
    return Middleware.get_media_by_id(ids);
  },
  savePreset: function(uuid, object) {
    return new Promise(function(resolve) {
      Middleware.preset('save', {
        uuid: uuid,
        object: object,
      }).then(function(data) {
        resolve(data);
      });
    });
  },
  getPresetList: function(uuid) {
    return new Promise(function(resolve) {
      Middleware.preset('list', {
        uuid: uuid,
      }).then(function(data) {
        resolve(data);
      });
    });
  },
  fetchPreset: function(uuid) {
    return new Promise(function(resolve) {
      Middleware.preset('fetch', {
        uuid: uuid,
      }).then(function(data) {
        resolve(data);
      });
    });
  },
  deletePreset: function(uuid) {
    return new Promise(function(resolve) {
      Middleware.preset('delete', {
        uuid: uuid,
      }).then(function(data) {
        resolve(data);
      });
    });
  },
  getDataObj: function() {
    return Middleware.getDataObj();
  },
  getState: function() {
    return Middleware.getState();
  },
};

export default MicroscopyMiddlewareServices;
