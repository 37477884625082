import { createRouter, createWebHistory } from 'vue-router';
import Home from '../components/Home.vue';

// import store from '../store';

const routes = [
  {
    path: '/:uuid?',
    name: 'Home',
    component: Home,
    props: (route) => ({ uuid: route.params.uuid || 0 }),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;