<template>
  <template v-if="!timeReady || !isReady">
    <div class="splash">
      <SplashScreen ref="splashScreen" />
    </div>
  </template>
  <template v-if="portalReady">
    <TopNavBar />
    <main class="home justify-center h-screen w-screen">
      <div id="contentWrapper" class="contentWrapper" ref="activeArea">
        <div class="full activeAreaBar">
          <img src="../assets/general_lab_icons/BL_lab_bar_logo.svg" alt="Beyond Labz Logo"
            style="height: 24px; margin-left: 8px;" v-if="!isWhiteLabel" />
        </div>
        <Tray ref="tray" />
      </div>
    </main>
  </template>
</template>
<script>
import { mapGetters } from "vuex";
import SplashScreen from "./SplashScreen.vue";
import TopNavBar from "./TopNavBar.vue";
import Tray from "./Tray.vue";
import * as Middleware from '../middleware';
import * as LabBookMiddleware from './LabBook/scripts/LabBookMiddleware';
import biologyLabTexts from '../assets/json/BiologyLabTexts.json';
import biologyLabTextsEs from '@/assets/json/BiologyLabTextsEs.json';
import BiologyLabEnum from '../enums/BiologyLabEnum';
import Constants from '../enums/Constants';
import * as StoreTypes from '../store/store-types';
import MicroscopyMiddlewareServices from '../services/MicroscopyMiddlewareServices';
import XhrClient from "../services/XhrClient";
import jwtDecode from "jwt-decode";
import { initXAPI } from "@/services/xapi";
export default {
  name: "Home",
  data: () => ({
    portalReady: false,
    timeReady: false,
    benchContainerHeight: 0,
    benchContainerWidth: 0,
    labContainerWidth: 0,
    activeMaxHeight: 0,
    benchWidth: 0,
  }),
  props: ['uuid'],
  created() {
    if (
      window.top == window.self &&
      !window.origin.includes('localhost') &&
      !window.origin.includes('dev-')
    ) {
      window.location.replace(
        `https://gibbs.${process.env.VUE_APP_ACTIVE_DOMAIN}.com/biology-microscopy${window.location.pathname}`,
      );
      return;
    }
    if (window.top == window.self) {
      initXAPI({ actor: Constants.DEVELOPMENT }).then(() => {
        this.initMiddleware({});
      });
    } else {
      if (typeof this.id_token !== 'undefined') {
        this.tokenRefresh(this.id_token);
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.timeReady = true;
    }, 1000);
    window.addEventListener('resize', () => {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.onResize();
        });
      });
    });
    this.$nextTick(() => {
      this.onResize();
    });
    if (this.$props.uuid !== 0) {
      this.$store.dispatch(StoreTypes.EXTERNAL_PRESET_LOAD_ACTION, true);
    }
  },
  unmounted: function () {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    getCookie(name) {
      let matches = document.cookie.match(
        new RegExp(
          "(?:^|; )" +
          name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
          "=([^;]*)"
        )
      );
      return matches ? decodeURIComponent(matches[1]) : undefined;
    },
    initMiddleware(options) {
      this.$router.isReady().then(() => {
        let cookie = this.getCookie('lab_language');
        // cookie = 'es';
        let lang = 'en';
        if (!cookie) {
          var userLang = navigator.language || navigator.userLanguage;
          if (userLang.includes('es-') || userLang == 'es') {
            lang = 'es';
          }
        } else {
          lang = cookie;
        }
        // if localhost, set to spanish
        if (window.location.hostname.includes('localhost')) {
          lang = 'es';
        }
        this.$store.dispatch('SET_CURRENT_LANGUAGE', lang);
        let labTexts;
        switch (lang) {
          case "en":
          case "it":
          case "pt_br":
            labTexts = biologyLabTexts;
            break;
          case "es":
            labTexts = biologyLabTextsEs;
        }
        this.setLocaleData(BiologyLabEnum.MICROSCOPY, labTexts);
        options.lang = lang;
        let uuid = this.$props.uuid;
        Middleware.init(options).then(() => {
          const currentLab = this.getLocaleData(
            BiologyLabEnum.MICROSCOPY,
            Constants.LAB_TITLE_KEY,
          );
          this.$store.dispatch(StoreTypes.SET_CURRENT_LAB_ACTION, currentLab);
          this.$store.dispatch(StoreTypes.SET_STATE_FROM_MIDDLEWARE_ACTION, currentLab);

          MicroscopyMiddlewareServices.getPresetList(uuid).then(
            (data) => {
              this.$store.dispatch(StoreTypes.PRESET_LIST_ACTION, data);
              this.$store.dispatch(
                StoreTypes.SET_USER_UUID_ACTION,
                this.$props.uuid,
              );
            },
          );

          LabBookMiddleware.lab_book('init');
          this.$nextTick(function () {
            this.onResize();
          });

          this.portalReady = true;
        });
      });
    },
    processMessage(message) {
      if (typeof message.action !== 'undefined') {
        switch (message.action) {
          case 'id_token':
            this.tokenRefresh(message.id_token);
            this.$store.dispatch('SET_EMAIL', message.email);
            break;
        }
      }
    },
    tokenRefresh(id_token) {
      XhrClient.id_token = id_token;
      if (!this.portalReady) {
        let decoded = jwtDecode(id_token);
        this.id_token = id_token;
        initXAPI({ actor: decoded.sub }).then(() => {
          this.initMiddleware({ user: decoded.sub });
        });
      } else {
        this.id_token = id_token;
      }
    },
    onResize: function () {
      const TOP_NAVBAR_HEIGHT = 48;
      const TOP_BTM_PADDING = 14;
      const LEFT_RIGHT_PADDING = 30;
      const BEYONDLABZ_BAR_HEIGHT = 30;
      const MIN_TRAY_WIDTH = 450;
      const TRAY_WIDTH_WITHOUT_PADDING = 1250;
      let windowWidth = window.innerWidth;
      let windowHeight = window.innerHeight;

      let winMaxHeight =
        windowHeight -
        TOP_NAVBAR_HEIGHT -
        TOP_BTM_PADDING -
        BEYONDLABZ_BAR_HEIGHT;
      let winMaxWidth = windowWidth - LEFT_RIGHT_PADDING;

      let trayRatio = MIN_TRAY_WIDTH / TRAY_WIDTH_WITHOUT_PADDING;

      // calculates tray width
      let trayWidth = winMaxWidth * trayRatio; // Tray Should be 35%
      if (trayWidth < MIN_TRAY_WIDTH) trayWidth = MIN_TRAY_WIDTH;

      // calculates bench width & height
      let benchWidth = winMaxWidth - trayWidth;
      let benchHeight = benchWidth * (3 / 4);

      if (benchHeight > winMaxHeight) {
        benchHeight = winMaxHeight;
        benchWidth = benchHeight * (4 / 3);
      }

      let finalWidth = benchWidth + trayWidth;
      let finalHeight = benchHeight + BEYONDLABZ_BAR_HEIGHT;

      this.benchContainerWidth = finalWidth;
      this.benchContainerHeight = finalHeight;
      this.benchContainerMaxWidth = winMaxWidth;
      this.benchContainerMaxHeight = winMaxHeight;
      this.labContainerWidth = winMaxWidth;
      this.benchWidth = benchWidth;

      let self = this;
      try {
        let aa = this.$refs.activeArea;

        if (typeof aa !== 'undefined') {
          aa.style.width = `${finalWidth}px`;
          aa.style.height = `${finalHeight}px`;
          this.$refs.tray.resizeTray(finalWidth, finalHeight, trayWidth);
        } else {
          this.onResizeTimer = setTimeout(function () {
            self.onResize();
          }, 500);
        }
      } catch (e) {
        console.error('ERROR', e);
        this.onResizeTimer = setTimeout(function () {
          self.onResize();
        }, 500);
      }
    },
  },
  components: {
    SplashScreen,
    TopNavBar,
    Tray,
  },
  computed: {
    ...mapGetters({
      isReady: StoreTypes.IS_READY,
      id_token: 'GET_ID_TOKEN',
      whiteLabelLogo: 'GET_WHITE_LABEL_LOGO',
    }),
    isWhiteLabel() {
      return this.whiteLabelLogo !== '';
    },
    cssprops: function () {
      return {
        '--lab-width': this.benchContainerWidth + 'px',
        '--lab-height': this.benchContainerHeight + 'px',
        '--lab-max-width': this.benchContainerMaxWidth + 'px',
        '--lab-max-height': this.benchContainerMaxHeight + 'px',
        '--lab-container-width': this.labContainerWidth + 'px',
        '--bench-width': this.benchWidth + 'px',
      };
    },
  },
  watch: {},
};
</script>
<style scoped src="../styles/Home.scss" lang="scss"></style>
<style scoped></style>
