<template>
  <div
    class="tooltip-box"
    @mouseover="toggleVisible(true)"
    @mouseleave="toggleVisible(false)"
  >
    <slot />
    <div
      v-if="visible"
      class="tooltip"
      @mouseover.stop="toggleVisible(false)"
      :class="align"
    >
      <span class="text">{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bl-tooltip',
  props: {
    text: {
      type: String,
      required: true,
    },
    align: {
      type: String,
    },
  },
  data: function() {
    return {
      visible: false,
    };
  },
  methods: {
    toggleVisible: function(isvisible) {
      this.visible = isvisible;
    },
  },
};
</script>
<style scoped lang="scss">
.tooltip-box {
  position: relative;
  display: inline-block;
  height: fit-content;

  .tooltip {
    color: #fff;
    text-align: center;
    padding: 5px 5px;
    border-radius: 2px;
    white-space: nowrap;
    bottom: 100%;
    opacity: 0;
    transition: opacity 0.5s;
    position: absolute;
    z-index: 10;
    width: fit-content;
    background: black;
    cursor: default;
  }

  &:hover {
    .tooltip {
      opacity: 0.5;
    }
  }

  .left {
    left: 0;
  }

  .right {
    right: 0;
  }
}
</style>
