import { createStore } from 'vuex';
import MicroscopyMiddlewareServices from '../services/MicroscopyMiddlewareServices';
import BridgeModule from './modules/bridge/bridge-module';
import {
  // getters
  GET_CURRENT_LANGUAGE,
  GET_CURRENT_LAB,
  IS_READY,
  PANELS,
  GET_USER_UUID,
  PRESETS_USER,
  PRESETS_SYSTEM,
  PRESETS_AUTOLOAD,
  GET_ACTIVE_TAB,
  GET_CURRENT_LAB_TYPE,
  GET_SAVED_DATA_STATE,
  GET_LIVE_DATA_OBJECT,
  GET_LIVE_DATA_CANVAS_HEIGHT,
  EXTERNAL_PRESET_LOAD,
  GET_EMAIL,
  GET_SPECIES,
  GET_CATEGORY_LIST,
  GET_CURRENT_SPECIES,
  GET_CURRENT_MEDIA,
  GET_SAVE_LAB,
  GET_RESTORE_LAB,
  GET_IMAGE_DATA,
  GET_SPECIES_ZOOM_POPUP_OPEN,
  GET_SPECIES_SELECTOR_INFORMATION,
  GET_SPECIAL_SPECIES_FILTER,
  GET_SPECIAL_MEDIA_LIST,
  //mutations
  SET_CURRENT_LAB_MUTATION,
  LAB_READY_MUTATION,
  SET_PANEL_MUTATION,
  SET_PRESET_TAB_MUTATION,
  UPDATE_PRESET_MUTATION,
  SET_USER_UUID_MUTATION,
  PRESET_LIST_MUTATION,
  CLEAR_DATA_MUTATION,
  SAVE_LAB_MUTATION,
  PRESET_RESTORE_MUTATION,
  RESTORE_LAB_MUTATION,
  SET_AUTOLOAD_PRESET_LOADED_MUTATION,
  SET_LIVE_DATA_CANVAS_HEIGHT_MUTATION,
  EXTERNAL_PRESET_LOAD_MUTATION,
  SET_EMAIL_MUTATION,
  SET_STATE_FROM_MIDDLEWARE_MUTATION,
  SET_CURRENT_SPECIES_MUTATION,
  SET_CURRENT_MEDIA_MUTATION,
  SET_IMAGE_DATA_MUTATION,
  SET_SPECIES_ZOOM_POPUP_OPEN_MUTATION,
  SET_SPECIES_SELECTOR_INFORMATION_MUTATION,
  SET_SPECIAL_SPECIES_FILTER_MUTATION,
  SET_SPECIAL_MEDIA_LIST_MUTATION,
  //actions
  SET_CURRENT_LAB_ACTION,
  LAB_READY_ACTION,
  SET_PANEL_ACTION,
  SET_PRESET_TAB_ACTION,
  UPDATE_PRESET_ACTION,
  SET_USER_UUID_ACTION,
  PRESET_LIST_ACTION,
  CLEAR_DATA_ACTION,
  SAVE_LAB_ACTION,
  PRESET_RESTORE_ACTION,
  RESTORE_LAB_ACTION,
  SET_AUTOLOAD_PRESET_LOADED_ACTION,
  SET_LIVE_DATA_CANVAS_HEIGHT_ACTION,
  EXTERNAL_PRESET_LOAD_ACTION,
  SET_EMAIL,
  SET_STATE_FROM_MIDDLEWARE_ACTION,
  SET_CURRENT_SPECIES_ACTION,
  SET_CURRENT_MEDIA_ACTION,
  SET_IMAGE_DATA_ACTION,
  SET_SPECIES_ZOOM_POPUP_OPEN_ACTION,
  SET_SPECIES_SELECTOR_INFORMATION_ACTION,
  SET_SPECIAL_SPECIES_FILTER_ACTION,
  SET_SPECIAL_MEDIA_LIST_ACTION,
} from './store-types';
import languages from '../assets/localization/languages';
import PanelConstants from '../enums/PanelConstants';
import Constants from '../enums/Constants.js';
import {
  SET_LIVE_DATA_OBJECT_ACTION,
  SET_LIVE_DATA_OBJECT_MUTATION,
} from './store-types';
import { entry } from '../services/xapi';
import xapiEntryTypes from '../enums/xapiEntryTypes';

export default createStore({
  state: {
    debugMode: false,
    currentLab: '',
    currentLabType: 10,
    currentLanguage: languages[0].id,
    savedDataSet: [],
    liveDataInfo: null,
    saveLab: false,
    restoreLab: false,
    restorePresetData: null,
    presetTab: Constants.SYSTEM,
    externalPresetLoad: false,
    email: '',
    isReady: false,
    species: [],
    categoryList: [],
    currentSpecies: null,
    currentMedia: null,
    panels: PanelConstants.SPECIES,
    imageData: {},
    speciesZoomPopupOpen: false,
    speciesSelectorInformation: {
      dropdown: 0,
      button: null,
      search: '',
    },
    specialMediaList: [],
    specialSpeciesFilter: [],
    presetList: [],
    procedure: null,
  },
  getters: {
    [GET_CURRENT_MEDIA]: (state) => {
      return state.currentMedia;
    },
    [GET_CURRENT_LANGUAGE]: (state) => {
      return state.currentLanguage;
    },
    [GET_CURRENT_LAB]: (state) => {
      return state.currentLab;
    },
    [IS_READY]: (state) => {
      return state.isReady;
    },
    [GET_ACTIVE_TAB]: (state) => {
      return state.presetTab;
    },
    [GET_USER_UUID]: (state) => {
      return state.userUUId;
    },
    [PANELS]: (state) => {
      return state.panels;
    },
    [PRESETS_USER]: (state) => {
      let presets = state.presetList.filter(function (e) {
        return (
          e.owner_id !== Constants.SYSTEM_UPPERCASE &&
          e.owner_id !== Constants.AUTOLOAD_UPPERCASE
        );
      });
      return presets;
    },
    [PRESETS_SYSTEM]: (state) => {
      let presets = state.presetList.filter(function (e) {
        return e.owner_id === Constants.SYSTEM_UPPERCASE;
      });
      return presets;
    },
    [PRESETS_AUTOLOAD]: (state) => {
      let presets = [];
      if (!state.isAutoloadPresetLoaded) {
        presets = state.presetList.filter(function (e) {
          return e.owner_id === Constants.AUTOLOAD_UPPERCASE;
        });
      }
      return presets;
    },
    [GET_CURRENT_LAB_TYPE]: (state) => {
      return state.currentLabType;
    },
    [GET_SAVED_DATA_STATE]: (state) => {
      return state.savedDataSet;
    },
    [GET_LIVE_DATA_OBJECT]: (state) => {
      return state.liveDataInfo;
    },
    [GET_LIVE_DATA_CANVAS_HEIGHT]: (state) => {
      return state.liveDataCanvasHeight;
    },
    [EXTERNAL_PRESET_LOAD]: (state) => {
      return state.externalPresetLoad;
    },
    [GET_EMAIL]: (state) => {
      return state.email;
    },
    [GET_SPECIES]: (state) => {
      return state.species;
    },
    [GET_CATEGORY_LIST]: (state) => {
      return state.categoryList;
    },
    [GET_CURRENT_SPECIES]: (state) => {
      return state.currentSpecies;
    },
    [GET_SAVE_LAB]: (state) => {
      return state.saveLab;
    },
    [GET_RESTORE_LAB]: (state) => {
      return state.restoreLab;
    },
    [GET_IMAGE_DATA]: (state) => {
      return state.imageData;
    },
    [GET_SPECIES_ZOOM_POPUP_OPEN]: (state) => {
      return state.speciesZoomPopupOpen;
    },
    [GET_SPECIES_SELECTOR_INFORMATION]: (state) => {
      return state.speciesSelectorInformation;
    },
    [GET_SPECIAL_SPECIES_FILTER]: (state) => {
      return state.specialSpeciesFilter;
    },
    [GET_SPECIAL_MEDIA_LIST]: (state) => {
      return state.specialMediaList;
    },
    // []: (state) => {
    //   return state.;
    // },
    GET_PROCEDURE: (state) => {
      return state.procedure;
    },
  },
  actions: {
    SET_PROCEDURE: (context, procedure) => {
      context.commit('SET_PROCEDURE', procedure);
    },
    SET_CURRENT_LANGUAGE: ({ commit }, lang) => {
      commit("SET_CURRENT_LANGUAGE", lang)
    },
    [SET_CURRENT_MEDIA_ACTION]: (context, media) => {
      context.commit(SET_CURRENT_MEDIA_MUTATION, media);
    },
    [SET_CURRENT_LAB_ACTION]: (context, currentLab) => {
      context.commit(SET_CURRENT_LAB_MUTATION, currentLab);
    },
    [LAB_READY_ACTION]: (context, ready) => {
      context.commit(LAB_READY_MUTATION, ready);
    },
    [SET_PANEL_ACTION]: (context, panel) => {
      context.commit(SET_PANEL_MUTATION, panel);
    },
    [PRESET_LIST_ACTION]: ({ commit }, data) => {
      commit(PRESET_LIST_MUTATION, data);
    },
    [SET_PRESET_TAB_ACTION]: ({ commit }, tab) => {
      commit(SET_PRESET_TAB_MUTATION, tab);
    },
    [UPDATE_PRESET_ACTION]: ({ commit }, data) => {
      commit(UPDATE_PRESET_MUTATION, data);
    },
    [SET_USER_UUID_ACTION]: ({ commit }, payload) => {
      commit(SET_USER_UUID_MUTATION, payload);
    },
    [CLEAR_DATA_ACTION]: ({ commit }) => {
      commit(CLEAR_DATA_MUTATION);
    },
    [SET_LIVE_DATA_OBJECT_ACTION]: (context, payLoad) => {
      context.commit(SET_LIVE_DATA_OBJECT_MUTATION, payLoad);
    },
    [SAVE_LAB_ACTION]: (context, payLoad) => {
      context.commit(SAVE_LAB_MUTATION, payLoad);
    },
    [RESTORE_LAB_ACTION]: ({ commit }, payload) => {
      commit(RESTORE_LAB_MUTATION, payload);
    },
    [PRESET_RESTORE_ACTION]: ({ commit }, payload) => {
      commit(PRESET_RESTORE_MUTATION, payload);
    },
    [SET_AUTOLOAD_PRESET_LOADED_ACTION]: (context, payLoad) => {
      context.commit(SET_AUTOLOAD_PRESET_LOADED_MUTATION, payLoad);
    },
    [SET_LIVE_DATA_CANVAS_HEIGHT_ACTION]: (context, payLoad) => {
      context.commit(SET_LIVE_DATA_CANVAS_HEIGHT_MUTATION, payLoad);
    },
    [EXTERNAL_PRESET_LOAD_ACTION]: (context, payLoad) => {
      context.commit(EXTERNAL_PRESET_LOAD_MUTATION, payLoad);
    },
    [SET_EMAIL]: ({ commit }, data) => {
      commit(SET_EMAIL_MUTATION, data);
    },
    [SET_STATE_FROM_MIDDLEWARE_ACTION]: ({ commit }, data) => {
      commit(SET_STATE_FROM_MIDDLEWARE_MUTATION, data);
    },
    [SET_CURRENT_SPECIES_ACTION]: ({ commit }, species) => {
      commit(SET_CURRENT_SPECIES_MUTATION, species);
    },
    [SET_IMAGE_DATA_ACTION]: ({ commit }, data) => {
      commit(SET_IMAGE_DATA_MUTATION, data);
    },
    [SET_SPECIES_ZOOM_POPUP_OPEN_ACTION]: ({ commit }, data) => {
      commit(SET_SPECIES_ZOOM_POPUP_OPEN_MUTATION, data);
    },
    [SET_SPECIES_SELECTOR_INFORMATION_ACTION]: ({ commit }, data) => {
      commit(SET_SPECIES_SELECTOR_INFORMATION_MUTATION, data);
    },
    [SET_SPECIAL_SPECIES_FILTER_ACTION]: ({ commit }, data) => {
      commit(SET_SPECIAL_SPECIES_FILTER_MUTATION, data);
    },
    [SET_SPECIAL_MEDIA_LIST_ACTION]: ({ commit }, data) => {
      commit(SET_SPECIAL_MEDIA_LIST_MUTATION, data);
    },
    // []: ({ commit }, data) => {
    //   commit(, data);
    // },
  },
  mutations: {
    SET_PROCEDURE: (state, procedure) => {
      state.procedure = procedure;
    },
    SET_CURRENT_LANGUAGE: (state, lang) => {
      state.currentLanguage = lang;
    },
    [SET_CURRENT_MEDIA_MUTATION]: (state, media) => {
      state.currentMedia = media;
    },
    [SET_CURRENT_LAB_MUTATION]: (state, currentLab) => {
      state.currentLab = currentLab;
    },
    [LAB_READY_MUTATION]: (state, ready) => {
      state.isReady = ready;
    },
    [SET_PANEL_MUTATION]: (state, panel) => {
      state.panels = panel;
    },
    [PRESET_LIST_MUTATION]: (state, payload) => {
      state.presetList = payload;
    },
    [UPDATE_PRESET_MUTATION]: (state, data) => {
      let check = state.presetList.filter(function (e) {
        return e.uuid === data.uuid;
      });
      if (check.length === 0) {
        data.object = JSON.parse(data.object);
        state.presetList.push(data);
      } else {
        check[0].object = JSON.parse(data.object);
      }
    },
    [SET_PRESET_TAB_MUTATION]: (state, payload) => {
      state.presetTab = payload;
    },
    [SET_USER_UUID_MUTATION]: (state, payload) => {
      state.userUUId = payload;
    },
    [CLEAR_DATA_MUTATION]: (state) => {
      state.savedDataSet = [];
    },
    [SET_LIVE_DATA_OBJECT_MUTATION]: (state, payLoad) => {
      state.liveDataInfo = JSON.parse(JSON.stringify(payLoad));
    },
    [SAVE_LAB_MUTATION]: (state, payLoad) => {
      state.saveLab = payLoad;
    },
    [RESTORE_LAB_MUTATION]: (state, payload) => {
      state.restoreLab = payload;
    },
    [PRESET_RESTORE_MUTATION]: (state, payload) => {
      state.restorePresetData = payload;
    },
    [SET_AUTOLOAD_PRESET_LOADED_MUTATION]: (state, payLoad) => {
      state.isAutoloadPresetLoaded = payLoad;
    },
    [SET_LIVE_DATA_CANVAS_HEIGHT_MUTATION]: (state, payLoad) => {
      state.liveDataCanvasHeight = payLoad;
    },
    [EXTERNAL_PRESET_LOAD_MUTATION]: (state, payLoad) => {
      state.externalPresetLoad = payLoad;
    },
    [SET_EMAIL_MUTATION]: (state, data) => {
      state.email = data;
    },
    [SET_STATE_FROM_MIDDLEWARE_MUTATION]: (state) => {
      let microscopyState = MicroscopyMiddlewareServices.getState();
      state.species = microscopyState.species;
      state.categoryList = microscopyState.categoryList;
    },
    [SET_CURRENT_SPECIES_MUTATION]: (state, species) => {
      state.currentSpecies = species;
      if (species && species.commonName) {
        const extensions = {
          name: Constants.SPECIES,
          state: species.commonName,
        };
        entry(xapiEntryTypes.STATE, {
          extensions: extensions,
        });
      }
    },
    [SET_IMAGE_DATA_MUTATION]: (state, data) => {
      state.imageData[data.name] = data.data;
    },
    [SET_SPECIES_ZOOM_POPUP_OPEN_MUTATION]: (state, data) => {
      state.speciesZoomPopupOpen = data;
    },
    [SET_SPECIES_SELECTOR_INFORMATION_MUTATION]: (state, data) => {
      if (!data.hasOwnProperty('search')) {
        data.search = '';
      }
      state.speciesSelectorInformation = data;
    },
    [SET_SPECIAL_SPECIES_FILTER_MUTATION]: (state, data) => {
      state.specialSpeciesFilter = data;
    },
    [SET_SPECIAL_MEDIA_LIST_MUTATION]: (state, data) => {
      state.specialMediaList = data;
    },
    // []: (state, data) => {
    //   state. = data;
    // },
  },
  modules: {
    BridgeModule,
  },
});
