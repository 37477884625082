const LabBookMiddleware = {
  callback: undefined,
  labName: undefined,
  lab_book(action, options = {}) {
    let params = {
      type: 1, // 1 = text, 2 = open / close message, 3 = image, 4 = data(table), 5 = Instructor note (from portal)
    };
    params = {
      ...params,
      ...options,
    };
    switch (action) {
      case "entry":
        return LabBookMiddleware.lab_book_entry(params);
      default:
        console.warn("UNKNOWN LAB BOOK ACTION", action);
        return false;
    }
  },
  setLabName(labName) {
    LabBookMiddleware.labName = labName;
  },
  setupCallback(callback) {
    LabBookMiddleware.callback = callback;
  },
  lab_book_entry: (params) => {
    let entryContents;
    if (params.type === 3) {
      //image
      entryContents = `<img alt="" src="${params.entry}">`;
      let title = `<strong>${LabBookMiddleware.labName} Image</strong><br><span class="dateString">{lab_book_timestamp}</span><br>`;
      entryContents = title + entryContents;
    } else if (params.type === 4) {
      let table = LabBookMiddleware.generateTable(params.entry);
      let button = LabBookMiddleware.generateButton();
      let title = `<div class="title-container">
                         <strong>${LabBookMiddleware.labName} Data:</strong>
                         <p class="time-stamp">{lab_book_timestamp}</p>
                         ${button.outerHTML}
                        </div>`;
      entryContents = `<hr class="top-line"/>${table.outerHTML}<hr class="bottom-line"/>`;
      entryContents = title + entryContents;
    } else {
      entryContents = `${params.entry}`;
    }
    const entry = {
      content: entryContents,
      entry_type: params.type,
    };

    LabBookMiddleware.callback(entry);  // Sends to App.vue for processing;
  },
  generateTable: function(data) {
    let headers = Object.values(data.headers);

    let tableData = data.dataSet.map((obj) => {
      return Object.values(obj);
    });
    tableData = LabBookMiddleware.removeDuplicates(tableData);
    var table = document.createElement("TABLE");

    var row = table.insertRow(-1);
    for (let i = 0; i < headers.length; i++) {
      var headerCell = document.createElement("TH");
      headerCell.innerHTML = headers[i];
      row.appendChild(headerCell);
    }

    for (let i = 0; i < tableData.length; i++) {
      row = table.insertRow(-1);
      for (var j = 0; j < headers.length; j++) {
        var cell = row.insertCell(-1);
        cell.innerHTML = tableData[i][j];
      }
    }

    let container = document.createElement("DIV");
    container.setAttribute("id", "tableContainer");
    container.append(table);

    let wrapper = document.createElement("DIV");
    wrapper.setAttribute("id", "tableWrapper");

    wrapper.append(container);

    return wrapper;
  },
  removeDuplicates(data) {
    let tmp = [];
    let uniqueData = data.filter(function(el) {
      if (tmp.indexOf(el.toString()) < 0) {
        tmp.push(el.toString());
        return el;
      }
    });
    return uniqueData;
  },
  generateButton: function() {
    let button = document.createElement("BUTTON");
    button.setAttribute("v-ripple", "");
    button.id = "copyButton";
    button.innerHTML = `<img
                          id="copyImg"
                          class="copy-img"
                          src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNC4zLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOm5vbmU7fQ0KCS5zdDF7ZmlsbDojMTc0NjlFO30NCjwvc3R5bGU+DQo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMCwwaDI0djI0SDBWMHoiLz4NCjxwYXRoIGNsYXNzPSJzdDEiIGQ9Ik0xNiwxSDRDMi45LDEsMiwxLjksMiwzdjE0aDJWM2gxMlYxeiBNMTkuOSw1SDhDNi45LDUsNiw1LjksNiw3djE0YzAsMS4xLDAuOSwyLDIsMmgxMS45YzEuMSwwLDItMC45LDItMlY3DQoJQzIxLjksNS45LDIxLDUsMTkuOSw1eiBNMTkuOSwyMUg4VjdoMTEuOVYyMXoiLz4NCjxwYXRoIGNsYXNzPSJzdDEiIGQ9Ik0xOCw1VjNjMC0xLjEtMC45LTItMi0ybDAsMHYybDAsMHYySDE4eiIvPg0KPHBhdGggY2xhc3M9InN0MSIgZD0iTTIsMTdMMiwxN2MwLDEuMSwwLjksMiwyLDJoMnYtMkg0bDAsMEgyeiIvPg0KPHJlY3QgeD0iNy4xIiB5PSI5LjkiIGNsYXNzPSJzdDEiIHdpZHRoPSIxNCIgaGVpZ2h0PSIxIi8+DQo8cmVjdCB4PSI3LjEiIHk9IjEzLjkiIGNsYXNzPSJzdDEiIHdpZHRoPSIxNCIgaGVpZ2h0PSIxIi8+DQo8cmVjdCB4PSI3LjEiIHk9IjE4IiBjbGFzcz0ic3QxIiB3aWR0aD0iMTQiIGhlaWdodD0iMSIvPg0KPHJlY3QgeD0iMy41IiB5PSIxMy42IiB0cmFuc2Zvcm09Im1hdHJpeCgzLjk0NDA1MWUtMTEgLTEgMSAzLjk0NDA1MWUtMTEgLTIuNTg1MSAyNS41ODUxKSIgY2xhc3M9InN0MSIgd2lkdGg9IjE2IiBoZWlnaHQ9IjEiLz4NCjxyZWN0IHg9IjguNSIgeT0iMTMuNiIgdHJhbnNmb3JtPSJtYXRyaXgoMy45NDQwNTFlLTExIC0xIDEgMy45NDQwNTFlLTExIDIuNDE0OSAzMC41ODUxKSIgY2xhc3M9InN0MSIgd2lkdGg9IjE2IiBoZWlnaHQ9IjEiLz4NCjwvc3ZnPg0K"
                          alt=""
                        />
                        <p id="copyText">Copy Data</p>`;
    button.classList.add("copy-data", "ripple");
    return button;
  },
}

export function setupCallback(callback) {
  return LabBookMiddleware.setupCallback(callback);
}
export function setLabName(labName) {
  return LabBookMiddleware.setLabName(labName);
}
export function lab_book(action, options) {
  return LabBookMiddleware.lab_book(action, options);
}