<template>
  <ul class="row buttonWrapper p-0 noDecoration">
    <li v-for="(button, index) in buttons" role="tab" aria-label="button"
      :aria-pressed="selectedButtonIndex == index ? 'true' : 'false'"
      :aria-selected="selectedButtonIndex == index ? 'true' : 'false'" :aria-controls="button[textAttribute]"
      :aria-checked="selectedButtonIndex == index ? 'true' : 'false'"
      :class="['imageButton', { selectedButton: selectedButtonIndex == index }]" @click="selectButton(button, index)"
      tabindex="0" @keyup.enter="selectButton(button, index)" :key="index">
      <div class="buttonLabel" :title="button[textAttribute]">{{ button[textAttribute] }}</div>
      <img class="buttonImage" :src="require('@/assets/' + button.imagePath)" alt="" />
    </li>
  </ul>
</template>
<script>
export default {
  name: 'ImageRadioButtons',
  emits: ['selectedRadioButton'],
  data: () => ({
    selectedButtonIndex: null,
  }),
  props: {
    buttons: {
      type: Array,
      required: true,
    },
    textAttribute: {
      type: String,
      default: 'text',
    },
    selectedButtonId: {
      type: Number,
      default: null,
    },
  },
  methods: {
    selectButton(button, index) {
      if (this.selectedButtonIndex == index) {
        this.selectedButtonIndex = null;
      } else {
        this.selectedButtonIndex = index;
      }
      this.$emit("selectedRadioButton", button);
    },
    clearSelection: function () {
      this.selectedButtonIndex = null;
    },
  },
}
</script>
<style scoped>
.buttonWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.imageButton {
  border: 1px solid #17469e;
  padding: 5px;
  width: 70px;
  height: 50px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.imageButton:hover,
.selectedButton {
  background: #e3e4e6;
}

.buttonLabel {
  position: relative;
  font-size: 9px;
  color: black;
  top: -12px;
  font-weight: normal;
  background: white;
  right: -1px;
  display: flex;
  padding-left: 1px;
  width: fit-content;
}

.butonLabelText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.buttonImage {
  position: relative;
  top: -11px;
  width: 100%;
  height: 100%;
}</style>
