<template>
  <section :aria-label="getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.SPECIES_TRAY_TITLE)" class="column h-full">
    <section aria-label="Species Controls" class="speciesHeader" ref="header">
      <div class="row m-medium content-center">
        <Dropdown :list="dropdownOptions" variant="text" :selectedInd="dropdownSelected"
          @selected="dropdownOptionSelected" />
        <Search ref="search" class="ml-auto" :placeholder="searchPlaceHolderText" @textChanged="searchTextChanged" />
      </div>
      <div class="row mx-medium" style="margin-top: 12px;">
        <RadioButtons v-if="dropdownSelected == 0" :buttons="alphabeticCategories" textAttribute="displayName"
          @selectedRadioButton="buttonSelected" idName="categoryId" :selectedButtonId="selectedCategoryId" />
        <ImageRadioButtons v-else :buttons="imageCategories" textAttribute="displayName"
          @selectedRadioButton="buttonSelected" ref="imageButtons" :selectedButtonId="selectedCategoryId" />
      </div>
    </section>
    <div class="row speciesBottom mx-medium" :style="bottomStyle">
      <ul :id="selectedCategoryTitle" class="speciesWrapper column disColumn align-start noDecoration p-0 my-0"
        role="tablist">
        <li v-for="species in displayedSpecies" :id="'species' + species.id" role="tab"
          :aria-selected="selectedSpecies && species.id == selectedSpecies.id ? 'true' : 'false'"
          :class="['selectable', { selectedSpecies: selectedSpecies && species.id == selectedSpecies.id }]"
          @click="selectSpecies(species)" tabindex="0" @keyup.enter="selectSpecies(species)"
          :key="'displayedSpecies' + species.id">
          {{ species.commonName }}
        </li>
      </ul>
      <div id="selectedSpeciesViewer" v-if="selectedSpecies" class="disColumn" role="tabpanel"
        :aria-labelledby="'species' + selectedSpecies.id">
        <img :src="S3URL + selectedSpecies.imagePath" />
        <h4 class="selectedSpeciesName my-0">{{ selectedSpecies.commonName }}</h4>
        <div class="column selectedSpeciesDescription user-selectable" v-html="selectedSpecies.description"></div>
      </div>
    </div>
  </section>
</template>
<script>
import Search from './utilities/Search.vue';
import Dropdown from './utilities/Dropdown.vue';
import RadioButtons from './utilities/RadioButtons.vue';
import ImageRadioButtons from './utilities/ImageRadioButtons.vue';
import Constants from '../enums/Constants';
import BiologyLabEnum from '../enums/BiologyLabEnum';
import MicroscopyMiddlewareServices from '../services/MicroscopyMiddlewareServices';
import { mapGetters } from 'vuex'
import {
  GET_SPECIES,
  GET_CATEGORY_LIST,
  GET_SPECIES_SELECTOR_INFORMATION,
  GET_CURRENT_SPECIES,
  SET_SPECIES_SELECTOR_INFORMATION_ACTION,
  SET_CURRENT_SPECIES_ACTION,
  GET_SPECIAL_SPECIES_FILTER,
  SET_SPECIAL_MEDIA_LIST_ACTION,
} from '../store/store-types';
import NotificationMixin from '@/mixins/NotificationMixin';
export default {
  name: 'TrayPanelSpecies',
  mixins: [NotificationMixin],
  data: function () {
    return {
      headerHeight: 0,
      BiologyLabEnum,
      Constants,
      S3URL: Constants.S3_BUCKET_URL,
      dropdownOptions: [
        {
          text: this.getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.CATEGORY_LIST.ALPHABETICAL),
          value: 'alphabetic',
        },
        {
          text: this.getLocaleData(
            BiologyLabEnum.MICROSCOPY,
            Constants.CATEGORY_LIST.CHARACTERISTICS
          ),
          value: 'characters',
        },
        {
          text: this.getLocaleData(
            BiologyLabEnum.MICROSCOPY,
            Constants.CATEGORY_LIST.CLASSIFICATION
          ),
          value: 'classifications',
        },
        {
          text: this.getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.CATEGORY_LIST.HABITATS),
          value: 'habitats',
        },
        {
          text: this.getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.CATEGORY_LIST.MODELSPECIES),
          value: 'modelSpecies',
        },
      ],
      radioButtons: [
        {
          text: Constants.PLANTS,
        },
        {
          text: Constants.ANIMALS,
        },
        {
          text: Constants.FUNGI,
        },
        {
          text: Constants.PROTISTA,
        },
        {
          text: Constants.BACTERIA,
        },
        {
          text: Constants.ARCHAEA,
        },
      ],
      searchPlaceHolderText: this.getLocaleData(
        BiologyLabEnum.MICROSCOPY,
        Constants.SEARCH_PLACEHOLDER_TEXT
      ),
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.updateClientHeight();
    });
  },
  methods: {
    updateClientHeight() {
      this.$nextTick(() => {
        this.headerHeight = this.$refs.header.clientHeight + 10;
      });
    },
    dropdownOptionSelected: function (index) {
      this.$store.dispatch(SET_SPECIES_SELECTOR_INFORMATION_ACTION, {
        dropdown: index,
        button: this.speciesSelectorInformation.button,
        search: this.speciesSelectorInformation.search,
      });
      this.resetSelected();
      this.updateClientHeight();
    },
    buttonSelected: function (button) {
      let category = button == this.selectedCategory ? null : button.id;
      this.$store.dispatch(SET_SPECIES_SELECTOR_INFORMATION_ACTION, {
        dropdown: this.speciesSelectorInformation.dropdown,
        button: category,
        search: this.speciesSelectorInformation.search,
      });
      this.$store.dispatch(SET_CURRENT_SPECIES_ACTION, null);
      this.showSRNotification("Search Results Updated");
    },
    selectSpecies: function (species) {
      if (species == this.selectedSpecies) {
        this.$store.dispatch(SET_CURRENT_SPECIES_ACTION, null);
        return;
      }
      species.loadData().then(() => {
        if (!species.media) {
          MicroscopyMiddlewareServices.get_media(species).then((media) => {
            species.media = media;
            this.$store.dispatch(SET_CURRENT_SPECIES_ACTION, species);
            this.$store.dispatch(SET_SPECIAL_MEDIA_LIST_ACTION, []);
          });
        } else {
          this.$store.dispatch(SET_CURRENT_SPECIES_ACTION, species);
          this.$store.dispatch(SET_SPECIAL_MEDIA_LIST_ACTION, []);
        }
        this.showSRNotification("Species Information Updated");
      });
    },
    resetSelected: function () {
      this.$store.dispatch(SET_CURRENT_SPECIES_ACTION, null);
      if (this.$refs.imageButtons) {
        this.$refs.imageButtons.clearSelection();
      }
    },
    searchTextChanged: function (text) {
      this.$store.dispatch(SET_SPECIES_SELECTOR_INFORMATION_ACTION, {
        dropdown: this.speciesSelectorInformation.dropdown,
        button: this.speciesSelectorInformation.button,
        search: text,
      });
    },
  },
  components: {
    Search,
    RadioButtons,
    ImageRadioButtons,
    Dropdown,
  },
  computed: {
    ...mapGetters({
      allSpecies: GET_SPECIES,
      speciesCategories: GET_CATEGORY_LIST,
      selectedSpecies: GET_CURRENT_SPECIES,
      speciesSelectorInformation: GET_SPECIES_SELECTOR_INFORMATION,
      specialSpeciesFilter: GET_SPECIAL_SPECIES_FILTER,
    }),
    selectedCategoryTitle() {
      if (this.selectedCategory) {
        return this.selectedCategory.displayName;
      } else {
        return 'All Species';
      }
    },
    selectedCategory() {
      return this.speciesCategories.find(category => category.id == this.speciesSelectorInformation.button);
    },
    selectedCategoryId() {
      return this.speciesSelectorInformation.button;
    },
    dropdownSelected() {
      return this.speciesSelectorInformation.dropdown;
    },
    searchText() {
      return this.speciesSelectorInformation.search;
    },
    bottomStyle() {
      return `height: calc(100% - ${this.headerHeight}px);`;
    },
    alphabeticCategories: function () {
      return this.speciesCategories.filter((category) => category.category == 'alphabetic');
    },
    imageCategories: function () {
      return this.speciesCategories.filter((category) => category.category.toLowerCase() == this.dropdownOptions[this.dropdownSelected].value.toLowerCase());
    },
    displayedSpecies: function () {
      let sortedSpecies;
      if (this.specialSpeciesFilter.length > 0) {
        return this.allSpecies.filter((obj) => {
          return this.specialSpeciesFilter.includes(obj.id);
        });
      }
      if (this.selectedCategory) {
        sortedSpecies = this.selectedCategory.speciesList.filter((species) => species);
      } else {
        sortedSpecies = this.allSpecies;
      }
      if (this.searchText != '') {
        return sortedSpecies.filter((species) => species.commonName.toLowerCase().includes(this.searchText));
      } else {
        return sortedSpecies;
      }
    },
  },
  watch: {
    searchText(value) {
      this.$refs.search.updateText(value);
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.row {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.content-center {
  align-items: center;
}

.h-full {
  height: 100%;
}

.ml-auto {
  margin-left: auto;
}

.m-small {
  margin: 8px;
}

.m-medium {
  margin: 12px;
}

.mx-medium {
  margin-left: 12px;
  margin-right: 12px;
}

.py-small {
  padding-left: 8px;
  padding-right: 8px;
}

.align-start {
  align-items: flex-start;
}

.selectable {
  cursor: pointer;
  user-select: auto;
  color: $color-primary-blue;
  border: 1px solid transparent;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  text-align: left;
  margin: 2px 0;
  padding-left: 12px;
  text-indent: -12px;
}

.selectable:hover,
.selectedSpecies {
  background: #e3e4e6;
  border: 1px solid $color-primary-blue;
}

.speciesHeader {
  margin-bottom: 10px;
  position: sticky;
  top: 0;
  background: white;
}

.speciesBottom {
  // height: calc(100% - 101px);
  // margin-top: 10px;
}

#speciesWrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  margin-right: 12px;
}

#speciesWrapper,
#selectedSpeciesViewer {
  width: calc(50% - 6px);
  height: auto;
  overflow-y: auto;
}

#selectedSpeciesViewer {
  text-align: left;
  color: $color-primary-blue;
  // padding: 0 8px;
}

.selectedSpeciesName {
  font-weight: bold;
  padding: 0 8px;
}

.selectedSpeciesDescription {
  padding: 0 8px;
  overflow: auto;
}

.selectedSpeciesDescription>p {
  margin: 7px 0;
}
</style>
<style lang="scss">
.selectedSpeciesDescription>p {
  margin: 7px 0;
}
</style>