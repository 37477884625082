<template>
  <div role="toolbar">
    <span class="ql-formats">
      <BLTooltip :text="getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.EXPORT_PDF)" align="top">
        <button type="button" class="quill-custom" id="lab-book-export-pdf" role="button" ref="exportPdfButton"
          :aria-label="getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.EXPORT_PDF)">
          <span class="icon">
            <img src="@/assets/general_lab_icons/export_pdf_arrow-24px.svg" class="quill-toolbar-image"
              :alt="getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.EXPORT)" />
          </span>
        </button>
      </BLTooltip>
      <BLTooltip :text="getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.IMPORT_PDF)" align="top">
        <button type="button" class="quill-custom" id="lab-book-import-pdf" role="button"
          :aria-label="getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.IMPORT_PDF)">
          <span class="icon">
            <img src="@/assets/general_lab_icons/import_pdf_arrow-24px.svg" class="quill-toolbar-image"
              :alt="getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.IMPORT)" />
          </span>
        </button>
      </BLTooltip>
      <BLTooltip :text="copyMessage" align="top">
        <button type="button" class="quill-custom" id="lab-book-copy" role="button" :aria-label="copyMessage">
          <span class="icon">
            <img class="quill-toolbar-image"
              src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDp0cmFuc3BhcmVudDt9Cgkuc3Qxe2ZpbGw6IzE3NDY5RTt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0wLDBoMjR2MjRIMFYweiIvPgo8cGF0aCBjbGFzcz0ic3QxIiBkPSJNMTYsMUg0QzIuOSwxLDIsMS45LDIsM3YxNGgyVjNoMTJWMXogTTE5LjksNUg4QzYuOSw1LDYsNS45LDYsN3YxNGMwLDEuMSwwLjksMiwyLDJoMTEuOWMxLjEsMCwyLTAuOSwyLTJWNwoJQzIxLjksNS45LDIxLDUsMTkuOSw1eiBNMTkuOSwyMUg4VjdoMTEuOVYyMXoiLz4KPHBhdGggY2xhc3M9InN0MSIgZD0iTTE4LDVWM2MwLTEuMS0wLjktMi0yLTJoMHYyaDB2MkgxOHoiLz4KPHBhdGggY2xhc3M9InN0MSIgZD0iTTIsMTdMMiwxN2MwLDEuMSwwLjksMiwyLDJoMnYtMkg0bDAsMEgyeiIvPgo8L3N2Zz4K"
              :alt="getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.COPY)" />
          </span>
        </button>
      </BLTooltip>
      <BLTooltip :text="pasteMessage" align="top">
        <button type="button" class="quill-custom" id="lab-book-paste" role="button" :aria-label="pasteMessage">
          <span class="icon">
            <img class="quill-toolbar-image"
              src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDp0cmFuc3BhcmVudDt9Cgkuc3Qxe2ZpbGw6IzE3NDY5RTt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0wLDBoMjR2MjRIMFYweiIvPgo8cGF0aCBjbGFzcz0ic3QxIiBkPSJNMTYsMUg0QzIuOSwxLDIsMS45LDIsM3YxNGgyVjNoMTJWMXogTTE5LjksNUg4QzYuOSw1LDYsNS45LDYsN3YxNGMwLDEuMSwwLjksMiwyLDJoMTEuOWMxLjEsMCwyLTAuOSwyLTJWNwoJQzIxLjksNS45LDIxLDUsMTkuOSw1eiIvPgo8cGF0aCBjbGFzcz0ic3QxIiBkPSJNMTgsNVYzYzAtMS4xLTAuOS0yLTItMmgwdjJoMHYySDE4eiIvPgo8cGF0aCBjbGFzcz0ic3QxIiBkPSJNMiwxN0wyLDE3YzAsMS4xLDAuOSwyLDIsMmgydi0ySDRsMCwwSDJ6Ii8+Cjwvc3ZnPgo="
              :alt="getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.PASTE)" />
          </span>
        </button>
      </BLTooltip>
    </span>
    <span class="ql-formats">
      <BLTooltip :text="getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.BOLD)" align="top">
        <button type="button" class="ql-bold" role="button"
          :aria-label="getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.BOLD)"></button>
      </BLTooltip>
      <BLTooltip :text="getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.ITALIC)" align="top">
        <button type="button" class="ql-italic" role="button"
          :aria-label="getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.ITALIC)"></button>
      </BLTooltip>
      <BLTooltip :text="getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.UNDERLINE)" align="top">
        <button type="button" class="ql-underline" role="button"
          :aria-label="getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.UNDERLINE)"></button>
      </BLTooltip>
    </span>
    <span class="ql-formats">
      <BLTooltip :text="getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.SUPERSCRIPT)" align="top">
        <button type="button" class="ql-script" value="super" role="button"
          :aria-label="getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.SUPERSCRIPT)"></button>
      </BLTooltip>
      <BLTooltip :text="getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.SUBSCRIPT)" align="top">
        <button type="button" class="ql-script" value="sub" role="button"
          :aria-label="getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.SUBSCRIPT)"></button>
      </BLTooltip>
    </span>
    <span class="ql-formats quill-submit">
      <BLTooltip :text="getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.SUBMIT_ENTRY)" align="top">
        <button type="button" class="quill-custom" id="lab-book-submit-entry" role="button"
          :aria-label="getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.SUBMIT_ENTRY)">
          <span class="icon">
            <i class="mdi mdi-send"></i>
          </span>
        </button>
      </BLTooltip>
    </span>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import BLTooltip from '@/components/utilities/BLTooltip.vue';
import BiologyLabEnum from '@/enums/BiologyLabEnum';
import Constants from '@/enums/Constants';
export default {
  name: 'QuillToolbar',
  data: () => ({
    BiologyLabEnum,
    Constants,
  }),
  props: [],
  components: {
    BLTooltip,
  },
  created() { },
  mounted() { },
  methods: {
    focus() {
      this.$refs.exportPdfButton.focus();
    },
    getOperatingSystem: function () {
      var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;

      if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
      } else if (/Android/.test(userAgent)) {
        os = 'Android';
      } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
      }

      return os;
    },
  },
  computed: {
    copyMessage() {
      if (this.getOperatingSystem() === 'Mac OS') {
        return this.getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.COPY_MAC)
      }
      return this.getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.COPY_WIN)
    },
    pasteMessage() {
      if (this.getOperatingSystem() === 'Mac OS') {
        return this.getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.PASTE_MAC)
      }
      return this.getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.PASTE_WIN)
    },
  },
};
</script>
<style scoped>.quill-submit {
  float: right;
}</style>
