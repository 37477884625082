import Constants from '../enums/Constants';
import { SET_CURRENT_MEDIA_ACTION } from '../store/store-types';

export default {
  methods: {
    getMediaImageAndSetCurrentMedia(media) {
      return new Promise((resolve) => {
        if (media.type == 'static') {
          if (media.img) {
            this.$store.dispatch(SET_CURRENT_MEDIA_ACTION, media);
            resolve();
            return;
          }
          let img = new Image();
          img.crossOrigin = "Anonymous";
          let src = `${Constants.S3_BUCKET_URL}media/content/${media.mediaName}${Constants.IMG_EXT_JPG}`;
          img.onload = () => {
            media.img = img;
            this.$store.dispatch(SET_CURRENT_MEDIA_ACTION, media);
            resolve();
          };
          img.src = src;
        } else {
          this.$store.dispatch(SET_CURRENT_MEDIA_ACTION, media);
          resolve();
        }
      });
    }
  },
};
