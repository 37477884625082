const LabsList =[
  // Pull from database eventually
  {
    name: "Titrations",
    shortName: "Titrations",
    identifier: "chemistry-titrations",
    icon: "",
    frameSrc: "",
    labType: 0,
    productId: 32,
  },
  {
    name: "Inorganic",
    shortName: "Inorganic",
    identifier: "chemistry-inorganic",
    icon: "",
    frameSrc: "",
    labType: 1,
    productId: 2,
  },
  {
    name: "Gases",
    shortName: "Gases",
    identifier: "chemistry-gases",
    icon: "",
    frameSrc: "",
    labType: 2,
    productId: 6,
  },
  {
    name: "Calorimetry",
    shortName: "Calorimetry",
    identifier: "chemistry-calorimetry",
    icon: "",
    frameSrc: "",
    labType: 3,
    productId: 4,
  },
  {
    name: "Quantum",
    shortName: "Quantum",
    identifier: "chemistry-quantum",
    icon: "",
    frameSrc: "",
    labType: 4,
    productId: 8,
  },
  {
    name: "Organic Qualitative Analysis",
    shortName: "Organic Qual",
    identifier: "chemistry-organicq",
    icon: "",
    frameSrc: "",
    labType: 5,
    productId: 12,
  },
  {
    name: "Organic Synthesis",
    shortName: "Organic Synthesis",
    identifier: "chemistry-organics",
    icon: "",
    frameSrc: "",
    labType: 6,
    productId: 14,
  },
  {
    name: "Density",
    shortName: "Density",
    identifier: "physics-density",
    icon: "",
    frameSrc: "",
    labType: 7,
    productId: 10,
  },
  {
    name: "Physics",
    shortName: "Physics",
    identifier: "physics",
    icon: "",
    frameSrc: "",
    labType: 8,
    productId: 34,
  },
  {
    name: "Microscopy",
    shortName: "Microscopy",
    identifier: "biology-microscopy",
    icon: "",
    frameSrc: "",
    labType: 9,
    productId: 22,
  },
  {
    name: "Genetics",
    shortName: "Genetics",
    identifier: "biology-genetics",
    icon: "",
    frameSrc: "",
    labType: 10,
    productId: 24,
  },
  {
    name: "Ecology",
    shortName: "Ecology",
    identifier: "biology-ecology",
    icon: "",
    frameSrc: "",
    labType: 11,
    productId: 28,
  },
  {
    name: "Portal",
    shortName: "Portal",
    identifier: "gibbs-portal",
    icon: "",
    frameSrc: "",
    labType: 99,
    productId: 0,
  },
]

export default LabsList