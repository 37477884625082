<template>
  <div id="labBenchWrapper">
    <div id="canvasWrapper">
      <img id="labBackground" src="../../assets/base/Microsopy_background.jpg" :alt="getLocaleData(
        BiologyLabEnum.MICROSCOPY,
        Constants.LAB_BACKGROUND,
      )" />
      <canvas class="pixi bench" id="bench" ref="backgroundCanvas" :width="benchWidth" :height="benchHeight" />
    </div>
    <div id="imageListsOverlay" v-if="species || specialMedia.length">
      <div v-show="!zoomPopupVisible" class="w-full row">
        <div id="fieldScope" class="imageListWrapper">
          <section aria-label="Field Scope Images" v-show="fieldScopeImages.length" class="imageList"
            ref="fieldScopeImageList" :style="scrollbarStyling('fieldScope')">
            <div v-for="image in fieldScopeImages" @click="selectImage(image)" class="imageTooltip"
              @keyup.enter="selectImage(image)">
              <img @load="checkHeights()" :alt="image.title" tabindex="0"
                :class="['thumbnailImage', { 'selectedThumbnail': media && image.id == media.id }]"
                crossorigin="anonymous" :src="S3URL + PATH + image.i + THUMB + (image.t == 'static' ? JPG : F4V)" />
            </div>
          </section>
          <img class="imageConnector imageConnectorFieldScope" alt="" v-show="hasImages(fieldScopeImages)"
            :src="FieldScopeConnector" />
        </div>
        <div id="dissecting" class="imageListWrapper">
          <section aria-label="Dissecting Microscope Images" v-show="dissectingImages.length" class="imageList"
            ref="dissectingImageList" :style="scrollbarStyling('dissecting')">
            <div v-for="image in dissectingImages" @click="selectImage(image)" class="imageTooltip"
              @keyup.enter="selectImage(image)">
              <img @load="checkHeights()" :alt="image.title" tabindex="0"
                :class="['thumbnailImage', { 'selectedThumbnail': media && image.id == media.id }]"
                crossorigin="anonymous" :src="S3URL + PATH + image.i + THUMB + (image.t == 'static' ? JPG : F4V)" />
            </div>
          </section>
          <img class="imageConnector imageConnectorDissecting" alt="" v-show="hasImages(dissectingImages)"
            :src="DissectingCompoundConnector" />
        </div>
        <div id="compound" class="imageListWrapper">
          <section aria-label="Compound Microscope Images" v-show="compoundImages.length" class="imageList"
            ref="compoundImageList" :style="scrollbarStyling('compound')">
            <div v-for="image in compoundImages" @click="selectImage(image)" class="imageTooltip"
              @keyup.enter="selectImage(image)">
              <img @load="checkHeights()" :alt="image.title" tabindex="0"
                :class="['thumbnailImage', { 'selectedThumbnail': media && image.id == media.id }]"
                crossorigin="anonymous" :src="S3URL + PATH + image.i + THUMB + (image.t == 'static' ? JPG : F4V)" />
            </div>
          </section>
          <img class="imageConnector imageConnectorCompound" alt="" v-show="hasImages(compoundImages)"
            :src="DissectingCompoundConnector" />
        </div>
        <div id="SEM" class="imageListWrapper">
          <section aria-label="SEM Images" v-show="SEMImages.length" class="imageList" ref="SEMImageList"
            :style="scrollbarStyling('SEM')">
            <div v-for="image in SEMImages" @click="selectImage(image)" class="imageTooltip"
              @keyup.enter="selectImage(image)">
              <img @load="checkHeights()" :alt="image.title" tabindex="0"
                :class="['thumbnailImage', { 'selectedThumbnail': media && image.id == media.id }]"
                crossorigin="anonymous" :src="S3URL + PATH + image.i + THUMB + (image.t == 'static' ? JPG : F4V)" />
            </div>
          </section>
          <img class="imageConnector imageConnectorSEM" alt="" v-show="hasImages(SEMImages)" :src="SEMConnector" />
        </div>
        <div id="TEM" class="imageListWrapper">
          <section aria-label="TEM Images" v-show="TEMImages.length" class="imageList" ref="TEMImageList"
            :style="scrollbarStyling('TEM')">
            <div v-for="image in TEMImages" @click="selectImage(image)" class="imageTooltip"
              @keyup.enter="selectImage(image)">
              <img @load="checkHeights()" :alt="image.title" tabindex="0"
                :class="['thumbnailImage', { 'selectedThumbnail': media && image.id == media.id }]"
                crossorigin="anonymous" :src="S3URL + PATH + image.i + THUMB + (image.t == 'static' ? JPG : F4V)" />
            </div>
          </section>
          <img class="imageConnector imageConnectorTEM" alt="" v-show="hasImages(TEMImages)" :src="TEMConnector" />
        </div>
      </div>
    </div>
    <span class="microscopeLabel fieldScopeLabel" :style="fieldScopeStyle" ref="FieldScopeLabel">{{
      labelText.fieldScope
    }}</span>
    <span class="microscopeLabel dissectingLabel" :style="dissectingStyle" ref="DissectingLabel">{{
      labelText.dissecting
    }}</span>
    <span class="microscopeLabel compoundLabel" :style="compoundStyle" ref="CompoundLabel">{{
      labelText.compound
    }}</span>
    <span class="microscopeLabel SEMLabel" :style="SEMStyle" ref="SEMLabel">{{ labelText.SEM }}</span>
    <span class="microscopeLabel TEMLabel" :style="TEMStyle" ref="TEMLabel">{{ labelText.TEM }}</span>
    <Popup v-if="showPosterPopup" @close="showPosterPopup = false" width="61vw" max-height="80vh" min-width="650px"
      :headerMargin="false" :leftPadding="false" ref="popup">
      <img style="width: 90%; border: 15px solid #17469E;" :alt="getLocaleData(
        BiologyLabEnum.MICROSCOPY,
        Constants.MAGNIFICATION_POSTER,
      )" src="../../assets/media/Magnification_Poster_OpenFull.png" />
    </Popup>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as StoreTypes from '../../store/store-types';
import BackgroundImage from '../../assets/base/Microscopy_Base.png';
import FieldScopeConnector from '../../assets/microscopeConnectors/FieldScope.png';
import DissectingCompoundConnector from '../../assets/microscopeConnectors/MicroscopesDissectingCompound.png';
import SEMConnector from '../../assets/microscopeConnectors/SEM.png';
import TEMConnector from '../../assets/microscopeConnectors/TEM.png';
import Popup from '../utilities/Popup.vue';
import Constants from '../../enums/Constants';
import BiologyLabEnum from '../../enums/BiologyLabEnum';
import { GET_SPECIES_SELECTOR_INFORMATION } from '../../store/store-types';
import benchConfig from '../../assets/json/BenchConfig.json';
import MicroscopyMiddlewareServices from '../../services/MicroscopyMiddlewareServices';
import moment from 'moment';
import NotificationMixin from '../../mixins/NotificationMixin.js';
import PanelConstants from '../../enums/PanelConstants';
import { entry, enableCommit } from '../../services/xapi';
import xapiEntryTypes from '../../enums/xapiEntryTypes';
import ImageMixin from "../../mixins/ImageMixin.js";
export default {
  name: "LabBench",
  mixins: [NotificationMixin, ImageMixin],
  data: () => ({
    BiologyLabEnum,
    Constants,
    benchWidth: '',
    benchHeight: '',
    autoload: null,
    BackgroundImage,
    showIMG: true,
    mousex: null,
    mousey: null,
    showPosterPopup: false,
    img: null,
    S3URL: Constants.S3_BUCKET_URL,
    JPG: Constants.IMG_EXT_JPG,
    F4V: Constants.IMG_EXT_F4V,
    THUMB: Constants.THUMBNAIL,
    PATH: Constants.MEDIA_CONTENT,
    FieldScopeConnector,
    DissectingCompoundConnector,
    SEMConnector,
    TEMConnector,
    scrollbar: {
      fieldScope: false,
      dissecting: false,
      compound: false,
      SEM: false,
      TEM: false,
    },
    labelText: {
      fieldScope: '',
      dissecting: '',
      compound: '',
      SEM: '',
      TEM: '',
    },
    labelPositions: {
      fieldScope: {
        x: 0,
        y: 0,
      },
      dissecting: {
        x: 0,
        y: 0,
      },
      compound: {
        x: 0,
        y: 0,
      },
      SEM: {
        x: 0,
        y: 0,
      },
      TEM: {
        x: 0,
        y: 0,
      },
    },
    labConfigurationSaved: '',
    labConfigurationRestored: '',
  }),
  props: {
    zoomPopupVisible: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    let img = new Image();
    img.onload = () => {
      this.img = img;
    };
    img.src = this.BackgroundImage;
    this.labConfigurationSaved = this.getLocaleData(
      BiologyLabEnum.MICROSCOPY,
      Constants.LAB_SAVED_NOTIFICATION,
    );
    this.labConfigurationRestored = this.getLocaleData(
      BiologyLabEnum.MICROSCOPY,
      Constants.LAB_RESTORED_NOTIFICATION,
    );
  },
  mounted() {
    this.isLabLoaded();
    this.setupCanvasMouseListener();
    this.getLabelTexts();
  },
  methods: {
    getLabelTexts() {
      this.labelText.fieldScope = this.getLocaleData(
        BiologyLabEnum.MICROSCOPY,
        Constants.FIELD_SCOPE,
      );
      this.labelText.dissecting = this.getLocaleData(
        BiologyLabEnum.MICROSCOPY,
        Constants.DISSECTING_MICROSCOPE,
      );
      this.labelText.compound = this.getLocaleData(
        BiologyLabEnum.MICROSCOPY,
        Constants.COMPOUND_MICROSCOPE,
      );
      this.labelText.SEM = this.getLocaleData(
        BiologyLabEnum.MICROSCOPY,
        Constants.SEM,
      );
      this.labelText.TEM = this.getLocaleData(
        BiologyLabEnum.MICROSCOPY,
        Constants.TEM,
      );
    },
    scrollbarStyling(element) {
    },
    hasImages(items) {
      return items && items.length > 0;
    },
    selectImage: function (image) {
      MicroscopyMiddlewareServices.get_media_details(image).then(() => {
        const extensions = {
          name: Constants.THUMBNAIL_CLICK,
          state: image.title,
          inZoomPopup: false,
          scope: image.scope,
        };
        entry(xapiEntryTypes.STATE, {
          extensions: extensions,
        });
        this.getMediaImageAndSetCurrentMedia(image);
        this.$store.dispatch(StoreTypes.SET_PANEL_ACTION, PanelConstants.LIVEDATA);
      });
    },
    getValue: function (value, size) {
      return value / 100 * size;
    },
    resizeLabBench: function () {
      // Resize the renderer
      if (this.img) {
        let parentWidth = this.$refs.backgroundCanvas.parentElement.clientWidth;
        let parentHeight = this.$refs.backgroundCanvas.parentElement.clientHeight;
        let canvas = this.$refs.backgroundCanvas;
        let ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        //draw text for each machine
        let width = canvas.width;
        let height = canvas.height;
        let fontSize = this.getValue(benchConfig.TextSize, width);
        var str = this.getLocaleData(
          BiologyLabEnum.MICROSCOPY,
          Constants.FIELD_SCOPE,
        );
        let x = this.getValue(benchConfig.FieldScope.x, width);
        let y = this.getValue(benchConfig.FieldScope.y, height);
        this.labelPositions.fieldScope.x = x;
        this.labelPositions.fieldScope.y = y;
        this.labelPositions.fieldScope.fontSize = fontSize;
        ctx.font = `bold ${fontSize}px Open Sans`;
        str = this.getLocaleData(
          BiologyLabEnum.MICROSCOPY,
          Constants.DISSECTING_MICROSCOPE,
        );
        x = this.getValue(benchConfig.DissectingMicroscope.x, width);
        y = this.getValue(benchConfig.DissectingMicroscope.y, height);
        let textWidth = this.getValue(benchConfig.LargeTextWidth, width);
        this.labelPositions.dissecting.x = x;
        this.labelPositions.dissecting.y = y;
        this.labelPositions.dissecting.fontSize = fontSize;
        str = this.getLocaleData(
          BiologyLabEnum.MICROSCOPY,
          Constants.COMPOUND_MICROSCOPE,
        );
        x = this.getValue(benchConfig.CompoundMicroscope.x, width);
        y = this.getValue(benchConfig.CompoundMicroscope.y, height);
        this.labelPositions.compound.x = x;
        this.labelPositions.compound.y = y;
        this.labelPositions.compound.fontSize = fontSize;
        str = this.getLocaleData(
          BiologyLabEnum.MICROSCOPY,
          Constants.SEM,
        );
        x = this.getValue(benchConfig.SEM.x, width);
        y = this.getValue(benchConfig.SEM.y, height);
        this.labelPositions.SEM.x = x;
        this.labelPositions.SEM.y = y;
        this.labelPositions.SEM.fontSize = fontSize;
        str = this.getLocaleData(
          BiologyLabEnum.MICROSCOPY,
          Constants.TEM,
        );
        x = this.getValue(benchConfig.TEM.x, width);
        y = this.getValue(benchConfig.TEM.y, height);
        this.labelPositions.TEM.x = x;
        this.labelPositions.TEM.y = y;
        this.labelPositions.TEM.fontSize = fontSize;
        this.benchWidth = parentWidth;
        this.benchHeight = parentHeight;
        this.showIMG = false;
      }
    },
    setupCanvasMouseListener: function () {
      let canvas = this.$refs.backgroundCanvas;
      canvas.addEventListener('mousemove', this.mouseMoveFunction);
      canvas.addEventListener('mousedown', this.mouseDownFunction);
    },
    mouseMoveFunction: function (e) {
      let canvas = this.$refs.backgroundCanvas;
      let rect = canvas.getBoundingClientRect();
      var canvasx = rect.left + document.body.scrollLeft;
      var canvasy = rect.top + document.body.scrollTop;
      this.mousex = parseInt(e.clientX - canvasx);
      this.mousey = parseInt(e.clientY - canvasy);

      var x = e.pageX - canvas.offsetLeft - canvas.getBoundingClientRect().left;
      var y = e.pageY - canvas.offsetTop - canvas.getBoundingClientRect().top;
      this.resizeLabBench();
      x = x / canvas.width * 100;
      y = y / canvas.height * 100;

      if (this.insideObject(x, y)) {
        canvas.style.cursor = "pointer";
      } else {
        canvas.style.cursor = 'default';
      }
    },
    mouseDownFunction: function (e) {
      let canvas = this.$refs.backgroundCanvas;

      var x = (e.pageX - canvas.offsetLeft - canvas.getBoundingClientRect().left) / canvas.width * 100;
      var y = (e.pageY - canvas.offsetTop - canvas.getBoundingClientRect().top) / canvas.height * 100;

      let object = this.insideObject(x, y);
      switch (object) {
        case 'poster':
          this.showPosterPopup = true;
          this.$refs.popup.focus();
          break;
      }

    },
    insideObject: function (x, y) {
      if (x >= benchConfig.PosterPosition.XMin && x <= benchConfig.PosterPosition.XMax && y >= benchConfig.PosterPosition.YMin && y <= benchConfig.PosterPosition.YMax) {
        return "poster";
      }
      return null;
    },
    isLabLoaded: function () {
      if (
        !this.$store.state.isReady
      ) {
        this.$store.dispatch(StoreTypes.LAB_READY_ACTION, true); // Hide splash
        this.$store.dispatch(StoreTypes.EXTERNAL_PRESET_LOAD_ACTION, false); // reset external preset load
        const extensions = {
          labName: this.$store.state.currentLab,
          action: Constants.LAUNCHED,
        };
        enableCommit(true);
        this.xapiStartUpEntry(extensions);
      }
    },
    saveLabState: function () {
      let labState = {};
      labState.labName = 'Microscopy';
      labState.panel = this.$store.state.panels;
      labState.name = this.generateLink();
      let speciesSelectorInformation = this.speciesSelectorInformation ? this.speciesSelectorInformation : {
        dropdown: 0,
        button: null,
      };
      labState.json = {
        speciesID: this.species && this.species.id ? this.species.id : null,
        mediaID: this.media && this.media.id ? this.media.id : null,
        zoomPopupOpen: this.zoomPopupOpen,
        speciesSelectorInformation,
        speciesFilter: this.specialSpeciesFilter,
        displayedMedia: this.specialMedia.map(m => m.id),
      }
      MicroscopyMiddlewareServices.savePreset(
        labState.uuid,
        JSON.stringify(labState),
      ).then((data) => {
        this.$store.dispatch(StoreTypes.UPDATE_PRESET_ACTION, data);
        this.showNotification(this.labConfigurationSaved);
        this.$store.dispatch(StoreTypes.SAVE_LAB_ACTION, false);
      });
    },
    generateLink: function () {
      const date = moment().format('MM-DD-YYYY');
      const time = moment().format('hh:mm:ssa');
      const link = `${this.$store.state.currentLab} | ${date} | ${time}`;
      return link;
    },
    clearEntireLab() {
      enableCommit(false);
      this.$store.dispatch(StoreTypes.SET_CURRENT_SPECIES_ACTION, null);
      this.$store.dispatch(StoreTypes.SET_CURRENT_MEDIA_ACTION, null);
      this.$store.dispatch(StoreTypes.SET_PANEL_ACTION, PanelConstants.SPECIES);
      this.$store.dispatch(StoreTypes.SET_SPECIAL_MEDIA_LIST_ACTION, []);
      this.$store.dispatch(StoreTypes.SET_SPECIAL_SPECIES_FILTER_ACTION, []);
      this.$store.dispatch("SET_PROCEDURE", null);
      let speciesSelectorInformation = {
        dropdown: 0,
        button: null,
        search: '',
      };
      this.$store.dispatch(StoreTypes.SET_SPECIES_SELECTOR_INFORMATION_ACTION, speciesSelectorInformation);
      enableCommit(true);
      const extensions = {
        name: Constants.CLEAR_LAB,
        state: true,
      };
      this.xapiStateEntry(extensions);
    },
    restoreLabState: function (autoload) {
      this.clearEntireLab(true);
      enableCommit(false);
      setTimeout(() => {
        let labState = autoload
          ? autoload
          : this.$store.state.restorePresetData;
        let json = labState.json;
        let speciesID = json.speciesID;
        let mediaID = json.mediaID;
        let speciesSelectorInformation = json.speciesSelectorInformation ? json.speciesSelectorInformation : {
          dropdown: 0,
          button: null,
          search: '',
        };
        this.$store.dispatch("SET_PROCEDURE", labState.procedure);
        this.$store.dispatch(StoreTypes.SET_SPECIES_SELECTOR_INFORMATION_ACTION, speciesSelectorInformation);
        if (json.speciesFilter) {
          this.$store.dispatch(StoreTypes.SET_SPECIAL_SPECIES_FILTER_ACTION, json.speciesFilter);
        } else {
          this.$store.dispatch(StoreTypes.SET_SPECIAL_SPECIES_FILTER_ACTION, []);
        }
        if (json.displayedMedia) {

          MicroscopyMiddlewareServices.get_media_by_id(json.displayedMedia).then((media) => {
            this.$store.dispatch(StoreTypes.SET_SPECIAL_MEDIA_LIST_ACTION, media);
            if (mediaID) {
              let selectedMedia = media.find(item => item.id == mediaID);
              if (selectedMedia) {
                this.selectImage(selectedMedia);
              }
            }
          });
        } else {
          this.$store.dispatch(StoreTypes.SET_SPECIAL_MEDIA_LIST_ACTION, []);
        }
        if (speciesID) {
          MicroscopyMiddlewareServices.get_species_object(speciesID).then((species) => {
            species.loadData().then(() => {
              this.$store.dispatch(StoreTypes.SET_CURRENT_SPECIES_ACTION, species);
              if (mediaID) {
                MicroscopyMiddlewareServices.get_media_object(mediaID, speciesID).then((media) => {
                  this.$store.dispatch(StoreTypes.SET_CURRENT_SPECIES_ACTION, species);
                  this.selectImage(media);
                });
              } else {
                this.$store.dispatch(StoreTypes.SET_CURRENT_SPECIES_ACTION, species);
              }
              this.$nextTick(() => {
                this.$store.dispatch(StoreTypes.SET_SPECIES_ZOOM_POPUP_OPEN_ACTION, json.zoomPopupOpen);
              });
            });
          });
        } else {
          this.$store.dispatch(StoreTypes.SET_SPECIES_ZOOM_POPUP_OPEN_ACTION, json.zoomPopupOpen);
        }
        // restore selected panel
        this.$store.dispatch(StoreTypes.SET_PANEL_ACTION, labState.panel);
        this.showNotification(this.labConfigurationRestored);
        this.$store.dispatch(StoreTypes.RESTORE_LAB_ACTION, false);
        enableCommit(true);
        if (!autoload) {
          const extensions = {
            name: Constants.PRESET,
            presetName: this.$store.state.restorePresetData.name,
          };
          this.xapiStateEntry(extensions);
        } else {
          this.recordLabStartUp(autoload);
        }
      }, Constants.INT_FIFTY);
    },
    xapiStateEntry: function (extensions) {
      entry(xapiEntryTypes.STATE, {
        extensions: extensions,
      });
    },
    xapiStartUpEntry: function (extensions) {
      entry(xapiEntryTypes.STARTUP, {
        extensions: extensions,
      });
    },
    recordLabStartUp: function (data) {
      const preset = this.presetList.find((preset) => {
        return preset.object.name === data.name && preset.owner_id === Constants.AUTOLOAD_UPPERCASE;
      });
      const extensions = {
        ...data,
        uuid: preset.uuid,
      };

      this.xapiStartUpEntry(extensions);
    },
    checkHeights() {
      if (this.$refs.fieldScopeImageList) {
        this.scrollbar.fieldScope = this.$refs.fieldScopeImageList.scrollHeight > this.$refs.fieldScopeImageList.clientHeight;
      }
      if (this.$refs.dissectingImageList) {
        this.scrollbar.dissecting = this.$refs.dissectingImageList.scrollHeight > this.$refs.dissectingImageList.clientHeight;
      }
      if (this.$refs.compoundImageList) {
        this.scrollbar.compound = this.$refs.compoundImageList.scrollHeight > this.$refs.compoundImageList.clientHeight;
      }
      if (this.$refs.SEMImageList) {
        this.scrollbar.SEM = this.$refs.SEMImageList.scrollHeight > this.$refs.SEMImageList.clientHeight;
      }
      if (this.$refs.TEMImageList) {
        this.scrollbar.TEM = this.$refs.TEMImageList.scrollHeight > this.$refs.TEMImageList.clientHeight;
      }
    },
  },
  components: {
    Popup,
  },
  updated() {
    this.checkHeights();
  },
  computed: {
    ...mapGetters({
      species: StoreTypes.GET_CURRENT_SPECIES,
      media: StoreTypes.GET_CURRENT_MEDIA,
      saveLab: StoreTypes.GET_SAVE_LAB,
      restoreLab: StoreTypes.GET_RESTORE_LAB,
      zoomPopupOpen: StoreTypes.GET_SPECIES_ZOOM_POPUP_OPEN,
      speciesSelectorInformation: GET_SPECIES_SELECTOR_INFORMATION,
      specialMedia: StoreTypes.GET_SPECIAL_MEDIA_LIST,
      specialSpeciesFilter: StoreTypes.GET_SPECIAL_SPECIES_FILTER,
      presetsAutoLoad: StoreTypes.PRESETS_AUTOLOAD,
    }),
    fieldScopeImages: function () {
      if (this.specialMedia.length) {
        return this.specialMedia.filter((media) => {
          return media.scope == 'field';
        });
      }
      return this.species && this.species.media ? this.species.media.field : [];
    },
    compoundImages: function () {
      if (this.specialMedia.length) {
        return this.specialMedia.filter((media) => {
          return media.scope == 'compound';
        });
      }
      return this.species && this.species.media ? this.species.media.compound : [];
    },
    dissectingImages: function () {
      if (this.specialMedia.length) {
        return this.specialMedia.filter((media) => {
          return media.scope == 'dissecting';
        });
      }
      return this.species && this.species.media ? this.species.media.dissection : [];
    },
    SEMImages: function () {
      if (this.specialMedia.length) {
        return this.specialMedia.filter((media) => {
          return media.scope == 'sem';
        });
      }
      return this.species && this.species.media ? this.species.media.sem : [];
    },
    TEMImages: function () {
      if (this.specialMedia.length) {
        return this.specialMedia.filter((media) => {
          return media.scope == 'tem';
        });
      }
      return this.species && this.species.media ? this.species.media.tem : [];
    },

    fieldScopeStyle() {
      return `top: ${benchConfig.FieldScope.y}%; left: ${benchConfig.FieldScope.x}%;`;
    },
    dissectingStyle() {
      return `top: ${benchConfig.DissectingMicroscope.y}%; left: ${benchConfig.DissectingMicroscope.x}%;`;
    },
    compoundStyle() {
      return `top: ${benchConfig.CompoundMicroscope.y}%; left: ${benchConfig.CompoundMicroscope.x}%;`;
    },
    SEMStyle() {
      return `top: ${benchConfig.SEM.y}%; left: ${benchConfig.SEM.x}%;`;
    },
    TEMStyle() {
      return `top: ${benchConfig.TEM.y}%; left: ${benchConfig.TEM.x}%;`;
    },
  },
  watch: {
    img: function () {
      this.$nextTick(() => {
        this.resizeLabBench();
      });
    },
    saveLab: function (val) {
      if (val) {
        this.saveLabState();
      }
    },
    restoreLab: function (val) {
      if (val) {
        this.restoreLabState();
      }
    },
    presetsAutoLoad: function (val) {
      if (val.length > 0 && !this.autoload) {
        if (this.labReady) {
          setTimeout(() => {
            this.restoreLabState(val[0].object);
          }, Constants.INT_ZERO);
          this.$store.dispatch(
            StoreTypes.SET_AUTOLOAD_PRESET_LOADED_ACTION,
            true,
          );
        } else {
          this.autoload = val[0].object;
          this.restoreLabState(this.autoload);
        }
      }
    },
  },
}
</script>
<style scoped>
#labBenchWrapper {
  width: 100%;
  height: 100%;
}

#imageListsOverlay {
  display: flex;
  z-index: 5;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.w-full {
  width: 100%;
}

.row {
  display: flex;
}

.imageListWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 15%;
  height: 75%;
  margin: 0 2%;
  position: relative;
  justify-content: flex-end;
}

.imageList {
  width: 100%;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  left: 26px;
}

.imageList::-webkit-scrollbar {
  width: 30px;
  height: 30px;
  background-color: transparent;
}

.imageList::-webkit-scrollbar-track {
  background-color: transparent;
  /* border-left: 1px solid #f0f0f0; */
  /* border-top: 1px solid #f0f0f0; */
}

.imageList::-webkit-scrollbar-thumb:vertical {
  background-color: #d5d5d5;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  background-clip: content-box;
}

.imageList::-webkit-scrollbar-thumb:horizontal {
  background-color: #d5d5d5;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  background-clip: content-box;
}

.imageList::-webkit-scrollbar-thumb:vertical:hover {
  background-color: #a0a0a0;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  background-clip: content-box;
}

.imageList::-webkit-scrollbar-thumb:horizontal:hover {
  background-color: #a0a0a0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  background-clip: content-box;
}

.imageTooltip {
  width: 30%;
  height: fit-content;
  margin: 3px;
  cursor: pointer;
  pointer-events: all;
}

.thumbnailImage {
  width: 100%;
  height: 100%;
  border: 1px solid #17469e;
}

.imageConnector {
  margin-top: 4px;
}

.imageConnectorFieldScope {
  width: 30%;
}

.imageConnectorDissecting {
  width: 44%;
}

.imageConnectorCompound {
  width: 41%;
}

.imageConnectorSEM {
  width: 29%;
}

.imageConnectorTEM {
  width: 29%;
}

.selectedThumbnail {
  border: 2px solid yellow;
}

.microscopeLabel {
  position: absolute;
  color: black;
  font-weight: bold;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  cursor: default;
}

#canvasWrapper {
  /* background-image: url("../../assets/base/Microsopy_background.jpg"); */
  /* background-size: 100%; */
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

#labBackground {
  width: 100%;
  height: 100%;
}

.fieldScopeLabel {
  font-size: 0.7vw;
}

.dissectingLabel {
  font-size: 0.7vw;
  letter-spacing: -0.85px;
}

.compoundLabel {
  font-size: 0.65vw;
  letter-spacing: -0.8px;
}

.SEMLabel {
  font-size: 0.7vw;
}

.TEMLabel {
  font-size: 0.7vw;
}

@media only screen and (max-width: 1650px) {
  .fieldScopeLabel {
    font-size: 0.8vw;
  }

  .dissectingLabel {
    font-size: 0.7vw;
    letter-spacing: -0.2px;
  }

  .compoundLabel {
    font-size: 0.65vw;
    letter-spacing: -0.2px;
  }

  .SEMLabel {
    font-size: 0.7vw;
  }

  .TEMLabel {
    font-size: 0.7vw;
  }
}

@media only screen and (max-width: 1550px) {
  .fieldScopeLabel {
    font-size: 0.8vw;
  }

  .dissectingLabel {
    font-size: 0.7vw;
    letter-spacing: -0.2px;
  }

  .compoundLabel {
    font-size: 0.65vw;
    letter-spacing: -0.2px;
  }

  .SEMLabel {
    font-size: 0.7vw;
  }

  .TEMLabel {
    font-size: 0.7vw;
  }
}

@media only screen and (max-width: 1420px) {
  .fieldScopeLabel {
    font-size: 0.7vw;
  }

  .dissectingLabel {
    font-size: 0.7vw;
    letter-spacing: -0.85px;
  }

  .compoundLabel {
    font-size: 0.65vw;
    letter-spacing: -0.8px;
  }

  .SEMLabel {
    font-size: 0.7vw;
  }

  .TEMLabel {
    font-size: 0.7vw;
  }
}

@media only screen and (max-width: 1300px) {
  .fieldScopeLabel {
    font-size: 0.7vw;
  }

  .dissectingLabel {
    font-size: 0.7vw;
    letter-spacing: -0.85px;
  }

  .compoundLabel {
    font-size: 0.65vw;
    letter-spacing: -0.8px;
  }

  .SEMLabel {
    font-size: 0.7vw;
  }

  .TEMLabel {
    font-size: 0.7vw;
  }
}
</style>

<style src="../../styles/notifications.scss" lang="scss"></style>
<style src="../../styles/LabBench.scss" lang="scss"></style>
