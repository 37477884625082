const eventNamesConstants = {
  CLEAR_LAB: 'clearLab',
  ADD_SOLID_FROM_STOCKROOM: 'add-solid-from-stockroom',
  SOLID_POSITION_CHANGED: 'solidPosChanged',
  CLEAR_SOLID_FROM_STOCKROOM: 'clear-solid-from-stockroom',
  PLAY_DRAIN_ANIMATION: 'play-drain-animation',
  EXPLODE_EVENT: 'explode',
  ADD_SOLID_UNKNOWN: 'add-solid-unknown',
  SET_PADDING: 'setPadding',
};

export default Object.freeze(eventNamesConstants);
