<template>
  <div class="bench_wrapper" id="benchWrapper">
    <LabBench ref="LabBench" :zoomPopupVisible="zoomPopupOpen" />
    <Popup v-if="zoomPopupOpen" @close="closeZoomPopup" :headerMargin="false" :innerPopup="true" height="86%" width="83%" ref="popup"
      style="top: 85px;" :title="scopeTypeText" :grayTitle="true" :headerBorder="true">
      <SpeciesZoom />
    </Popup>
  </div>
  <div class="tray no-select" id="trayDiv">
    <TrayMenu ref="TrayMenu" @clearLab="clearLab" />
    <div class="sidebar_outer">
      <div class="sidebar_inner" id="trayPanels">
        <TrayPanelProcedures ref="TPL" v-show="panels === PanelConstants.PROCEDURES" />
        <keep-alive>
          <TrayPanelLiveData ref="TPL" v-if="panels === PanelConstants.LIVEDATA" @zoom="zoom" />
        </keep-alive>
        <keep-alive>
          <TrayPanelSpecies ref="TPS" v-if="panels === PanelConstants.SPECIES" />
        </keep-alive>
        <LabBook v-if="panels === PanelConstants.LABBOOK" />
        <TrayPanelPresets ref="presetPanel" v-if="panels === PanelConstants.PRESETS" />
      </div>
    </div>
  </div>
</template>
<script>
import LabBench from './LabBench/LabBench.vue';
import TrayPanelLiveData from './TrayPanelLiveData.vue';
import TrayPanelProcedures from '@/components/TrayPanelProcedures.vue';
import TrayMenu from './TrayMenu.vue';
import TrayPanelSpecies from './TrayPanelSpecies.vue';
import { mapState, mapGetters } from 'vuex';
import * as StoreTypes from '../store/store-types';
// import TrayPanelUnknowns from './TrayPanelUnknowns.vue';
import LabBook from './LabBook/LabBook.vue';
import TrayPanelPresets from './TrayPanelPresets.vue';
import PanelConstants from '../enums/PanelConstants';
import * as HtmlToImage from 'html-to-image';
import Popup from './utilities/Popup.vue';
import * as LabBookMiddleware from './LabBook/scripts/LabBookMiddleware';
import SpeciesZoom from './SpeciesZoom.vue'
import BiologyLabEnum from '../enums/BiologyLabEnum';
import Constants from '../enums/Constants';
import { entry } from '../services/xapi';
import xapiEntryTypes from '../enums/xapiEntryTypes';
import NotificationMixin from '../mixins/NotificationMixin.js';
import html2canvas from 'html2canvas';
export default {
  name: 'Tray',
  data: () => ({
    PanelConstants,
    onResizeTimer: undefined,
    zoomPopupVisible: false,
  }),
  props: [],
  created() { },
  mixins: [NotificationMixin],
  mounted() { },
  methods: {
    closeZoomPopup: function () {
      // this.zoomPopupVisible = false;
      this.$store.dispatch(StoreTypes.SET_SPECIES_ZOOM_POPUP_OPEN_ACTION, false);
      const extensions = {
        name: Constants.ZOOM_POPUP,
        state: Constants.CLOSED,
      };
      entry(xapiEntryTypes.STATE, {
        extensions: extensions,
      });
    },
    zoom: function () {
      // this.zoomPopupVisible = true;
      this.$store.dispatch(StoreTypes.SET_SPECIES_ZOOM_POPUP_OPEN_ACTION, true);
      const extensions = {
        name: Constants.ZOOM_POPUP,
        state: this.currentMedia.title,
        scope: this.currentMedia.scope,
      };
      entry(xapiEntryTypes.STATE, {
        extensions: extensions,
      });
    },
    setPanel: function (panel) {
      switch (panel) {
        default:
          return false;
      }
    },
    resizeTray: function (width, height, trayWidth) {
      const trayHeight = height - 30;

      const trayDiv = document.getElementById('trayDiv');

      trayDiv.style.width = `${trayWidth}px`;
      trayDiv.style.height = `${trayHeight}px`;

      const benchDiv = document.getElementById('benchWrapper');

      const labWidth = width - trayWidth;
      const labHeight = labWidth * (3 / 4);

      benchDiv.style.width = `${labWidth}px`;
      benchDiv.style.height = `${labHeight}px`;

      this.$nextTick(() => {
        this.$refs.LabBench.resizeLabBench();
        if (this.$refs.TPS) {
          this.$refs.TPS.updateClientHeight();
        }
      });
    },
    clearLab: function () {
      this.$refs.LabBench.clearEntireLab(true);
    },
    captureEntireScreen: function () {
      let labRef = document.getElementById(`benchWrapper`);

      setTimeout(() => {
        let labBench = document.getElementById('labBenchWrapper');
        html2canvas(labBench, {
          allowTaint: true,
          useCORS: true,
        }).then((labCanvas) => {
          let trayMenuPanels = document.getElementById('trayMenuPanels');
          html2canvas(trayMenuPanels, {
            allowTaint: true,
            useCORS: true,
          }).then((trayMenuCanvas) => {
            let trayPanels = document.getElementById('trayPanels');
            html2canvas(trayPanels, {
              allowTaint: true,
              useCORS: true,
            }).then(async (trayPanelsCanvas) => {
              let popupCanvas = null;
              if (this.zoomPopupOpen) {
                let PopupWrapper = document.querySelector(".PopupWrapper");
                popupCanvas = await html2canvas(PopupWrapper, {
                  allowTaint: true,
                  useCORS: true,
                });
              }
              let scaledCanvas = document.createElement('canvas');
              let ctx = scaledCanvas.getContext('2d');
              let scalingFactor = 1;

              let labWidth = Math.max(labRef.clientWidth, labCanvas.width);
              let scaledCanvasHeight = Math.max(labRef.clientHeight, trayPanelsCanvas.height, trayMenuCanvas.width);
              let scale = scaledCanvasHeight / labCanvas.height;
              
              let scaledCanvasWidth =
                Math.max(labWidth * scale, labWidth) + Math.max(trayPanelsCanvas.width * scale, trayPanelsCanvas.width) + Math.max(trayMenuCanvas.width * scale, trayMenuCanvas.width);
              scaledCanvas.width = scaledCanvasWidth;
              scaledCanvas.height = scaledCanvasHeight;
              scaledCanvas.style.width = `${scaledCanvasWidth}px`;
              scaledCanvas.style.height = `${scaledCanvasHeight}px`;

              ctx.scale(scalingFactor, scalingFactor);
              //lab
              ctx.drawImage(labCanvas, 0, 0, labWidth * scale, scaledCanvasHeight);
              if (popupCanvas) {
                ctx.drawImage(popupCanvas, 0, 0, popupCanvas.width * scale, popupCanvas.height * scale);
              }
              //tray menu
              ctx.drawImage(
                trayMenuCanvas,
                labWidth * scale,
                0,
                trayMenuCanvas.width * scale,
                scaledCanvasHeight
              );
              // tray
              ctx.drawImage(
                trayPanelsCanvas,
                labWidth * scale + trayMenuCanvas.width * scale,
                0,
                trayPanelsCanvas.width * scale,
                scaledCanvasHeight
              );

              let imgBase64 = encodeURI(scaledCanvas.toDataURL());
              try {
                LabBookMiddleware.lab_book('entry', {
                  entry: imgBase64,
                  type: 3,
                });
                this.showNotification('Image saved.');
              } catch (e) {
                console.error(e);
              }
            });
          });
        });
      }, Constants.CONSTANT_THOUSAND);
    },
  },
  components: {
    LabBench,
    TrayMenu,
    TrayPanelSpecies,
    TrayPanelLiveData,
    TrayPanelProcedures,
    LabBook,
    TrayPanelPresets,
    Popup,
    SpeciesZoom,
  },
  computed: {
    ...mapState({
      Tray: (state) => {
        return state.tray;
      },
    }),
    ...mapGetters({
      panels: StoreTypes.PANELS,
      currentMedia: StoreTypes.GET_CURRENT_MEDIA,
      selectedSpecies: StoreTypes.GET_CURRENT_SPECIES,
      zoomPopupOpen: StoreTypes.GET_SPECIES_ZOOM_POPUP_OPEN,
    }),
    scopeTypeText: function () {
      if (!this.currentMedia) {
        return '';
      }
      let types = {
        field: this.getLocaleData(
          BiologyLabEnum.MICROSCOPY,
          Constants.FIELD_SCOPE,
        ),
        compound: this.getLocaleData(
          BiologyLabEnum.MICROSCOPY,
          Constants.COMPOUND_MICROSCOPE,
        ),
        dissection: this.getLocaleData(
          BiologyLabEnum.MICROSCOPY,
          Constants.DISSECTING_MICROSCOPE,
        ),
        sem: this.getLocaleData(
          BiologyLabEnum.MICROSCOPY,
          Constants.SEM,
        ),
        tem: this.getLocaleData(
          BiologyLabEnum.MICROSCOPY,
          Constants.TEM,
        ),
      };
      return types[this.currentMedia.scope];
    },
  },
  watch: {
    zoomPopupOpen: function (value) {
      if (value) {
        this.$refs.popup.focus();
      }
    },
    selectedSpecies: function (value) {
      this.$store.dispatch(StoreTypes.SET_SPECIES_ZOOM_POPUP_OPEN_ACTION, false);
    },
  },
}
</script>
<style scoped></style>

<style scoped src="../styles/Tray.scss" lang="scss"></style>
