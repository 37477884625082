import { v4 as uuidv4 } from 'uuid';
import XhrClient from './XhrClient';

const xapi = {
  session: undefined,
  sequence: 0,
  entries: [],
  actor: 'DEVELOPMENT',
  commitEnabled: false,
  callback: undefined,
  init(options) {
    return new Promise((resolve) => {
      let params = {
        actor: 'DEVELOPMENT',
        ...options,
      };
      this.actor = params.actor;
      this.session = uuidv4();
      resolve();
    });
  },
  setupCallback(callback) {
    xapi.callback = callback;
  },
  enableCommit(enabled) {
    xapi.commitEnabled = enabled;
  },
  entry(activity, object) {
    this.sequence++;
    switch (activity) {
      case 'state':
        xapi.entry_state(object);
        break;
      case 'startup':
        xapi.entry_startup(object);
        break;
    }
  },
  entry_actor() {
    const actor = {
      account: {
        homePage: `https://${process.env.VUE_APP_ACTIVE_DOMAIN}.com`,
        name: `${xapi.actor}`,
      },
    };
    return actor;
  },
  entry_state(object) {
    const obj = {
      id: `http://lrs.${process.env.VUE_APP_ACTIVE_DOMAIN}.com/activities/state`,
      definition: {
        name: { 'en-US': 'state changed' },
      },
      extensions: object.extensions,
    };

    xapi.commit_entry(obj);
  },
  entry_startup(object) {
    const obj = {
      id: `http://lrs.${process.env.VUE_APP_ACTIVE_DOMAIN}.com/activities/startup`,
      definition: {
        name: { 'en-US': 'lab start up' },
      },
      extensions: object.extensions,
    };

    xapi.commit_entry(obj);
  },
  commit_entry(entry = {}) {
    let extensions = {};
    extensions[`https://lrs.${process.env.VUE_APP_ACTIVE_DOMAIN}.com/extensions/session`] = xapi.session;
    extensions[`https://lrs.${process.env.VUE_APP_ACTIVE_DOMAIN}.com/extensions/sequence`] = xapi.sequence;
    if (typeof entry.extensions !== 'undefined') {
      for (const key in entry.extensions) {
        extensions[`https://lrs.${process.env.VUE_APP_ACTIVE_DOMAIN}.com/extensions/${key}`] = entry.extensions[key];
      }
      delete entry.extensions;
    }

    let statement = {
      actor: xapi.entry_actor(),
      verb: {
        id: 'http://adlnet.gov/expapi/verbs/experienced',
        display: { 'en-US': 'experienced' },
      },
      object: { ...entry },
      context: {
        extensions: {
          ...extensions,
        },
      },
    };

    this.entries.push(statement);

    if (!xapi.commitEnabled) return;

    let opts = {
      url: `https://lrs.${process.env.VUE_APP_ACTIVE_DOMAIN}.com/xapi/statements`,
      body: [statement],
      contentType: 'application/json',
      authBasic:
        'ZTJiMjk1YzQwOTE1YWJhNDI4MGE5MWMwZTlkMDJmNTUwZDk3ZTQ0NTo5MDkyZDA1NzIxYmExNjU5NmE0ZmQ1ZWI2YWMxYzg1ZmY4YzYxNWI1',
      authHeader: true,
      method: 'POST',
      xAPI: true,
    };
    let data2 = {
      ...statement,
      authority: {
        mbox: "mailto:info@beyondlabz.com",
        name: "Beyond Labz",
      },
      attachments: [],
      stored: new Date().toISOString(),
      timestamp: new Date().toISOString(),
      id: uuidv4(),
    }

    // XhrClient.request(opts).then(
    //   (data) => {
        xapi.callback(data2);
    //   },
    //   (error) => {
    //     console.error('XHR XAPI ERROR', error);
    //   },
    // );
  },
};

export function initXAPI(options) {
  return xapi.init(options);
}

export function enableCommit(enabled) {
  return xapi.enableCommit(enabled);
}

export function entry(activity, object) {
  return xapi.entry(activity, object);
}

export function setupCallback(callback) {
  return xapi.setupCallback(callback);
}
