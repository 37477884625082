/* Copyright (C) 2021 Beyond Labz LLC - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

"use strict";
import XhrClient from "../services/XhrClient";
export default class BiologyDBClient
{
	constructor() {
		 this.currentLang = 'en';
	}
	get language ()
	{
		return this.currentLang;
		// The sdk won't care about this, but there needs to be away for the ui/middleware to set it  It doesn't need to be implemented this way - added here as more of a reminder.
	}
	set language ( lang )
	{
		this.currentLang = lang;
		// The sdk won't care about this, but there needs to be away for the ui/middleware to set it  It doesn't need to be implemented this way - added here as more of a reminder.
	}


	/**
	 * Returns the status code for the last call.
	 */
	getStatus ()
	{
		// May (or may not) need this?
		throw new Error( "Implement me?" );
	}

	/**
	 * Returns the status message for the last call
	 */
	getStatusMessage ()
	{
		// May (or may not) need this?
		throw new Error( "Implement me?" );
	}

	/**
	 * Queries and returns promise containing list of species in the biology database.
	 * @param {string} lab Lab identifier of species to filter by.  "microscopy", "ecology", "genetics", "systematics", "molecular" or null.  If null all species will be returned.
	 * @param {boolean} lazyLoad When true (default)  only loads summary data - like names.  When false, load all data and descriptions associated with species.
	 * @param {boolean} ignoreApproved Whether to ignore the approved flag.
	 * @returns {array}
	 */
	async getSpeciesList ( lab = null, lazyLoad = true, ignoreApproved = false )
	{
		let opts = {
			url: `https://presets.staging-beyondlabz.com/biology/getSpeciesList`, 
			body: {
				lab,
				lazyLoad,
				ignoreApproved,
				lang: this.language,
			},
			contentType: 'application/json',
			method: 'POST',
			authHeader: false,
		};

		return await XhrClient.request(opts);
	}

	/**
	 * Queries and returns promise containing object with properties not included with call to getSpeciesList.  Primarily description.
	 * @param {int} id The id of the species to load data for.
	 * @returns {object|null} Species data object when found.  Or null if not found.
	 */
	async getSpecies ( id )
	{
		let opts = {
			url: `https://presets.staging-beyondlabz.com/biology/getSpecies/${id}`, 
			body: {
				lang: this.language,
			},
			contentType: 'application/json',
			method: 'POST',
			authHeader: false,
		};

		return await XhrClient.request(opts);
	}



	/**
	 * Queries the database to get all of the SpeciesCategories data from the database.  
	 * Returns promise.
	 * @returns {array} 
	 */
	async getSpeciesCategories ()
	{
		let opts = {
			url: `https://presets.staging-beyondlabz.com/biology/getSpeciesCategories`, 
			body: {
				lang: this.language,
			},
			contentType: 'application/json',
			method: 'POST',
			authHeader: false,
		};

		return await XhrClient.request(opts);
	}


	/**
	 * Queries the database for all available SpeciesCategoryRelation objects.  Returns promise.
	 * @param includeCategoryName Whether to include the category name in the results.  (Setting this to true will happen in admin tool.)
	 * @returns {array} Array wrapped in Promise.
	 */
	async getSpeciesCategoryRelations ( includeCategoryName = false )
	{
		let opts = {
			url: `https://presets.staging-beyondlabz.com/biology/getSpeciesCategoryRelations`, 
			body: {},
			contentType: 'application/json',
			method: 'POST',
			authHeader: false,
		};

		return await XhrClient.request(opts);
	}


	/**
	 * Query database returns all species selector data in 1 call.
	 * getSpeciesList() - other than the lab parameter, will assume others are the default.
	 * getSpeciesCategories()
	 * getSpeciesCategoryRelations()
	 * @param lab Lab filter to apply to getSpeciesList.  
	 * @returns {object} Object with { species: [], speciesCategories: [], speciesCategoryRelations: [] }
	 */
	async getAllSpeciesData ( lab = null, lazyLoad = true, ignoreApproved = false )
	{
		// Hopefully this can be made as a single api call.  For speed and efficiency purposes.

		// If not, worst case is those 3 functions can be called at the same time.  
		//   Note: if you end up doing this to get them all going at the same time see this noting the placement of the awaits:
		//     let speciesPromise = this.getSpecies();
		//     let categoriesPromise = this.getSpeciesCategories();
		//     let relations = await this.getSpeciesCategoryRelations();
		//     let species = await speciesPromise;
		//     let categories = await categoriesPromise;
		//    (At least I think I've done that before.  You would know this works if all 3 are fired at the same time, not 1 at a time as one finishes and you end up with all of the data.)
		//    Or do something else if there is a better way to do that (but hopefully it can be a single api call)
		let opts = {
			url: `https://presets.staging-beyondlabz.com/biology/getAllSpeciesData`, 
			body: {
				lab,
				lazyLoad,
				ignoreApproved,
				lang: this.language,
			},
			contentType: 'application/json',
			method: 'POST',
			authHeader: false,
		};

		return await XhrClient.request(opts);
	}


	/**
	 * Queries the database for all attribution objects.  Returns promise.
	 * @returns {array} Array wrapped in Promise.
	 */
	async getAttributions ()
	{
		// The old code had an option to query specific ids. But there arne't that many of them (for now anyway).  I think it better to just to download them all.
		// If you see a need to query for specific ones, go ahead and add a default array parameter containing the ids or something.
		let opts = {
			url: `https://presets.staging-beyondlabz.com/biology/getAttributions`, 
			body: {},
			contentType: 'application/json',
			method: 'POST',
			authHeader: false,
		};

		return await XhrClient.request(opts);
	}


	/**
	 * Queries the database for all magnification objects.  Returns promise.
	 * @returns {array} Array wrapped in Promise.
	 */
	async getMagnifications ()
	{
		// The old code had an option to query specific ids. But there arne't that many of them (for now anyway).  I think it better to just to download them all.
		// If you see a need to query for specific ones, go ahead and add a default array parameter containing the ids or something.
		let opts = {
			url: `https://presets.staging-beyondlabz.com/biology/getMagnifications`, 
			body: {
				lang: this.language,
			},
			contentType: 'application/json',
			method: 'POST',
			authHeader: false,
		};

		return await XhrClient.request(opts);
	}



	/**
	 * Query database returns all species selector data in 1 call.
	 * getAttributions()
	 * getMagnifications()
	 * @returns {object} Object with { attributions: [], magnifications: [] }
	 */
	async getAllMicroscopyData ()
	{
		// Hopefully this can be made as a single api call.  For speed and efficiency purposes.
		// If not, worst case is those 2 functions can be called at the same time.  
		let opts = {
			url: `https://presets.staging-beyondlabz.com/biology/getAllMicroscopyData`, 
			body: {
				lang: this.language,
			},
			contentType: 'application/json',
			method: 'POST',
			authHeader: false,
		};

		return await XhrClient.request(opts);
	}


	/**
	 * Queries the database for available Media objects for the given speciesID
	 * Media returned by this call will NOT have the arrows, description properties set on the Media objects, the value of the properties will be the class default.
	 * Promise is returned.
	 * @param speciesId The id of the species media to query for.
	 * @param approved Whether only approved media should be returned.  Default should be true.  The SDK will always expect this to be true.
	 * @returns {Array} 
	 */
	async getMedia ( speciesID, approved = true )
	{
		// NOTE to prevent abuse from users who know about F12. I would suggest ONLY sending the 'approved' parameter back to the api when it is false.  In the api it should default to true, unless a falsy get back there.
		let opts = {
			url: `https://presets.staging-beyondlabz.com/biology/getMedia`, 
			body: {
				lang: this.language,
				speciesID,
			},
			contentType: 'application/json',
			method: 'POST',
			authHeader: false,
		};
		if (!approved) {
			opts.body.approved = false;
		}

		return await XhrClient.request(opts);
	}

	/**
	 * Queries the database for available Media objects for the given mediaIDs
	 * Media returned by this call will NOT have the arrows, description properties set on the Media objects, the value of the properties will be the class default.
	 * Promise is returned.
	 * @param mediaIDs Array of media IDs to query for.
	 * @param approved Whether only approved media should be returned.  Default should be true.  The SDK will always expect this to be true.
	 * @returns {Array} 
	 */
	 async getMediaByID ( mediaIDs, approved = true )
	 {
		 // NOTE to prevent abuse from users who know about F12. I would suggest ONLY sending the 'approved' parameter back to the api when it is false.  In the api it should default to true, unless a falsy get back there.
		 let opts = {
			 url: `https://presets.staging-beyondlabz.com/biology/getMediaByID`, 
			 body: {
				 lang: this.language,
				 mediaIDs,
			 },
			 contentType: 'application/json',
			 method: 'POST',
			 authHeader: false,
		 };
		 if (!approved) {
			 opts.body.approved = false;
		 }
 
		 return await XhrClient.request(opts);
	 }
	
	async getAllMedia ( approved = true )
	{
		// NOTE to prevent abuse from users who know about F12. I would suggest ONLY sending the 'approved' parameter back to the api when it is false.  In the api it should default to true, unless a falsy get back there.
		let opts = {
			url: `https://presets.staging-beyondlabz.com/biology/getAllMedia`, 
			body: {
				lang: this.language,
			},
			contentType: 'application/json',
			method: 'POST',
			authHeader: false,
		};
		if (!approved) {
			opts.body.approved = false;
		}

		return await XhrClient.request(opts);
	}

	/**
	 * Queries the database for the description text associated with the specifed media id.
	 * Promise is returned.
	 * @param mediaId the media to search for
	 * @returns {String} 
	 */
	async getMediaDescription ( mediaID )
	{
		// The original code returned string.  We may find it is better to return object of some form.
		let opts = {
			url: `https://presets.staging-beyondlabz.com/biology/getMediaDescription`, 
			body: {
				lang: this.language,
				mediaID,
			},
			contentType: 'application/json',
			method: 'POST',
			authHeader: false,
		};
		return await XhrClient.request(opts);
	}


	/**
	 * Queries the database for the MediaArrow data associated with the specifed media id.
	 * Promise is returned.
	 * @param mediaId the media to search for
	 * @returns {array} 
	 */
	async getMediaArrows ( mediaID )
	{
		let opts = {
			url: `https://presets.staging-beyondlabz.com/biology/getMediaArrows`, 
			body: {
				lang: this.language,
				mediaID,
			},
			contentType: 'application/json',
			method: 'POST',
			authHeader: false,
		};
		return await XhrClient.request(opts);
	}


	/**
	 * Query database returns all media data in 1 call.
	 * getMediaDescription()
	 * getMediaArrows()
	 * @param mediaID the id of the media object we want data for.
	 * @returns {object} Object with { description: "", arrows: [] }
	 */
	async getAllMediaData ( mediaID )
	{
		// Hopefully this can be made as a single api call.  For speed and efficiency purposes.
		// If not, worst case is those 2 functions can be called at the same time. 
		let opts = {
			url: `https://presets.staging-beyondlabz.com/biology/getAllMediaData`, 
			body: {
				lang: this.language,
				mediaID,
			},
			contentType: 'application/json',
			method: 'POST',
			authHeader: false,
		};
		return await XhrClient.request(opts);
	}


	/////////////// Diversity Related calls //////////////////////


	/**
	 * Queries the database for available DiversityTrees objects.
	 * 
	 * @param approved Whether to query only approved trees.
	 * @param includeNodes Whether to include the nodes of the tree with the query.
	 * @returns {array} Array of trees via Promise.
	 */
	async getAllDiversityTrees ( approved = true, includeNodes = false )
	{
		let opts = {
			url: `https://presets.staging-beyondlabz.com/biology/getAllDiversityTrees`, 
			body: {
				lang: this.language,
				includeNodes,
			},
			contentType: 'application/json',
			method: 'POST',
			authHeader: false,
		};
		if (!approved) {
			opts.body.approved = false;
		}

		return await XhrClient.request(opts);
	}


	/**
	 * Queries the database for specified approved DiversityTrees objects.  Can query for and exclude specific tree ids.
	 * 
	 * @param {array} queryFor List of treeIds of the trees to specfically query for.  If null or if the list is empty all trees will be queried for.
	 * @param {array} exclude List of treeIds to exclude.  If an id exists in this and the queryFor list, then the tree id is excluded.
	 * @returns {array} Array of trees via Promise.
	 */
	async getDiversityTrees ( queryFor, exclude )
	{
		let opts = {
			url: `https://presets.staging-beyondlabz.com/biology/getDiversityTrees`, 
			body: {
				lang: this.language,
				queryFor,
				exclude,
			},
			contentType: 'application/json',
			method: 'POST',
			authHeader: false,
		};

		return await XhrClient.request(opts);
	}


	/**
	 * Queries the database for all GlossaryTerm objects.
	 * @returns {array} Array of glossary terms via Promise.
	 */
	async getGlossaryTerms ()
	{
		let opts = {
			url: `https://presets.staging-beyondlabz.com/biology/getGlossaryTerms`, 
			body: {
				lang: this.language,
			},
			contentType: 'application/json',
			method: 'POST',
			authHeader: false,
		};
		return await XhrClient.request(opts);
	}


	/////////////// Molecular Related calls //////////////////////

	/**
	 * >Queries the database for all MolecularSetting objects. 
	 * Any MolecularSettings objects are returned with the associated globalDna, globalPcr and defaultPcr objects
	 * ALSO having been quieried and already assigned to the associated MolecularSettings objects.
	 * It is important to note that at the time of this writing there is only assumed to be 1 MolecularSetting entry in the database.
	 * @returns {object} MolecularSettings data in object form via Promise
	 */
	async getMolecularSettings ()
	{
		// Future us note from Greg: I'm not sure what the global* stuff is talking about, we will need to see if this should be a database/api thing or a SDK.
		//   Probably api/db thing, but we can double check.
		let opts = {
			url: `https://presets.staging-beyondlabz.com/biology/getMolecularSettings`, 
			body: {},
			contentType: 'application/json',
			method: 'POST',
			authHeader: false,
		};
		return await XhrClient.request(opts);
	}


	/**
	 * <p>Queries the database for the DNA data assigned to a specific species.</p>
	 * <p>The query will also query for Genes associated with the the same Species and assign those
	 * Genes to the <code>genes</code> property of the DNA class.</p>
	 * 
	 * @param speciesId The Species' database id to query for in the database.
	 * @returns {array} Array of dna objects terms via Promise.
	 */
	async getDNABySpeciesId ( speciesId )
	{
		let opts = {
			url: `https://presets.staging-beyondlabz.com/biology/getDNABySpeciesId`, 
			body: {
				speciesId,
			},
			contentType: 'application/json',
			method: 'POST',
			authHeader: false,
		};
		return await XhrClient.request(opts);
	}



	/////////////// Genetics Related calls //////////////////////

	/**
	 * <p>Queries the database for the GeneticsBackground with the backgroundId.</p>
	 * @param backgroundId The backgrounds database id to query for in the database.  If null (default) is passed in all backgrounds will be returned.
	 * @returns {array} Array of geneticBackground object(s) via Promise.
	 */
	async getGeneticBackgrounds ( backgroundId = null )
	{
		let opts = {
			url: `https://presets.staging-beyondlabz.com/biology/getGeneticBackgrounds`, 
			body: {
				backgroundId,
			},
			contentType: 'application/json',
			method: 'POST',
			authHeader: false,
		};
		return await XhrClient.request(opts);
	}


	/**
	 * <p>Queries the database for the Genetics data assigned to a specific species.</p>
	 * <p>The Genetics Objects returned will be fully populated with traits, linkedLoci and geneticBackground properties
	 * fully populated each their own sub-properties fully populated where needed.</p> 
	 * 
	 * @param speciesId The Species' database id to query for in the database.
	 * @returns {array} Array of genetic objects via Promise.
	 */
	async getGeneticsBySpeciesId ( speciesId )
	{
		let opts = {
			url: `https://presets.staging-beyondlabz.com/biology/getGeneticsBySpeciesId`, 
			body: {
				speciesId,
				lang: this.language,
			},
			contentType: 'application/json',
			method: 'POST',
			authHeader: false,
		};
		return await XhrClient.request(opts);
	}



	/**
	 * <p>Queries the database for the GeneticsSettings data.</p>
	 * 
	 * @param settingsId The GeneticsSettings database id to query for in the database.  If set to null (default) all GeneticsSettings are returned.
	 * @returns {array} Array of genetic settings via Promise.
	 */
	async getGeneticsSettings ( settingsId = null )
	{
		let opts = {
			url: `https://presets.staging-beyondlabz.com/biology/getGeneticsSettings`, 
			body: {
				settingsId,
			},
			contentType: 'application/json',
			method: 'POST',
			authHeader: false,
		};
		return await XhrClient.request(opts);
	}


	//////////////////////////////////////////


	/**
	 * <p>Queries the database for the EcologySettings data.</p>
	 * 
	 * @param settingsId The EcologySettings database id to query for in the database.  If set to null all EcologySettings are returned.
	 * @returns {array} Array of ecology settings via Promise.
	 */
	async getEcologySettings ( settingsId = null )
	{
		let opts = {
			url: `https://presets.staging-beyondlabz.com/biology/getEcologySettings`, 
			body: {
				settingsId,
			},
			contentType: 'application/json',
			method: 'POST',
			authHeader: false,
		};
		return await XhrClient.request(opts);
	}


	/**
	 * <p>Queries the database for the Ecology data.</p>
	 * 
	 * @param speciesId The speciesId associated with the Ecology object to return.
	 * @returns {object} Ecology object via Promise.
	 */
	async getEcology ( speciesId )
	{
		let opts = {
			url: `https://presets.staging-beyondlabz.com/biology/getEcology`, 
			body: {
				speciesId,
			},
			contentType: 'application/json',
			method: 'POST',
			authHeader: false,
		};
		XhrClient.request(opts).then((data) => {
			return data;
		}).catch((error) => {
			return error;
		});
	}



	/**
	 * <p>Queries the database for the FoodClassification data.</p>
	 * @returns {array} Array of food classification objects.
	 */
	async getFoodClassifications ()
	{
		let opts = {
			url: `https://presets.staging-beyondlabz.com/biology/getFoodClassifications`, 
			body: {},
			contentType: 'application/json',
			method: 'POST',
			authHeader: false,
		};
		return await XhrClient.request(opts);
	}


	/**
	 * <p>Queries the database for the Biome data.</p>
	 * @returns {array} Array of food classification objects.
	 */
	async getBiomes ()
	{
		let opts = {
			url: `https://presets.staging-beyondlabz.com/biology/getBiomes`, 
			body: {
				lang: this.language,
			},
			contentType: 'application/json',
			method: 'POST',
			authHeader: false,
		};
		return await XhrClient.request(opts);
	}

	/*
	 * Query database returns all ecology data in 1 call.
	 * getEcologySettings()
	 * getFoodClassifications()
	 * getBiomes()
	 * @returns {object} Object with { settings: [], food: [], biomes: [] }
	*/
	async getAllEcologyData ()
	{
		// HOpefully this can all be done in 1 api call
		let opts = {
			url: `https://presets.staging-beyondlabz.com/biology/getAllEcologyData`, 
			body: {
				lang: this.language,
			},
			contentType: 'application/json',
			method: 'POST',
			authHeader: false,
		};
		return await XhrClient.request(opts);
	}

}
