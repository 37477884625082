<template>
  <section :aria-label="getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.LIVE_DATA_TRAY_TITLE)" class="section_wrapper row">
    <div id="speciesWrapper" class="column h-full speciesWrapper">
      <div class="selectedSpeciesName" v-if="media">{{ media.title }}</div>
      <!-- <div v-if="media" v-show="media.type == 'static'" v-html="currentImage"
        class="speciesImage">
      </div>-->
      <img v-if="media" v-show="media.t == 'static'" class="speciesImage" id="speciesImage"
        :src="S3URL + PATH + media.i + (media.t == 'static' ? JPG : MP4)" :alt="media.title" />
      <video v-if="media && media.t != 'static'" data-setup="{}" type="video/mp4" id="mainImage" ref="video" controls
        crossorigin="anonymous">
        <source :src="S3URL + PATH + media.i + MP4 + '#t=0.01'" type="video/mp4" />
      </video>
      <div v-if="media && media.attribution" class="attribution">{{ media.attribution }}</div>
      <!-- <span v-if="media">{{media.title}}</span> -->
      <div v-if="media" class="column selectedSpeciesDescription user-selectable" v-html="media.description"></div>
    </div>
    <div class="column h-full buttonsColumn" v-if="media">
      <button @click="saveToLabBook()" class="inline-flex items-center btn focus:outline-none ripple">
        <span>
          <img src="../assets/live_data_icons/SaveToLabBook-24px.svg" alt="" class="btn-icon" />
        </span>
        {{ saveText }}
      </button>
      <button @click="zoom" class="inline-flex items-center btn focus:outline-none ripple">
        <span>
          <img src="../assets/live_data_icons/zoom_in-24px.svg" alt="" class="btn-icon" />
        </span>
        {{ zoomText }}
      </button>
      <!-- buttons -->
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex'
import Constants from '../enums/Constants';
import html2canvas from 'html2canvas';
import * as LabBookMiddleware from './LabBook/scripts/LabBookMiddleware';
import BiologyLabEnum from '../enums/BiologyLabEnum';
import NotificationMixin from '../mixins/NotificationMixin.js';
export default {
  name: 'TrayPanelLiveData',
  mixins: [NotificationMixin],
  data: function () {
    return {
      S3URL: Constants.S3_BUCKET_URL,
      JPG: Constants.IMG_EXT_JPG,
      THUMB: Constants.THUMBNAIL,
      F4V: Constants.IMG_EXT_F4V,
      MP4: Constants.IMG_EXT_MP4,
      PATH: Constants.MEDIA_CONTENT,

      saveText: this.getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.SAVE),
      zoomText: this.getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.ZOOM),
      BiologyLabEnum,
      Constants,

    }
  },
  props: [],
  created() { },
  mounted() { },
  methods: {
    zoom: function () {
      this.$emit("zoom");
    },
    saveToLabBook: function () {
      if (this.media.type == 'static') {
        html2canvas(document.getElementById('speciesImage'), {
          allowTaint: true,
          // foreignObjectRendering: true,
          useCORS: true,
          // width: 420,
        }).then((generatedCanvas) => {
          let image = generatedCanvas.toDataURL('image/png');
          try {
            LabBookMiddleware.lab_book('entry', {
              entry: image,
              type: 3,
            });
            this.showNotification('Image saved.');
          } catch (e) {
            console.error(e);
          }
        });
      } else {
        let canvas = document.createElement('CANVAS');
        let saveImage = () => {
          let imageToSave = canvas.toDataURL('image/png');
          try {
            LabBookMiddleware.lab_book('entry', {
              entry: imageToSave,
              type: 3,
            });
            this.showNotification('Image saved.');
          } catch (e) {
            console.error('Entry Save Fail', data);
          }
        };
        canvas.width = this.$refs.video.clientWidth;
        canvas.height = this.$refs.video.clientHeight;
        let ctx = canvas.getContext('2d');
        let video = this.$refs.video;
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        saveImage();
      }
    },
  },
  components: {},
  computed: {
    ...mapGetters({
      species: 'GET_CURRENT_SPECIES',
      media: 'GET_CURRENT_MEDIA',
    }),
    currentImage() {
      return this.media.img.outerHTML;
    },
  },
  watch: {
    media: function (value) {
      if (value && value.type != 'static') {
        this.$nextTick(() => {
          this.$refs.video.load();
        });
      }
    },
  },
}
</script>

<style lang="scss" scoped src="../styles/TrayPanelLiveData.scss">

</style>
