import store from '../store/index';
import * as StoreTypes from '../store/store-types';
const lang = store.getters[StoreTypes.GET_CURRENT_LANGUAGE];
import dataEn from "../assets/localization/common-lang-en.json";
import dataEs from "../assets/localization/common-lang-es.json";

// const data = require();

let dictionary = {
  en: {
    common: dataEn,
  },
  es: {
    common: dataEs,
  }
};

export { dictionary };
