export default {
  methods: {
    /*
      text: [string], // Notification text
    */
    showNotification(text) {
      this.$toast.show(
        `<div class="notification-alert" role="alert" aria-live="polite" tabindex="-1">
        <img class="notification-img" alt="" />
        <p>${text}</p>
      </div>`,
      );
    },
    showErrorNotification(text) {
      this.$toast.show(
        `<div class="notification-error" role="alert" aria-live="polite" tabindex="-1">
        <img class="notification-img" alt="" />
        <p>${text}</p>
      </div>`,
      );
    },
    showSRNotification(text) {
      this.$toast.show(
        `<div class="notification sr-only" role="alert" aria-live="polite" tabindex="-1">
        <img class="notification-img" alt="" />
        <p>${text}</p>
      </div>`,
      );
    },
  },
};
