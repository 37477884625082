import LabsList from './labs-list';
const BridgeModule = {
  state: () => ({
    user: {
      token: undefined,
      email: '',
      userId: undefined,
    },
    labBookCurrentEntry: '',
    labBookEntries: [],
    labs: LabsList,
    labBookType: 9,
    incomingQueue: [],
    processingQueue: false,
    whiteLabelLogo: '',
  }),
  getters: {
    GET_WHITE_LABEL_LOGO(state) {
      return state.whiteLabelLogo;
    },
    GET_ID_TOKEN(state) {
      return state.user.token;
    },
    GET_EMAIL(state) {
      return state.user.email;
    },
    GET_USER(state) {
      return state.user;
    },
    ACTIVE_LAB_BOOK(state) {
      return state.user.userId;
    },
    LAB_BOOK_CURRENT_ENTRY(state) {
      return state.labBookCurrentEntry;
    },
    LAB_BOOK_ENTRIES(state) {
      let workingEntries = state.labBookEntries.filter((e) => {
        return e.metaData.labType === state.labBookType || e.metaData.labType == 99;
      });
      let NonDupe = [];
      for (let x = 0; x < workingEntries.length; x++) {
        let session = workingEntries[x];

        let resolveDupes = NonDupe.find((e) => {
          return e.actor_id === session.actor_id && e.open === session.open;
        });
        if (typeof resolveDupes === 'undefined') {
          NonDupe.push(session);
        }
      }
      let sorted = NonDupe.sort((a, b) =>
        a.open > b.open ? 1 : b.open > a.open ? -1 : 0,
      );
      return sorted;
    },
    LABS_LIST(state) {
      return state.labs;
    },
    LAB_BOOK_TYPE(state) {
      return state.labBookType;
    },
  },
  actions: {
    SET_LAB_TYPE({ commit }, type) {
      commit('SET_LAB_TYPE', type);
    },
    PROCESS_MESSAGE({ commit }, message) {
      if (typeof message.action !== 'undefined') {
        switch (message.action) {
          case 'id_token':
            // eslint-disable-next-line no-case-declarations
            const idInfo = {
              token: message.id_token,
              email: message.email,
              userId: message.userId,
            };
            commit('SET_ID_INFO', idInfo);
            break;
          case 'incoming_session':
            commit('INCOMING_SESSION', message);
            break;
          case 'white_label':
            if (message.value == true) {
              commit('SET_WHITE_LABEL_LOGO', message.logo);
            }
            break;
        }
      }
    },
    SEND_MESSAGE({ commit }, message) {
      commit('SEND_MESSAGE', message);
    },
    SET_LAB_BOOK_CURRENT_ENTRY({ commit }, entry) {
      commit('SET_LAB_BOOK_CURRENT_ENTRY', entry);
    },
  },
  mutations: {
    SET_WHITE_LABEL_LOGO(state, logo) {
      state.whiteLabelLogo = logo;
    },
    INCOMING_SESSION(state, message) {
      state.incomingQueue.push(message.session);
      this.commit('PROCESS_QUEUE');
    },
    PROCESS_QUEUE(state) {
      if (!state.processingQueue) {
        if (state.incomingQueue.length > 0) {
          state.processingQueue = true;
          this.commit('PROCESS_QUEUE');
        }
      } else {
        const workingSession = state.incomingQueue.shift();
        if (state.incomingQueue.length === 0) {
          state.processingQueue = false;
        }
        let workingEntries = state.labBookEntries.filter((e) => {
          //remove the current incoming
          return e.open !== workingSession.open;
        });

        workingEntries.push(workingSession);

        state.labBookEntries = [];

        state.labBookEntries = workingEntries;

        this.commit('PROCESS_QUEUE');
      }
    },
    SEND_MESSAGE(state, message) {
      message.labType = state.labBookType;
      parent.postMessage(message, '*');
    },
    SET_LAB_TYPE(state, type) {
      state.labBookType = type;
      this.commit('SEND_MESSAGE', { action: 'lab-online' });
    },
    SET_ID_INFO(state, idInfo) {
      state.user.token = idInfo.token;
      state.user.email = idInfo.email;
      state.user.userId = idInfo.userId;
    },
    SET_LAB_BOOK_CURRENT_ENTRY(state, entry) {
      state.labBookCurrentEntry = entry;
    },
  },
};

export default BridgeModule;
