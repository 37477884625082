<template>
  <section aria-label="Images Popup" class="zoomWrapper">
    <div class="column w-full leftColumn">
      <!-- Main view here -->
      <div id="zoomControls" class="row align-center">
        <button @click="saveToLabBook" class="ml-0 inline-flex items-center btn btn-text focus:outline-none ripple">
          <span>
            <img src="../assets/live_data_icons/SaveToLabBook-24px.svg" alt="" class="btn-icon btn-icon-text" />
          </span>
          {{ saveText }}
        </button>
        <button @click="zoomIn" class="inline-flex items-center btn focus:outline-none ripple" v-show="!isVideo">
          <span>
            <img src="../assets/live_data_icons/zoom_in-24px.svg" alt="" class="btn-icon" />
          </span>
        </button>
        <button @click="zoomOut" class="inline-flex items-center btn focus:outline-none ripple" v-show="!isVideo">
          <span>
            <img src="../assets/live_data_icons/zoom_out-24px.svg" alt="" class="btn-icon" />
          </span>
        </button>
        <button @click="toggleLabels" class="inline-flex items-center btn btn-text focus:outline-none"
          :class="[{ 'btn-blue': displayLabels }]" v-show="!isVideo">
          <span>
            <img :src="displayLabels ? LabelIconWhite : LabelIconBlue" alt="" class="btn-icon btn-icon-text" />
          </span>
          {{ labelsText }}
        </button>
        <div class="row align-center" v-show="!isVideo">
          <label for="contrastSlider">
            <b>{{ contrastText }}</b>
          </label>
          <input type="range" min="0" max="200" class="slider" id="contrastSlider" list="steplist"
            v-model="contrastInput" />
        </div>
      </div>
      <div v-if="currentMedia" class="row mediaName">
        <b>{{ currentMedia.title }}</b>
      </div>
      <div id="imageWrapper">
        <canvas v-if="currentMedia && currentMedia.type == 'static'" ref="mainImage" id="mainImage" class="panzoom" />
        <video v-else-if="currentMedia" data-setup="{}" type="video/mp4" id="mainVideo" ref="video" controls
          crossorigin="anonymous">
          <source :src="S3URL + PATH + currentMedia.i + MP4 + '#t=0.01'" type="video/mp4" />
        </video>
      </div>
      <span class="scaleText">
        {{ currentMedia && currentMedia.magnification }}
        <span class="percent">&nbsp;&nbsp;({{ (scale * 100).toFixed(0) }} %)</span>
      </span>
    </div>
    <div id="imagesList" class="column">
      <!-- Individual images here -->
      <img v-for="image in images" :alt="image.title"
        :class="['imageThumbnail', { 'selectedThumbnail': currentMedia && image.id == currentMedia.id }]"
        @click="selectImage(image)" :src="S3URL + PATH + image.i + THUMB + (image.t == 'static' ? JPG : F4V)"
        tabindex="0" @keyup.enter="selectImage(image)" />
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex'
import Constants from '../enums/Constants';
import MicroscopyMiddlewareServices from '../services/MicroscopyMiddlewareServices';
import PanZoomMixin from '../mixins/PanZoomMixin'
import { GET_CURRENT_MEDIA, GET_CURRENT_SPECIES, GET_SPECIAL_MEDIA_LIST } from '../store/store-types';
import LabelIconWhite from '../assets/live_data_icons/show_labels_White_24dp.svg';
import LabelIconBlue from '../assets/live_data_icons/show_labels_Blue_24dp.svg';
import html2canvas from 'html2canvas';
import * as LabBookMiddleware from './LabBook/scripts/LabBookMiddleware';
import ImageMixin from "../mixins/ImageMixin.js";
import { entry } from '../services/xapi';
import xapiEntryTypes from '../enums/xapiEntryTypes';
import BiologyLabEnum from '../enums/BiologyLabEnum';
import NotificationMixin from '../mixins/NotificationMixin.js';
export default {
  name: 'SpeciesZoom',
  mixins: [PanZoomMixin, ImageMixin, NotificationMixin],
  data: function () {
    return {
      // selectedImage: null,
      S3URL: Constants.S3_BUCKET_URL,
      JPG: Constants.IMG_EXT_JPG,
      THUMB: Constants.THUMBNAIL,
      F4V: Constants.IMG_EXT_F4V,
      MP4: Constants.IMG_EXT_MP4,
      PATH: Constants.MEDIA_CONTENT,
      contrastInput: 100,
      cameraOffset: { x: null, y: null },
      cameraZoom: 1,
      MAX_ZOOM: 5,
      MIN_ZOOM: 1,
      zoomCount: 1,
      isDragging: false,
      dragStart: { x: 0, y: 0 },
      zoomElement: null,
      displayLabels: true,
      LabelIconWhite,
      LabelIconBlue,
      increment: 0.05,
      currentImg: null,
      initialLoad: false,

      saveText: this.getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.SAVE),
      labelsText: this.getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.LABELS),
      contrastText: this.getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.CONTRAST),
    }
  },
  props: [],
  created() { },
  mounted() {
    if (this.currentMedia) {
      this.getImage();
    }
  },
  methods: {
    selectImage: function (media) {
      this.currentImg = null;
      MicroscopyMiddlewareServices.get_media_details(media).then(() => {
        const extensions = {
          name: Constants.ZOOM,
          state: media.title,
          inZoomPopup: true,
          scope: media.scope,
        };
        entry(xapiEntryTypes.STATE, {
          extensions: extensions,
        });
        this.getMediaImageAndSetCurrentMedia(media).then(() => {
          if (media.type == 'static') {
            this.$nextTick(() => {
              this.getImage();
            });
          } else {
            this.$nextTick(() => {
              this.$refs.video.load();
              this.$refs.video.play();
            });
          }
          this.dragStart = { x: 0, y: 0 };
          this.cameraOffset = { x: null, y: null };
          this.zoomCount = 1;
        });
      });
    },
    updateCanvas: function () {
      if (this.currentMedia.type != 'static') {
        return;
      }
      let canvas = this.$refs.mainImage;
      let ctx = canvas.getContext("2d");
      let parentWidth = canvas.parentElement.clientWidth;
      let parentHeight = canvas.parentElement.clientHeight;
      let imageWidth = this.currentImg.width;
      let imageHeight = this.currentImg.height;
      let ratio = Math.min(parentWidth / imageWidth, parentHeight / imageHeight);
      canvas.width = imageWidth * ratio;
      canvas.height = imageHeight * ratio;
      ctx.filter = `contrast(${this.contrastInput}%)`;
      ctx.drawImage(this.currentImg, 0, 0, canvas.width, canvas.height);
      if (!this.zoomElement) {
        this.zoomElement = this.PanZoom(".panzoom", {
          minScale: .75,
          maxScale: 10,
          increment: this.increment,
        });
      }
      if (this.displayLabels) {
        let labels = [];
        for (let arrow of this.currentMedia.arrows) {
          this.arrow(ctx, arrow.startPos, arrow.endPos, 8);
          if (arrow.label) {
            labels.push({ label: arrow.label, labelPos: arrow.labelPos });
          }
        }
        for (let label of labels) {
          this.drawLabel(ctx, label.label, label.labelPos, canvas);
        }
      }
    },
    getImage: function () {
      if (this.currentMedia.type != 'static') {
        return;
      }
      let canvas = this.$refs.mainImage;
      let ctx = canvas.getContext("2d");
      if (!this.cameraOffset.x) {
        this.cameraOffset.x = canvas.width;
        this.cameraOffset.y = canvas.height;
      }
      this.currentImg = this.currentMedia.img;
      this.updateCanvas();
      this.initialLoad = true;
    },
    getValue: function (value, size) {
      return value / 100 * size;
    },
    value: function (value) {
      return value * .84;
    },
    drawLabel: function (ctx, label, pos, canvas) {
      let offsetY = 15;
      let offsetX = -1;
      ctx.font = `bold 13px Open Sans`;
      ctx.fillStyle = "#FDBF72";
      ctx.shadowColor = "black";
      ctx.shadowBlur = 7;
      ctx.lineWidth = 5;
      ctx.strokeText(label, this.value(pos.x) + offsetX, this.value(pos.y) + offsetY);
      ctx.shadowBlur = 0;
      ctx.fillText(label, this.value(pos.x) + offsetX, this.value(pos.y) + offsetY);
    },
    arrow: function (ctx, p1, p2, size) {
      let p1x = this.value(p1.x);
      let p1y = this.value(p1.y);
      let p2x = this.value(p2.x);
      let p2y = this.value(p2.y);
      var angle = Math.atan2((p2y - p1y), (p2x - p1x));
      var hyp = Math.sqrt((p2x - p1x) * (p2x - p1x) + (p2y - p1y) * (p2y - p1y));

      ctx.save();
      ctx.translate(p1x, p1y);
      ctx.rotate(angle);

      // line
      ctx.strokeStyle = "#FDBF72";
      ctx.shadowColor = "black";
      ctx.shadowBlur = 7;
      ctx.lineWidth = 2;
      ctx.beginPath();
      ctx.moveTo(0, 0);
      ctx.lineTo(hyp - size, 0);
      ctx.stroke();

      // triangle
      ctx.fillStyle = '#FDBF72';
      ctx.beginPath();
      ctx.lineTo(hyp - size, size);
      ctx.lineTo(hyp, 0);
      ctx.lineTo(hyp - size, -size);
      ctx.fill();

      ctx.restore();
    },
    contrastImage: function (imageData, contrast) {
      var d = imageData.data;
      contrast = (contrast / 100) + 1;  //convert to decimal & shift range: [0..2]
      var intercept = 128 * (1 - contrast);
      for (var i = 0; i < d.length; i += 4) {   //r,g,b,a
        d[i] = d[i] * contrast + intercept;
        d[i + 1] = d[i + 1] * contrast + intercept;
        d[i + 2] = d[i + 2] * contrast + intercept;
      }
      return imageData;
    },
    toggleLabels: function () {
      this.displayLabels = !this.displayLabels;
      this.getImage();
    },
    saveToLabBook: function () {
      if (this.currentMedia.type == 'static') {
        html2canvas(document.getElementById('imageWrapper'), {
        }).then((generatedCanvas) => {
          let image = generatedCanvas.toDataURL('image/png');
          try {
            LabBookMiddleware.lab_book('entry', {
              entry: image,
              type: 3,
            });
            this.showNotification('Image saved.');
          } catch (e) {
            console.error(e);
          }
        });
      } else {
        let canvas = document.createElement('CANVAS');
        let saveImage = () => {
          let imageToSave = canvas.toDataURL('image/png');
          try {
            LabBookMiddleware.lab_book('entry', {
              entry: imageToSave,
              type: 3,
            });
            this.showNotification('Image saved.');
          } catch (e) {
            console.error('Entry Save Fail', data);
          }
        };
        canvas.width = this.$refs.video.clientWidth;
        canvas.height = this.$refs.video.clientHeight;
        let ctx = canvas.getContext('2d');
        let video = this.$refs.video;
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        saveImage();
      }
    },
    zoomIn() {
      let x = this.$refs.mainImage.width / 2;
      let y = this.$refs.mainImage.height / 2;
      this.zoomElement.applyScale(this.increment, x, y);
      const extensions = {
        name: Constants.MAGNIFY,
        state: Constants.MAGNIFY_IN,
      };
      entry(xapiEntryTypes.STATE, {
        extensions: extensions,
      });
    },
    zoomOut() {
      let x = this.$refs.mainImage.width / 2;
      let y = this.$refs.mainImage.height / 2;
      this.zoomElement.applyScale(-this.increment, x, y);
      const extensions = {
        name: Constants.MAGNIFY,
        state: Constants.MAGNIFY_OUT,
      };
      entry(xapiEntryTypes.STATE, {
        extensions: extensions,
      });
    },
    adjustZoom(zoomAmount, zoomFactor) {
      if (!this.isDragging) {
        if (zoomAmount) {
          this.cameraZoom += zoomAmount;
        }
        else if (zoomFactor) {
          this.cameraZoom = zoomFactor * this.lastZoom;
        }
        this.cameraZoom = Math.min(this.cameraZoom, this.MAX_ZOOM);
        this.cameraZoom = Math.max(this.cameraZoom, this.MIN_ZOOM);
      }
    },
  },
  components: {},
  computed: {
    ...mapGetters({
      species: GET_CURRENT_SPECIES,
      currentMedia: GET_CURRENT_MEDIA,
      specialMedia: GET_SPECIAL_MEDIA_LIST,
    }),
    images: function () {
      if (this.specialMedia.length) {
        return this.specialMedia;
      }
      if (!this.species || !this.currentMedia) {
        return [];
      }
      return this.species.media[this.currentMedia.s];
    },
    scopeTypeText: function () {
      let types = {
        field: 'Field Scope',
        compound: 'Compound Microscope',
        dissection: 'Dissecting Microscope',
        sem: 'SEM',
        tem: 'TEM',
      };
      return types[this.currentMedia.s];
    },
    isVideo: function () {
      return this.currentMedia && this.currentMedia.type == 'static' ? false : true;
    },
  },
  watch: {
    contrastInput: function (contrast) {
      const extensions = {
        name: Constants.CONTRAST,
        state: contrast,
      };
      entry(xapiEntryTypes.STATE, {
        extensions: extensions,
      });
      this.getImage();
    },
    currentMedia: function (value) {
      if (value && value.type != 'static') {
        this.$refs.video.load();
      } else if (value && !this.initialLoad) {
        this.$nextTick(() => {
          this.getImage();
        });
      }
    },
  },
}
</script>
<style scoped>
.row {
  display: flex;
}

.full {
  width: 100%;
  height: 100%;
}

.w-full {
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
}

.align-center {
  align-items: center;
}

#zoomControls {
  margin-top: 12px;
  margin-bottom: 12px;
}

.zoomWrapper {
  width: 100%;
  height: 100%;
  display: flex;
}

#mainVideo {
  min-width: 200px;
  min-height: 200px;
  width: inherit;
}

.percent {
  font-size: 10px;
}

.scopeType {
  font-weight: bolder;
}

#imagesList {
  margin-left: 24px;
  overflow: auto;
  margin-top: 24px;
  min-width: 77px;
  margin-right: 42px;
  height: calc(100% - 50px);
}

.imageThumbnail {
  width: 50px;
  border: 1px solid;
  margin: 2px 0;
  cursor: pointer;
}

#mainImage {
  /* width: 750px;
  max-height: 562px; */
  /* width: 100%;
  height: 100%; */
}

#imageWrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* border: 1px solid #17469e; */
  align-self: center;
}

.mediaName {
  margin-bottom: 12px;
}

.btn {
  border: 1px solid #17469e;
  border-radius: 4px;
  font-size: 12px;
  background: #fff;
  color: #17469e;
  padding: 0 8px;
  margin: 6px;
  height: 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
}

.scaleText {
  display: flex;
  align-items: center;
}

.mr-0 {
  margin-right: 0px;
}

.ml-0 {
  margin-left: 0px;
}

.ml-auto {
  margin-left: auto;
}

.btn-blue {
  background: #01319c;
  color: white;
}

/* .btn-text {
  width: 72px;
} */

.btn-icon {
  height: 18px;
  color: #17469e;
  display: table-cell;
  vertical-align: middle;
}

.btn-icon-text {
  padding-right: 6px;
}

.btn-wrapper {
  display: inline-grid;
  margin: 6px;
}

.ripple {
  background-position: center;
  transition: background 0.8s;
}

.ripple:hover {
  background: #fff radial-gradient(circle, transparent 1%, #fff 1%) center/15000%;
}

.ripple:active {
  background-color: #17469e 7c;
  background-size: 100%;
  transition: background 0s;
}

/* The slider itself */
.slider {
  -webkit-appearance: none;
  /* Override default CSS styles */
  appearance: none;
  width: 100%;
  /* Full-width */
  height: 5px;
  /* Specified height */
  border-radius: 5px;
  /* background: #ADB8D4; Grey background */

  background: url("../assets/live_data_icons/SliderBar_106px.svg");
  /* Remove outline */
  /* opacity: 0.7; Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s;
  /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
  margin-left: 12px;
  margin-right: 24px;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1;
  /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  width: 15px;
  /* Set a specific slider handle width */
  height: 15px;
  /* Slider handle height */
  background: #003298;
  /*Green background */
  cursor: pointer;
  /* Cursor on hover */
  border-radius: 15px;
}

.slider::-moz-range-thumb {
  width: 15px;
  /* Set a specific slider handle width */
  height: 15px;
  /* Slider handle height */
  background: #003298;
  /* Green background */
  cursor: pointer;
  /* Cursor on hover */
  border-radius: 15px;
}

.selectedThumbnail {
  border: 2px solid yellow;
}

.leftColumn {
  flex-basis: auto;
  max-width: 80%;
}
</style>
