<template>
  <section class="lab-book-session" :id="'session-' + session.open">
    <div class="lab-book-session-header">
      <div class="columns is-gapless">
        <div class="column lab-book-session-title">
          <span class="lab-book-session-title-inner">
            <b v-if="false">{{ labName }}:</b>&nbsp;{{ formatDate
            }}<small class="session-duration" v-if="labName != 'Portal'"
              >,&nbsp;Time in lab {{ duration }}</small
            >
          </span>
        </div>
      </div>
    </div>
    <div class="portalHeader" v-if="session.metaData.labType == 99">Portal Entry:</div>
    <div class="lab-book-session-entries">
      <div v-for="(entry, index) in session.entries" :key="index">
        <LabBookEntry :entry="entry" />
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex';
import LabBookEntry from './LabBookEntry.vue';

export default {
  name: 'LabBookSession',
  data: () => ({
    showLabCam: false,
    addingNote: false,
  }),
  props: ['session'],
  emits: [],
  created() {
    // this.session.entries.forEach((e) => {
    //   e.metaData.content = this.htmlContent(e);
    // });
  },
  mounted() {},
  methods: {
    htmlContent(entry) {
      let dt = new Date(entry.created);
      let vs = dt.toLocaleString();
      let string;
      try {
        string = entry.metaData.content.replace('{lab_book_timestamp}', vs);
      } catch (err) {
        console.error('ERROR REPLACING TIMESTAMP', entry);
        string = entry.metaData.content;
      }

      return string;
    },
  },
  components: {
    LabBookEntry,
  },
  computed: {
    ...mapGetters({
      LABS_LIST: 'LABS_LIST',
      ACTIVE_LAB_BOOK: 'ACTIVE_LAB_BOOK',
      USER: 'GET_USER',
    }),
    labName() {
      const labDetail = this.LABS_LIST.find((e) => {
        return e.labType === this.session.metaData.labType;
      });
      if (!labDetail) {
        if (typeof this.session !== 'undefined') {
          console.warn('no detail:', this.session, this.session.metaData);
        } else {
          console.warn('no detail:', this.session);
        }
        if (
          typeof this.session.actor_id !== 'undefined' &&
          typeof this.session.open !== 'undefined'
        ) {
          return `UNKNOWN LAB NAME FOR ${this.session.actor_id} ${this.session.open}`;
        } else {
          return `UNKNOWN LAB NAME`;
        }
      } else {
        return labDetail.name;
      }
    },
    viewingMyLabbook() {
      return this.USER.userId === this.ACTIVE_LAB_BOOK;
    },
    duration() {
      const parseMillisecondsIntoReadableTime = (milliseconds) => {
        //Get hours from milliseconds
        var hours = milliseconds / (1000 * 60 * 60);
        var absoluteHours = Math.floor(hours);
        var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

        //Get remainder from hours and convert to minutes
        var minutes = (hours - absoluteHours) * 60;
        var absoluteMinutes = Math.floor(minutes);
        var m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;

        //Get remainder from minutes and convert to seconds
        var seconds = (minutes - absoluteMinutes) * 60;
        var absoluteSeconds = Math.floor(seconds);
        var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;
        return `${h}:${m}:${s}`;
      };

      if (parseInt(this.session.duration) <= 0) {
        return `--:--:--`;
      } else {
        return parseMillisecondsIntoReadableTime(this.session.duration);
      }
    },
    labImage() {
      return '';
    },
    formatDate() {
      let dt = new Date(this.session.open);
      return dt.toLocaleString();
    },
  },
  watch: {},
};
</script>
<style scoped>
.lab-book-session {
  background-color: #fff;
  border: 1px solid #f6f6f6;

  /* box-shadow: 0 3px 3px #00000029; */
  width: 100%;

  /* margin-bottom: 10px; */
}

.lab-book-session-entries {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 10px;
  font-size: 0.75rem;
  margin-bottom: 15px;
}

.lab-book-session-header {
  background-color: #17469e14;
  color: #000;
  border-bottom: 1px solid #f6f6f6;
  width: 100%;
  position: relative;
  border-right: #fff 5px solid;
  border-left: #fff 5px solid;
}

.lab-book-session-title {
  padding-left: 20px;
}

.lab-book-session-title-inner {
  margin-left: 10px;
}
@media only screen and (max-width: 1500px) {
  .lab-book-session-title-inner {
    font-size: 13px;
  }
}

.lab-book-session-action {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin: auto;
}

.lab-book-session-action-wrapper {
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.instructorNoteMessage {
  font-style: italic;
  padding: 4px;
  border: 1px solid #afafaf;
}

.portalHeader {
  margin-left: 15px;
  margin-top: 3px;
  font-size: 14px;
}
.active {
  background: #ccc;
  border-radius: 5px;
}
</style>
