<template>
  <div class="itunes">
    <div class="splash_wrapper">
      <div style="width: 100%; height: 100%; position: relative;">
        <div class="loading"></div>
      </div>
      <img src="../assets/splash/splash.jpg" alt="Loading" class="box-shadow" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SplashScreen',
  data: () => ({}),
  props: [],
  created() {},
  mounted() {},
  methods: {},
  components: {},
  computed: {
    ...mapGetters({}),
  },
  watch: {},
}
</script>
<style scoped></style>

<style scoped src="../styles/SplashScreen.scss" lang="scss"></style>
