<template>
  <div class="search">
    <input class="searchInput" @focus="focused=true" @blur="focused=false" :placeholder="placeholder" v-model="text" :aria-label="getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.SEARCH)" :title="getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.SEARCH)" />
    <img class="searchIcon" :src="icon"  alt="" @click="clear"/>
  </div>
</template>
<script>
import searchIcon from '../../assets/general_lab_icons/Search.svg';
import cancelIcon from '../../assets/general_lab_icons/Close.svg';
import BiologyLabEnum from '../../enums/BiologyLabEnum';
import Constants from '../../enums/Constants';
export default {
  name: "Search",
  data() {
    return {
      BiologyLabEnum,
      Constants,
      cancelIcon,
      searchIcon,
      text: '',
      focused: false,
    };
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
  },
  watch: {
    text: function() {
      this.$emit("textChanged", this.text)
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    updateText(text) {
      this.text = text;
    },
    clear: function() {
      if (!this.focused && this.text.length) {
        this.text = '';
      }
    },
  },
  computed: {
    icon: function() {
      if (!this.focused && this.text.length) {
        return this.cancelIcon;
      }
      return this.searchIcon;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../styles/colors.scss';
.search {
  width: 266px;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid $color-primary-blue;
  border-radius: 16px;
  display: flex;
}
.searchInput {
  margin-left: 24px;
	height: 100%;
	width: 100%;
  padding: 0;
	border: 0px;
  font-size: 14px;
	// outline: transparent;
  text-overflow: ellipsis;
}
.searchIcon {
  margin-right: 24px;
  cursor: pointer;
}
</style>