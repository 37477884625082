<template>
  <button
    :class="['Button', { disabled: disabled, hidden: hidden }, buttonClass]"
    :style="buttonDims"
    @click="$emit('on-click')"
    outlined
    small
  >
    <span v-if="hasIcon" class="icon">
      <slot name="icon"></slot>
    </span>
    <span v-if="hasText" :class="['text', buttonTextClass]">
      <slot name="text"></slot>
    </span>
  </button>
</template>

<script>
export default {
  name: 'Button',
  data() {
    return {
    };
  },
  computed: {
    buttonClass: function() {
      if (this.variant == 'link') { //white
        return 'linkButton';
      } else if (this.variant == 'primary') { //blue
        return 'primaryButton';
      }else if (this.variant == 'secondary') { //white w/ border
        return 'secondaryButton';
      }
      return '';
    },
    buttonDims: function() {
      return `height: ${this.height}; width: ${this.width}`;
    },
    buttonTextClass: function() {
      if (this.variant == 'secondary' || this.variant == 'link') {
        return 'secondaryButtonText';
      } else if (this.variant == 'primary') {
        return 'primaryButtonText';
      }
      return '';
    },
  },
  methods: {
  },
  props: {
    /**
     * Options: link (white), secondary (white w/ border), primary (blue)
     */
    variant: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasText: {
      type: Boolean,
      default: true,
    },
    hasIcon: {
      type: Boolean,
      default: false,
    },
    width: {
      required: false,
      default: 'fit-content',//'150px',
      type: String,
    },
    height: {
      required: false,
      default: '36px',
      type: String,
    },
    hidden: {
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
:root {
  --main-bg-color: #f6f6f6;
  --blue: #17469e;
  --disabled: 0.38;
  --sidebar-size: 72px;
  --header-width: calc(100vw - var(--sidebar-size));
}
.Button {
  cursor: pointer;
  border: 1px solid;
  font-family: 'Open Sans', sans-serif;
  border-radius: 4px;
  // padding: 3px 8px;
  vertical-align: middle;
  display: flex;
  // font-size: 14px;
  // font-weight: bolder;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  // min-width: 65px;
  padding: 0 7px;
  // min-height: 36px;
}

.disabled {
  cursor: default;
  pointer-events: none;
}
.primaryButton:hover {
  background-color: #335caa;
}
.secondaryButton:hover, .linkButton:hover {
  background-color: #e3e9f3;
}

.secondaryButton {
  background-color: white;
  border-color: #17469e;
  border-width: 1px;
}

.secondaryButtonText {
  color: #17469e;
}

.primaryButton {
  background-color: #17469e;
  border-color: transparent;
}

.primaryButtonText {
  color: white;
}

.linkButton {
  background-color: white;
  border: transparent;
}

.text {
  // margin-left: 5px;
  // font-size: 14px;
  text-transform: none;
}

.icon {
  margin-right: 5px;
}
.hidden {
  color: transparent;
  pointer-events: none;
  cursor: default;
  background: transparent;
  user-select: none;
}
.hidden .text {
  color: transparent;
}
</style>
