import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import LocalizationMixin from "./mixins/LocalizationMixin.js";
import Toaster from "@meforma/vue-toaster";

const app = createApp(App);
app.use(router);
app.use(store);
app.use(Toaster, {
  position: "top-right",
  useDefaultCss: false,
});
app.mixin(LocalizationMixin);
app.mount('#app');