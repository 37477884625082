import store from '../store/index';
import * as StoreTypes from '../store/store-types';
import { dictionary } from '../locales/localization';
import ErrorAndWarningConstants from '../enums/ErrorAndWarningConstants';

console.log('dictionary:: ', dictionary);

export default {
  methods: {
    getLocaleData(module, key, vars) {
      const lang = store.getters[StoreTypes.GET_CURRENT_LANGUAGE];
      if (!dictionary[lang]) {
        console.error(ErrorAndWarningConstants.NO_DATA_EXISTS_FOR_CUR_LANG);
      } else if (!dictionary[lang][module]) {
        console.error(ErrorAndWarningConstants.MODULE_DOES_NOT_EXISTS);
      } else if (
        !dictionary[lang][module][key] &&
        dictionary[lang][module][key] !== ''
      ) {
        console.error(ErrorAndWarningConstants.INVALID_KEY, key);
      } else {
        let data = dictionary[lang][module][key];
        for (let v in vars) {
          data = data.replace('%' + v + '%', vars[v]);
        }
        return data;
      }
    },
    setLocaleData(module, data) {
      const lang = store.getters[StoreTypes.GET_CURRENT_LANGUAGE];
      if (!dictionary[lang]) {
        dictionary[lang] = {};
      }
      if (dictionary[lang][module]) {
        console.error(ErrorAndWarningConstants.MODULE_ALREADY_EXISTS);
      } else {
        dictionary[lang] = {
          ...dictionary[lang],
          [module]: data,
        };
      }
    },
  },
};
