<template>
	<div class="labelWrapper" v-if="label != ''">
		<div class="dropdownLabel" v-html="label" />
		<span v-if="required" class="required">*</span>
	</div>
	<div :class="['dropdown', { 'dotsDropdown': variant == 'dots', disabled: disabled }]" :style="labelMargin">
		<div @click.stop="toggleOpen" :class="[variant == 'dots' ? 'ellipsisWrapper' : '']" tabindex="0" @keyup.enter="toggleOpen"
			aria-haspopup="true">
			<img alt="" ref="toggle" class="toggle ellipsis" v-if="variant == 'dots'"
				src="../../assets/general_lab_icons/Ellipsis.svg" />
			<div ref="toggle" v-else class="toggle selector" :style="borderColor">
				<p v-if="prompt && currIndex == -1" class="dropdownPrompt" :style="promptColor">{{ prompt }}</p>
				<p v-else class="selectedText inline max-size truncate">{{ selectedItemText }}</p>
				<span class="num" v-if="selectedItem && selectedItem.number != undefined"> ({{ selectedItem.number }})</span>
				<img alt="" class="caret" :src="caret" />
			</div>
		</div>
		<div v-if="open">
			<div v-if="open" @click.stop="toggleOpen" class="window" tabindex="0" @keyup.enter="toggleOpen"
				aria-haspopup="true" />
			<input class="dropdownInput" ref="dropdownInput" @keyup="inputKeyUp($event)" autocomplete="off">
			<div v-if="open" :class="['list mt-3', positionClass, { 'smaller': smaller }]" :aria-expanded="open" role="menu"
				:style="[widthStyle, dropdownPosition]" ref="dropdown">
				<div v-for="(item, index) in list" :key="itemText[index]" @click.stop="selected(index, item)" tabindex="0"
					@keyup.enter="selected(index, item)" role="menuitem" :aria-label="itemText[index]" :aria-selected="index == currIndex" :aria-posinset="index + 1" :aria-setsize="list.length" 
					:class="['px-4 py-2 item', { 'pr-16': item.icon, 'browsedItem': index == browsingIndex, 'selectedItem': index == currIndex, 'borderItem': hasBorder(item, index) }]">
					<img alt="" v-if="item.icon" class='icon' :src="item.icon" />
					<span :class="['text', { 'blueText': blue }]">{{ itemText[index] }}</span>
					<span v-if="item.number != undefined" class="text num"> ({{ item.number }})</span>
				</div>
			</div>
		</div>
		<div class="errorWrapper">
			<span class="errorMessage">
				{{ errorMessage }}
			</span>
		</div>
	</div>
</template>

<script>
import CaretDown from '../../assets/general_lab_icons/CaretDown.svg';
import CaretUp from '../../assets/general_lab_icons/CaretUp.svg';
export default {
	name: 'DropdownUtility',
	emits: ['selected', 'dropdownToggled'],
	props: {
		variant: {
			type: String,
			default: 'dots'
		},
		/**
		 * bottom, top, offsetLeft, offsetRight, bottom-left
		 */
		position: {
			type: String,
			default: 'offsetLeft',
		},
		/**
		 * Attributes that it can have:
		 * text (or other which is specified by `textAttribute`)
		 * icon, number, group
		 */
		list: {
			type: Array,
			required: true,
		},
		/**
		 * Whether the text is blue
		 */
		blue: {
			type: Boolean,
			default: false,
		},
		selectedInd: {
			type: Number,
			required: false,
			default: -1,
		},
		textAttribute: {
			type: String,
			default: 'text',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		width: {
			type: [String, Number],
			required: false,
		},
		/**
		 * Example: "State"
		 */
		label: {
			type: String,
			default: '',
		},
		/**
		 * Exmaple: "Select State"
		 * Set starting index to -1 to show
		 */
		prompt: {
			type: String,
			default: null,
		},
		/**
		 * Whether to freeze background scrolling when dropdown is open
		 */
		freezeScroll: {
			type: Boolean,
			default: false
		},
		/**
		 * Whether to have max height be smaller
		 */
		smaller: {
			type: Boolean,
			default: false,
		},
		errorMessage: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
		browsable: {
			type: Boolean,
			default: true,
		},
		searchable: {
			type: Boolean,
			default: true,
		},
	},
	data: function () {
		return {
			open: false,
			caretDown: CaretDown,
			caretUp: CaretUp,
			currIndex: this.selectedInd,
			dropdownTop: 0,
			dropdownLeft: 0,
			dropdownHeight: 0,
			dropdownWidth: 0,
			toggleWidth: 0,
			toggleHeight: 0,
			inputTimeout: null,
			browsingIndex: 0,
		}
	},
	computed: {
		borderColor: function () {
			if (this.errorMessage != '') {
				return 'border: 2px solid #B90000;';
			}
			return '';
		},
		promptColor: function () {
			if (this.errorMessage != '') {
				return 'color: #B90000';
			}
			return '';
		},
		labelMargin: function () {
			if (this.label != '') {
				return 'margin-top: 18px';
			}
			return '';
		},
		caret: function () {
			if (this.open) {
				return this.caretUp;
			}
			return this.caretDown;
		},
		selectedItem: function () {
			if (!this.list) {
				return {};
			}
			return this.list[this.currIndex];
		},
		selectedItemText: function () {
			if (!this.selectedItem) {
				return '';
			}
			return this.selectedItem[this.textAttribute];
		},
		positionClass: function () {
			return this.position;
		},
		itemText: function () {
			return this.list.map((item) => {
				return item[this.textAttribute];
			});
		},
		widthStyle: function () {
			if (this.width) {
				return 'width: ' + (this.width.includes('px') ? this.width : this.width + 'px');
			}
			else if (this.position == 'bottom') {
				return 'width: 240px;'
			}
			else if (this.position == 'top') {
				return 'width: 179px;'
			}
			return '';
		},
		dropdownPosition: function () {
			let top;
			let left;
			switch (this.position) {
				case 'bottom':
					top = this.dropdownTop + this.toggleHeight - 10;
					left = this.dropdownLeft;
					break;
				case 'top':
					left = this.dropdownLeft;
					break;
				case 'offsetLeft':
					top = this.dropdownTop + this.toggleHeight - 10;
					left = this.dropdownLeft;
					break;
				case 'offsetRight':
					top = this.dropdownTop + this.toggleHeight - 10;
					left = this.dropdownLeft - this.dropdownWidth + this.toggleWidth;
					break;
			}
			if (top + this.dropdownHeight + 5 > window.innerHeight) {
				top -= this.dropdownHeight;
			}
			if (left + this.dropdownWidth > window.innerWidth) {
				left -= this.dropdownWidth;
			}
			return `top: ${top}px; left: ${left}px;`;
		},
	},
	methods: {
		inputKeyUp: function (event, b, c) {
			if (this.browsable && (event.key == 'ArrowUp' || event.key == 'ArrowDown' || event.key == 'Enter')) {
				if (event.key == 'ArrowUp' && this.browsingIndex != 0) {
					this.browsingIndex--;
				} else if (event.key == 'ArrowDown' && this.browsingIndex != this.list.length - 1) {
					this.browsingIndex++;
				} else if (event.key == 'Enter') {
					this.selected(this.browsingIndex, this.list[this.browsingIndex]);
				}
				this.$nextTick(() => {
					let element = document.getElementsByClassName('browsedItem')[0];
					if (element) {
						element.scrollIntoView(false);
					}
				});
				return;
			}
			if (!this.searchable) {
				return;
			}
			clearTimeout(this.inputTimeout);
			this.inputTimeout = setTimeout(this.clearInput, 3000);
			let index = this.list.findIndex((item) => item[this.textAttribute].toLowerCase().startsWith(this.$refs.dropdownInput.value.toLowerCase()));
			if (index < 0) { index = 0; }
			this.browsingIndex = index;
			this.$nextTick(() => {
				let element = document.getElementsByClassName('browsedItem')[0];
				if (element) {
					element.scrollIntoView(false);
				}
			});
		},
		clearInput: function () {
			if (this.$refs.dropdownInput) {
				this.$refs.dropdownInput.value = '';
			}
		},
		selected: function (index, item) {
			this.$emit("selected", index, item);
			this.currIndex = index;
			this.toggleOpen();
		},
		toggleOpen: function () {
			let rect = this.$refs.toggle.getBoundingClientRect();
			this.open = !this.open;
			this.$emit('dropdownToggled', this.open);
			var documentBody = document.getElementsByTagName('body')[0];
			if (this.open) {
				if (documentBody.style.overflow != 'hidden' && this.freezeScroll) {
					documentBody.style.overflow = 'hidden';
				}
				this.browsingIndex = this.currIndex;
				this.$nextTick(() => {
					this.$refs.dropdownInput.focus();
					let dropdown = this.$refs.dropdown;
					let toggle = this.$refs.toggle;
					this.dropdownTop = rect.top;
					this.dropdownHeight = dropdown.clientHeight;
					this.dropdownWidth = dropdown.offsetWidth;
					this.dropdownLeft = rect.left;
					this.toggleHeight = toggle.clientHeight;
					this.toggleWidth = toggle.offsetWidth;
				});
			}
			else if (documentBody.style.overflow == 'hidden') {
				documentBody.style.overflow = "";
			}
		},
		resetIndex: function (index = 0) {
			this.currIndex = index;
		},
		isOpen: function () {
			return this.open;
		},
		hasBorder: function (item, index) {
			if (item.hasOwnProperty('group')) {
				if (this.list.length > index + 1 &&
					this.list[index + 1].hasOwnProperty('group') &&
					this.list[index + 1].group != item.group) {
					return true;
				}
			}
			return false;
		}
	},
	watch: {
		selectedInd: function () {
			this.currIndex = this.selectedInd;
		}
	}

}
</script>

<style scoped>
.labelWrapper {
	height: 0;
	display: flex;
}

.required {
	font-size: 10px;
}

.blueText {
	color: #17469E;
}

.text {
	font-size: 14px;
}

.dropdownLabel {
	font-size: 10px;
	margin-bottom: 4px;
}

.window {
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: 50;
}

.dropdownPrompt {
	font-size: 14px;
	/* color: #00000061; */
	margin-left: 16px;
}

.list {
	background: #FFFFFF;
	box-shadow: 0px 3px 6px #00000042;
	border-radius: 4px;
	opacity: 1;
	position: fixed;
	z-index: 51;
	padding-bottom: 8px;
	padding-top: 8px;
	max-height: 400px;
	overflow-y: auto;
}

.smaller {
	max-height: 250px;
}

.offset {
	right: 0;
	top: 20px;
}

.bottom {
	left: 0;
	top: 30px;
}

.top {
	right: 0;
	bottom: 45px;
	width: 179px;
	/*todo, should probably make this a prop */
}

.item {
	display: flex;
	align-items: center;
	height: 40px;
}

.item:hover {
	background: #17469E14;
}

.selectedItem {
	background-color: #17469E1F;
}

.browsedItem {
	background-color: #17469E14;
}

.borderItem {
	border-bottom: 1px solid #00000029;
}

.ellipsis {
	height: 100%;
}

.selector {
	display: flex;
	align-items: center;
	background: #FFFFFF;
	box-shadow: 0px 3px 3px #00000029;
	border-radius: 4px;
	width: fit-content;
	min-width: 150px;
	max-width: 170px;
	height: 40px;
	white-space: nowrap;
}

.selectedText {
	margin-left: 16px;
	margin-right: 4px;
	font-weight: bold;
	text-overflow: ellipsis;
	max-width: 220px;
	overflow: hidden;
	font-size: 14px;
	margin-bottom: 0 !important;
	margin-top: 0 !important;
}

.num {
	opacity: .56;
	font-size: 12px;
	padding-left: 4px;
}

.disabled {
	cursor: default;
	opacity: var(--disabled);
	pointer-events: none;
}

.dropdownInput {
	height: 0;
	position: absolute;
	top: -200vh;
	width: 0;
}

.caret {
	margin-left: auto;
	margin-right: 8px;
}

.dropdown {
	cursor: pointer;
	display: flex;
	flex-direction: column;
}

.dotsDropdown {
	align-self: center;
	width: 30px;
	display: flex;
	justify-content: center;
	border-radius: 30px;
}

.dotsDropdown:hover {
	background: #17469E14;
}

.icon {
	height: 16px;
	width: 16px;
	margin-right: 2px;
}

.errorMessage {
	color: #B90000;
	font-size: 12px;
}

.ellipsisWrapper {
	text-align: center;
	margin-bottom: -6px;
}

.px-4 {
	padding-left: 1rem;
	padding-right: 1rem;
}

.py-2 {
	padding-top: .5rem;
	padding-bottom: .5rem;
}</style>