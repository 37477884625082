<template>
  <div class="PopupWrapper" :style="popupWrapperStyle" role="dialog">
    <div id="Popup" :style="size">
      <div class="titleWrapper" :style="headerStyle">
        <span class="popupTitle">{{ title }}</span>
        <img class="closeIcon" alt="" @click="closePopup" aria-label="close" role="button" src="../../assets/general_lab_icons/Close.svg" tabindex="0" @keyup.enter="closePopup" ref="close" />
      </div>
      <div class="bodyWrapper" :style="leftPadding ? 'padding-left: 24px;' : ''">
        <slot />
      </div>
      <div class="buttonsWrapper">
        <img
          v-if="loading"
          src="../../assets/spinner.gif"
          alt="Loading"
          style="width: 24px; height:24px;"
        />
        <template v-for="(button, index) in buttons" :key="index">
          <Button
            :variant="button.variant"
            @click="$emit('buttonClicked', index)" tabindex="0" @keyup.enter="$emit('buttonClicked', index)"
            class="button mt-1"
            :disabled="button.disabled"
          >
            <template v-slot:text>{{ button.text }}</template>
          </Button>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import Button from './Button.vue';
export default {
  name: 'Popup',
  components: {
    Button
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * Example: [{text: 'OK', variant:'primary'}]
     * When clicked, the index is emitted with `buttonClicked`
     */
    buttons: {
      type: Array,
    },
    maxWidth: {
      type: String,
      required: false,
      default: 'auto',
    },
    width: {
      type: String,
      required: false,
      default: 'auto',
    },
    maxHeight: {
      type: String,
      required: false,
      default: 'auto',
    },
    height: {
      type: String,
      required: false,
      default: 'auto',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    headerMargin: {
      type: Boolean,
      default: true,
    },
    innerPopup: {
      type: Boolean,
      default: false,
    },
    grayTitle: {
      default: false,
      type: Boolean,
    },
    headerBorder: {
      default: false,
      type: Boolean,
    },
    leftPadding: {
      default: true,
      type: Boolean,
    },
  },
  computed: {
    size: function () {
      return `max-width: ${this.maxWidth}; width:${this.width}; max-height: ${this.maxHeight}; height:${this.height};`;
    },
    popupWrapperStyle: function () {
      if (this.innerPopup) {
        return `position: fixed;
          height: inherit;
          width: inherit;
          display: flex;
          justify-content: center;
          background: #00000080;
          align-items: center;
        `;
      }
      return `position: fixed;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #00000080;
        z-index: 50;
      `;
    },
    headerStyle: function () {
      let style = this.headerMargin ? ' margin-bottom: 24px; ' : ' ';
      if (this.grayTitle) {
        style += ' background: #e0e0e0; ';
      }
      if (this.headerBorder) {
        style += ' border-bottom: 1px solid #b7b7b7; ';
      }
      return style;
    },
  },
  methods: {
    closePopup: function () {
      this.$emit('close');
    },
    focus() {
      this.$refs.close.focus();
    },
  },

}
</script>

<style scoped>
#Popup {
  min-width: 392px;
  min-height: 216px;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 3px 12px #00000042;
  display: flex;
  z-index: 15;
  flex-direction: column;
  padding-bottom: 24px;
  border-radius: 8px;
}
.popupTitle {
  font-size: 20px;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.closeIcon {
  margin-left: auto;
  cursor: pointer;
}
.titleWrapper {
  display: flex;
  padding: 5px;
  padding-left: 24px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.bodyWrapper {
  font-size: 14px;
  height: calc(100% - 27px);
  width: 100%;
  padding-right: 24px;
}
.buttonsWrapper {
  display: flex;
  margin-top: auto;
  margin-left: auto;
  align-items: center;
}
.button {
  margin-left: 5px;
}
</style>