<template>
  <section :aria-label="getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.PROCEDURES_TRAY_TITLE)"
    class="section_wrapper ProceduresSections">
    <iframe frameborder="0" allowfullscreen="false" mozallowfullscreen="false" webkitallowfullscreen="false" :src="url" />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import Constants from '@/enums/Constants';
import BiologyLabEnum from '@/enums/BiologyLabEnum';
import NotificationMixin from '@/mixins/NotificationMixin';
import LocalizationMixin from '@/mixins/LocalizationMixin.js';

export default {
  name: 'TrayPanelLiveData',
  mixins: [NotificationMixin, LocalizationMixin],
  data: function () {
    return {
      BiologyLabEnum,
      Constants,
      type: '',
      url: '',
    };
  },
  computed: {
    ...mapGetters({
      procedure: 'GET_PROCEDURE',
    }),
  },
  watch: {
    procedure: {
      handler: function (newProcedure) {
        this.type = newProcedure.type;
        switch (this.type) {
          case 'video':
          case 'pdf':
            this.url = newProcedure.url;
            break;
          case 'docx':
          case 'office':
            this.url = `https://docs.google.com/gview?url=${newProcedure.url}&embedded=true`;
            break;
          case 'google':
            this.url = newProcedure.url;
            break;
          case 'gdoc':
          default:
            this.url = newProcedure.url;
            break;
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped >
@import '@/styles/colors.scss';

iframe, .ProceduresSections {
  width: 100%;
  height: 99.7%;
}
</style>