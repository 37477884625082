const constants = {
  PLANTS: 'Plants',
  ANIMALS: 'Animals',
  FUNGI: 'Fungi',
  PROTISTA: 'Protista',
  BACTERIA: 'Bacteria',
  ARCHAEA: 'Archaea',
  CATEGORY_LIST: {
    ALPHABETICAL: 'alphabeticText',
    CHARACTERISTICS: 'charactersText',
    CLASSIFICATION: 'classificationsText',
    HABITATS: 'habitatsText',
    MODELSPECIES: 'modelSpeciesText',
  },
  ALPHABETICAL: 'Alphabetical',
  CHARACTERISTICS: 'Characteristics',
  CLASSIFICATION: 'Classification',
  BIOMES: 'Biomes',
  MODELS: 'Models',
  S3_BUCKET_URL: 'https://biology-media.s3.us-west-1.amazonaws.com/',
  THUMBNAIL: '_thumb',
  FIELD_SCOPE: 'fieldScope',
  DISSECTING_MICROSCOPE: 'dissectingMicroscope',
  COMPOUND_MICROSCOPE: 'compoundMicroscope',
  SEARCH_PLACEHOLDER_TEXT: 'searchPlaceHolderText',
  SEM: 'SEM',
  TEM: 'TEM',
  MICROSCOPY: 'Microscopy',
  SPECIES: 'Species',
  THUMBNAIL_CLICK: 'Thumbnail',
  ZOOM: 'zoom',
  MAGNIFY: 'Magnify',
  MAGNIFY_IN: 'Magnify In',
  MAGNIFY_OUT: 'Magnify Out',
  CONTRAST: 'Contrast',
  ZOOM_POPUP: 'Zoom Popup',
  OPEN: 'Open',
  CLOSED: 'Closed',
  LAUNCHED: 'Launched',
  CLEAR_LAB: 'Clear Lab',
  PRESET: 'Preset',

  NOTES: "notes",

  LAB_TITLE_KEY: 'microscopy',
  BACK_BTN_LABEL_KEY: 'backBtnText',
  PROCEDURES_TRAY_TITLE: 'procedures',
  LIVE_DATA_TRAY_TITLE: 'liveData',
  SPECIES_TRAY_TITLE: 'species',
  LAB_BOOK_TRAY_TITLE: 'labBook',
  PRESETS_TRAY_TITLE: 'presets',
  SAVE_LAB_BTN_TITLE: 'saveLab',
  CLEAR_LAB_BTN_TITLE: 'clearLab',
  OPEN: 'open',
  OVERLOAD_READING: 'Over Load',
  OPEN_LABBOOK_KEY: 'openLabbook',
  PRESETS_KEY: 'openPresetList',
  KEY: '_key',
  SHOW: 'show',
  HIDE: 'hide',
  RESET_LAB_BUTTON: 'ResetLabButtonDown',
  BENCH: 'BENCH',
  OPEN_LAB_BOOK: 'openLabbook',
  INPUT_HYPHEN: 'input-',
  LINK_HYPHEN: 'link-',
  NEW_HYPHEN_LINK: 'new-link',
  NOT_VISIBLE_CLASS: 'not-visible',
  PRESET_URL: `https://presets.${process.env.VUE_APP_ACTIVE_DOMAIN}.com/`,
  SET_PRESET_TAB: 'SET_PRESET_TAB',
  FETCH: 'fetch',
  LIST: 'list',
  DELETE: 'delete',
  SAVE: 'save',
  SYSTEM_UPPERCASE: 'SYSTEM',
  AUTOLOAD_UPPERCASE: 'AUTOLOAD',
  EDIT_TITLE: 'editTitle',
  MY_SAVED_LAB: 'mySavedLabs',
  LAB_PRESET: 'labPresets',
  COPY_LINK: 'copyLink',
  DELETE_PRESET: 'deletePreset',
  ADD_COPIED_PRESET: 'addCopiedPreset',
  SEND: 'send',
  SUBMIT_PRACTICAL: 'submit',
  ADD_COPIED_PRESET_MESSAGE: 'addCopiedPresetMessage',
  LAB_SAVED_NOTIFICATION: 'labSavedNotification',
  LAB_RESTORED_NOTIFICATION: 'labRestoredNotification',
  PRESET_COPIED: 'presetCopied',
  ENTER_VALID_LINK: 'enterValidLink',
  INVALID_SAVED_LAB: 'invalidSavedLab',
  OTHER_SAVED_LAB: 'otherSavedLab',
  VALID_LINK: 'validLink',
  ZERO: '0',
  SYSTEM: 'SYSTEM',
  USER: 'USER',
  BASE_SPRITESHEET_KEY: 'base_image',
  IMG_EXT_JPG: '.jpg',
  HYPHEN_SEPERATED: ' - ',
  ACTUAL_RESULT: 'actualResult',
  SUBMIT: 'submit',
  ASSIGNMENT_NAME: 'assignmentName',
  UNKNOWN_LABEL: 'unknownLabel',
  REVEAL: 'reveal',
  CREATE_UNKNOWN_LABEL: 'createUnknown',
  SOLID_UNDERSCORE_EMPTY: '_MT',
  SOLID_UNDERSCORE_OUT: '_OUT',
  SOLID_FLOAT: '_FLOAT',
  IMG_PNG: '.png',
  ROW_1_SOLID_KEY: 'Row1Solid',
  FLOATING_SOLID_TEXTURE_KEY: 'FloatingSolids/FLOAT_',
  FLOATING_SOLID_SPRITE_KEY: 'Solid_FLOAT',
  SOLID_EMPTY: 'MT',
  SOLID_OUT: 'OUT',
  ROW_LABEL: 'Row',
  UNDERSCORE: '_',
  TRASH: 'TRASH',
  BUTTON_RIGHT: 'ButtonRight',
  BUTTON_LEFT: 'ButtonLeft',
  IMG_EXT_PNG: '.png',
  DISPENSE: 'Dispense',
  IMAGE: 'image',
  EMPTY: 'EMPTY',
  LIVE_DATA_BASE_IMAGE: 'ZoomBackground',
  ZOOM_TICK_VOLUMN_HALF: 'ZoomTickMarksVolumnHALF',
  ZOOM_TICK_VOLUMN_FULL: 'ZoomTickMarksVolumnFULL',
  Full: 'Full',
  Half: 'Half',
  BALANCE_MASS_MULTIPLICATION_CONSTANT: 1000,
  DROPBUTTON_BRACKET_1_HALF: 'DropButtonBracket1_HALF',
  DROPBUTTON_BRACKET_1_FULL: 'DropButtonBracket1_FULL',
  DROP_BUTTON_BRACKET: 'DropButtonBracket',
  LIVE_DATA_CYLINDER_VIEW: 'LiveDataCylinderView',
  LIVE_DATA_CYLINDER_VIEW_WRAPPER: 'LiveDataCylinderViewWrapper',
  LIVE_DATA_CYLINDER_VIEW_OFFSET: 16,
  LIVE_DATA_SAVED_NOTIFICATION: 'liveDataSavedNotification',
  ZERO_PIXEL: 495,
  FULL_PIXEL: 319,
  DROPBUTTON_BRACKET_2_HALF: 'DropButtonBracket2_HALF',
  DROPBUTTON_BRACKET_2_FULL: 'DropButtonBracket2_FULL',
  DROPBUTTON_BRACKET_3_HALF: 'DropButtonBracket3_HALF',
  DROPBUTTON_BRACKET_3_FULL: 'DropButtonBracket3_FULL',
  DROPBUTTON_BRACKET_4_HALF: 'DropButtonBracket4_HALF',
  DROPBUTTON_BRACKET_4_FULL: 'DropButtonBracket4_FULL',
  DROPBUTTON_BRACKET_5_HALF: 'DropButtonBracket5_HALF',
  DROPBUTTON_BRACKET_5_FULL: 'DropButtonBracket5_FULL',
  ALL_DROPBUTTON_HALF: 'All_DropButton_HALF',
  ALL_DROPBUTTON_FULL: 'All_DropButton_FULL',
  DROP_BUTTON: 'DropButton',
  ALL_UNDERSCORE: 'All_',
  UNDERSCORE_DOWN: '_DOWN',
  DROPBUTTON_SLICE_START: 17,
  DROPBUTTON_SLICE_END: 23,
  TIMER_SCREEN_1: 'TimerScreen1',
  TIMER_SCREEN_2: 'TimerScreen2',
  TIMER_SCREEN_3: 'TimerScreen3',
  TIMER_SCREEN_4: 'TimerScreen4',
  TIMER_SCREEN_5: 'TimerScreen5',
  DEFAULT_SCREEN_LABEL: '0.00',
  SOLID_IN_OPAQUE_CONTAINER_TEXTURE_KEY: 'Solids In Cylinders/Solid_IN_OPAQUE_',
  SOLID_IN_SEMI_CONTAINER_TEXTURE_KEY: 'Solids In Cylinders/Solid_IN_SEMI_',
  SEMI: 'SEMI',
  OPAQUE: 'OPAQUE',
  CLEAR: 'CLEAR',
  SOLID_ON_CONTAINER_SPRITESHEET_KEY: 'solidsOnContainer_image',
  TIMER_SCREEN: 'TimerScreen',
  FLUID_RAISE_TEXTURE_KEY: 'FluidLevelRaise_A/FluidRaise_',
  FLUID_LEVEL_RAISE: 'FluidLevelRaise_',
  FLUID_RAISE: 'FluidRaise_',
  FLUID_RAISE_KEY: 'fluidRaise_',
  FLUID_RAISE_SPRITESHEET_KEY: 'fluidRaise_A_image',
  COLOR_WHITE: 'WHITE',
  COLOR_SILVER: 'SILVER',
  COLOR_BALCK: 'BLACK',
  TOP_MASK_FUZZY: 'TopMaskFuzzy',
  TOP_MASK_SHARP: 'TopMaskSharp',
  MAX_FLUIDS_IN_CYLINDER: 2,
  CYLINDER_1_HOTSPOT: 'cylinderHotspot1',
  CYLINDER_2_HOTSPOT: 'cylinderHotspot2',
  CYLINDER_3_HOTSPOT: 'cylinderHotspot3',
  CYLINDER_4_HOTSPOT: 'cylinderHotspot4',
  CYLINDER_5_HOTSPOT: 'cylinderHotspot5',
  CYLINDER_HOTSPOT: 'cylinderHotspot',
  LIVE_DATA_SPRITESHEET_NAME: 'liquidLevel_image',
  LIVE_DATA_FLUID_TEXTURE_BASE_NAME: 'ZoomView_fluid_bromine_',
  VISCOSITY_CHECK_CONSTANT: 10,
  CONSTANT_THOUSAND: 1000,
  UNKNOWN: 'Unknown',
  PRACTICE: 'practice',
  ASSIGNED: 'assigned',
  EXPLODE_NOTIFICATION: 'explodeCylinderNotification',
  ASSIGNMENT_SAVE_NOTIFICATON: 'assignmentSaveToLabbook',
  SOLID_IMG_HEIGHT: 21,
  ENTER_ALL_VALUE_NOTIFICATION: 'enterAllValueNotification',
  LIQUID_LEVEL: 'liquidLevel',
  UNDERSCORE_IMAGE: '_image',
  CYLINDER_VIEW_CANVAS_HEIGHT: 101,
  ADD_SEPERATED: ' + ',
  ZOOMVIEW_FLUID: 'ZoomView_fluid_',
  DISPENSER_MASK: 'DispenserMask',
  MASK_INIT_POS: 17,
  MASK_MAX_POS: 121,
  EIGHT: 8,
  FOUR: 4,
  INCREASE_STEP: 2,
  MASK_TIMER: 200,
  LIQUID_SELECTOR_ANIMATION_DELAY: 100,
  resizingSaveLiveDataConstant: 5,
  UNKNOWN_STOCKROOM_INDEX: 25,
  SOLID_IMG_OFFSET_LIVE_DATA: 68,
  SOLID_IMG_HEIGHT_LIVE_DATA: 132,
  FRAME_BEFORE_SETTLING: 90,
  FRAMES_BETWEEN_SETTLING: 53,
  POSITION_TO_START_FRAME_38_WTIH: 60,
  FRAME_BEFORE_SOLID_DROPS_IN_FLUID: 38,
  PIXEL_DIFF_BETWEEN_MARKS: 100,
  DIFF_BETWEEN_MIN_MAX_TICKS: 25.0,
  MAX_TICK_VALUE_HALF_CYLINDER: 140,
  MAX_TICK_VALUE_FULL_CYLINDER: 255,
  LIVEDATA_MASK_MIN_FRAME: 3,
  LIVEDATA_MASK_MAX_FRAME: 33,
  BASE_TEXTURE_KEY: 'solids/tube/Zoom_solids_in_tube_',
  MASK_TEXTURE_KEY: 'livedataSolidsMask_image',
  SINK: 'SINK',
  INT_FIFTY: 50,
  LOADING_ICON_KEY: 'loading_BL_image',
  MIN_TICK_VALUE_HALF_CYLINDER: 110,
  MIN_TICK_VALUE_FULL_CYLINDER: 225,
  SETTLING_ANIM_CONFIG: 'settlingAnim',
  FLUID_RAISE_INIT_ZINDEX: 300,
  SETTLING_IMG_MULTIPLIER_FACTOR: 2,
  PX: 'px',
  CYLINDER_BASE_WIDTH: 200,
  PADDING_OFFSET: 15,
  FLUID_NAME: 'fluidName',
  FLUID_RAISE_BOTTOM_ZINDEX: 350,
  SETTLING_ANIM_DELAY: 100,
  Z_INDEX_99: 99,
  Z_INDEX_100: 100,
  SOLID_IN_CYLINDER_BACKGROUND: 'solidInCylinderBackground',
  Y_OFFSET: 2,
  EMPTY_STRING: '',
  COMMA: ', ',
  EMPTY_CYLINDER_ON_EXPLODE_DELAY: 50,
  INT_FIVE: 5,
  GLASS_ID: 'glass',
  ICE_ID: 'ice',
  PLASTIC_ID: 'plastic',
  MILK_ID: 6,
  LIQUID_SELECTOR_ANIMATION_TIMER: 500,
  SETTLING_END_FRAME: 119,
  LAB_IMAGE_SAVED: 'labImageSaved',
  SAVE_LAB_IMAGE: 'saveLabImage',
  MEDIA_CONTENT: 'media/content/',
  IMG_EXT_F4V: '.f4v',
  IMG_EXT_MP4: '.mp4',
  LABELS: 'labels',
  CONTRAST: 'contrast',
  LAB_BACKGROUND: "labBackground",
  MAGNIFICATION_POSTER: "magnificationPoster",
  EXPORT: "export",
  IMPORT: "import",
  COPY: "copy",
  PASTE: "paste",
  PRESET_NAME: "presetName",
  EXPORT_PDF: 'exportPDF',
  IMPORT_PDF: 'importPDF',
  BOLD: 'bold',
  ITALIC: 'italic',
  UNDERLINE: 'underline',
  SUPERSCRIPT: 'superscript',
  SUBSCRIPT: 'subscript',
  SUBMIT_ENTRY: 'submitEntry',
  COPY_MAC: 'copyMac',
  COPY_WIN: 'copyWin',
  PASTE_MAC: 'pasteMac',
  PASTE_WIN: 'pasteWin',
  SEARCH: 'search',
  TRAY_MENU_MESSAGE: 'trayMenuMessage',
  LAB_NOTES_LABEL: 'labNotesLabel',
};

export default Object.freeze(constants);
