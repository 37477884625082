<template>
  <div>
    <div id="editor"></div>
    <div id="toolbar">
      <QuillToolbar ref="toolbar"/>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import QuillToolbar from "./QuillToolbar.vue";
import { v4 as uuidv4 } from "uuid";
import * as LabBookMiddleware from "./scripts/LabBookMiddleware";
import BiologyLabEnum from '../../enums/BiologyLabEnum';
import Constants from '../../enums/Constants';
let quill;
export default {
  name: "QuillEditor",
  data: () => ({
    Constants,
    BiologyLabEnum,
    showUploader: false,
    disabled: false,
    content: "",
    _content: "",
    editorOption: {},
  }),
  props: ["labFilter", "LAB_BOOK_SESSIONS"],
  emits: ["requestScrollDown"],
  created() { },
  mounted() {
    this.$nextTick(() => {
      var formats = [
        "background",
        "bold",
        "color",
        "font",
        "code",
        "italic",
        "link",
        "size",
        "strike",
        "script",
        "underline",
        "blockquote",
        "header",
        "indent",
        "list",
        "align",
        "direction",
        "code-block",
        "formula",
        // 'image'
        // 'video'
      ];
      quill = new Quill("#editor", {
        modules: {
          toolbar: "#toolbar",
          keyboard: {
            bindings: {
              enter: {
                key: 13,
                handler: () => {
                  this.submitEntry();
                },
              },
              escape: {
                key: 27,
                handler: () => {
                  this.focusToolbar();
                },
              },
            },
          },
        },
        placeholder: this.placeholderText,
        theme: "snow",
        formats: formats,
      });
      this.setContents();
      this.quillEvents();
      this.customButtons();
      // if (!this.viewingMyLabbook) {
      //   quill.enable(false);
      // }
    });
  },
  methods: {
    ...mapActions({
      SET_LAB_BOOK_CURRENT_ENTRY: "SET_LAB_BOOK_CURRENT_ENTRY",
      LAB_BOOK_SESSION_UPDATE: "LAB_BOOK_SESSION_UPDATE",
      SEND_MESSAGE: "SEND_MESSAGE",
    }),
    togglePDFUploader() {
    },
    focusToolbar() {
      this.$refs.toolbar.focus();
    },
    submitEntry() {
      const entry = quill.root.innerHTML;
      let submission = {
        actor_id: this.ACTIVE_LAB_BOOK,
        user_id: this.USER.userId,
        method: undefined,
        entry: undefined,
        labType: undefined,
      };
      let x;
      submission.entry = {
        metaData: {
          entry_type: 1,
          entry_id: uuidv4(),
          content: `${entry}`,
        },
      };
      submission.labType = this.LAB_BOOK_TYPE;

      this.$emit("requestScrollDown");
      LabBookMiddleware.lab_book("entry", {
        entry: `${entry}`,
        type: 1,
      });
      quill.setContents("");
    },
    customButtons() {
      document.getElementById("lab-book-submit-entry").addEventListener("click", () => {
        this.submitEntry();
      });
      document.getElementById("lab-book-import-pdf").addEventListener("click", () => {
        this.SEND_MESSAGE({ action: "pdf-upload" });
      });
      document.getElementById("lab-book-export-pdf").addEventListener("click", () => {
        this.SEND_MESSAGE({
          action: "pdf-create",
          innerHTML: document.getElementById("lab-book-sessions-wrapper").innerHTML,
        });
      });
    },
    quillEvents() {
      quill.on("text-change", (delta, oldDelta, source) => {
        this.SET_LAB_BOOK_CURRENT_ENTRY(quill.getContents());
      });
      document.querySelector(".ql-editor").setAttribute("role", "textbox");
      document.querySelector(".ql-editor").setAttribute("aria-multiline", "true");
      document.querySelector(".ql-editor").setAttribute("tabindex", "0");
      document.querySelector(".ql-editor").setAttribute("aria-label", this.getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.LAB_NOTES_LABEL));
    },
    setContents() {
      quill.setContents(this.LAB_BOOK_CURRENT_ENTRY);
    },
  },
  components: {
    QuillToolbar,
  },
  computed: {
    ...mapGetters({
      LAB_BOOK_CURRENT_ENTRY: "LAB_BOOK_CURRENT_ENTRY",
      LAB_BOOK_TYPE: "LAB_BOOK_TYPE",
      ACTIVE_LAB_BOOK: "ACTIVE_LAB_BOOK",
      USER: "GET_USER",
    }),
    message: {
      /* By default get() is used */
      get() {
        return this.$store.state.message;
      },
      /* We add a setter */
      set(value) {
        this.$store.commit("updateMessage", value);
      },
    },
    viewingMyLabbook() {
      return this.USER.userId === this.ACTIVE_LAB_BOOK;
    },
    placeholderText() {
      if (this.viewingMyLabbook) {
        return this.getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.NOTES)+'...';
      } else {
        return this.getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.INSTRUCTOR_NOTE)+'...';
      }
    },
  },
  watch: {},
};
</script>
<style>
#editor {
  width: 100%;
  height: 70px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

#editor .ql-container {
  display: flex;
  flex-direction: column-reverse;
}

.ql-toolbar .ql-stroke {
  fill: none !important;
  stroke: #17469e !important;
}

.ql-toolbar .ql-fill {
  fill: #17469e !important;
  stroke: none !important;
}

.quill-toolbar-image {
  fill: #17469e !important;
  stroke: none !important;
  width: 24px;
  height: 24px;
}

.ql-toolbar.ql-snow {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.ql-toolbar .ql-picker {
  color: #17469e !important;
}

.ql-formats {
  border-right: solid 1px var(--blz-blue);
  padding-right: 5px;
  margin-right: 5px !important;
}

.ql-formats.quill-submit {
  border-right: none;
  margin-right: 0;
  padding-right: 0;
}

.ql-active {
  /* background-color: rgba(23,70,158, 1.0) !important; */
  background-color: var(--blz-blue) !important;
}

.ql-active .ql-stroke {
  fill: none !important;
  stroke: #fff !important;
}

.ql-active .ql-fill {
  fill: #fff !important;
  stroke: none !important;
}

.ql-active .ql-picker {
  color: #fff !important;
}

.quill-custom {
  color: var(--blz-blue) !important;
  padding: 0 !important;
  font-size: 22px !important;
}

.ql-editor.ql-blank:focus::before {
  content: "";
}
.ql-editor>p {
  color: #575757;
}

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}
</style>
