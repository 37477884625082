<template>
  <router-view v-if="isLocal || GET_USER.userId !== undefined" />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import 'material-design-icons/iconfont/material-icons.css';
import "@mdi/font/scss/materialdesignicons.scss";
import { setupCallback as xapiCallback } from '@/services/xapi';
import {
  setupCallback as labBookCallback,
  setLabName,
} from '@/components/LabBook/scripts/LabBookMiddleware';

export default {
  name: "App",
  data: () => ({
    isLocal: false,
  }),
  created() {
    if (window.top === window.self) {
      this.isLocal = true;
    }

    window.addEventListener('message', (event) => {
      if (typeof event.data !== 'undefined') {
        this.processMessage(event.data);
      }
    });

    setLabName('Microscopy');
    xapiCallback(this.xapiCallback);
    labBookCallback(this.labBookCallback);
    this.SET_LAB_TYPE(9); // Should really be reading this from the middlewareSDK data.
    console.log('App :: Microscopy :: created');
  },
  methods: {
    ...mapActions({
      processMessage: 'PROCESS_MESSAGE',
      sendMessage: 'SEND_MESSAGE',
      SET_LAB_TYPE: 'SET_LAB_TYPE',
    }),
    xapiCallback(msg) {
      this.sendMessage({ action: 'statement', msg });
    },
    labBookCallback(msg) {
      this.sendMessage({ action: 'labbook_entry', msg });
    },
  },
  computed: {
    ...mapGetters({
      GET_USER: 'GET_USER',
    }),
  },
};
</script>

<style lang="scss">
.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.sr-only-text {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

:root {
  --blz-blue: rgba(23, 70, 158, 1);
}

.itunes {
  background-color: #8a8c94;

  /* IE10 Consumer Preview */
  background-image: -ms-radial-gradient(center bottom,
      ellipse farthest-corner,
      #ffffff 0%,
      #bdbbce 50%,
      #8a8c94 100%);

  /* Mozilla Firefox */
  background-image: -moz-radial-gradient(center bottom,
      ellipse farthest-corner,
      #ffffff 0%,
      #bdbbce 50%,
      #8a8c94 100%);

  /* Opera */
  background-image: -o-radial-gradient(center bottom,
      ellipse farthest-corner,
      #ffffff 0%,
      #bdbbce 50%,
      #8a8c94 100%);

  /* Webkit (Safari/Chrome 10) */
  background-image: -webkit-gradient(radial,
      center bottom,
      0,
      center bottom,
      553,
      color-stop(0, #ffffff),
      color-stop(0.5, #bdbbce),
      color-stop(1, #8a8c94));

  /* Webkit (Chrome 11+) */
  background-image: -webkit-radial-gradient(center bottom,
      ellipse farthest-corner,
      #ffffff 0%,
      #bdbbce 50%,
      #8a8c94 100%);

  /* W3C Markup, IE10 Release Preview */
  background-image: radial-gradient(ellipse farthest-corner at center bottom,
      #ffffff 0%,
      #bdbbce 50%,
      #8a8c94 100%);
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/open-sans-v18-latin/open-sans-v18-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    /* IE6-IE8 */
    url('./assets/fonts/open-sans-v18-latin/open-sans-v18-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* Super Modern Browsers */
    url('./assets/fonts/open-sans-v18-latin/open-sans-v18-latin-regular.woff2') format('woff2'),
    /* Modern Browsers */
    url('./assets/fonts/open-sans-v18-latin/open-sans-v18-latin-regular.woff') format('woff'),
    /* Safari, Android, iOS */
    url('./assets/fonts/open-sans-v18-latin/open-sans-v18-latin-regular.ttf') format('truetype'),
    /* Legacy iOS */
    url('./assets/fonts/open-sans-v18-latin/open-sans-v18-latin-regular.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Open Sans Condensed Bold';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/open-sans-condensed-bold/OpenSansCondensed-Bold.ttf') format('truetype');
}

/* benchnine-regular - latin */
@font-face {
  font-family: 'BenchNine';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/benchnine-v9-latin/benchnine-v9-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local('BenchNine Regular'), local('BenchNine-Regular'),
    /* IE6-IE8 */
    url('./assets/fonts/benchnine-v9-latin/benchnine-v9-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* Super Modern Browsers */
    url('./assets/fonts/benchnine-v9-latin/benchnine-v9-latin-regular.woff2') format('woff2'),
    /* Modern Browsers */
    url('./assets/fonts/benchnine-v9-latin/benchnine-v9-latin-regular.woff') format('woff'),
    /* Safari, Android, iOS */
    url('./assets/fonts/benchnine-v9-latin/benchnine-v9-latin-regular.ttf') format('truetype'),
    /* Legacy iOS */
    url('./assets/fonts/benchnine-v9-latin/benchnine-v9-latin-regular.svg#BenchNine') format('svg');
}

* {
  font-family: 'Open Sans', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.noDecoration {
  li {
    list-style: none;
  }
}

.p-0 {
  padding: 0;
}

.m-0 {
  margin: 0;
}

html,
body {
  padding: 0;
  height: 100%;
  width: 100%;
  margin: 0;
}

#app {
  width: 100%;
  height: 100%;
}

.text-init {
  font-family: BenchNine;
  font-size: 0px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.button {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  justify-content: center;
  // outline: 0;
  position: relative;
  text-decoration: none;
  text-indent: 0.0892857143em;
  text-transform: uppercase;
  transition-duration: 0.28s;
  transition-property: box-shadow, transform, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.button_blue {
  color: rgb(23, 70, 158);
}

.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.user-selectable {
  user-select: text;
}

/* Live Data */
.section_wrapper {
  width: 100%;
  text-align: left;
}

.section_detail {
  width: 100%;
  text-align: left;
  display: none;
}

.open>.section_detail {
  display: block;
}

.open i {
  transform: rotate(90deg);
}

li {
  box-sizing: border-box !important;
  cursor: pointer;
}

div.enabled {
  color: #003399 !important;
}

div.inUse {
  background-color: #e5e5e5 !important;
  border: 1px solid #9cb2d4 !important;
  box-sizing: border-box !important;
}

div.disabled {
  color: rgba(0, 0, 0, 0.38) !important;
}

.loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
</style>
