// import SecurityServices from "@/services/SecurityServices";
const XhrClient = {
  id_token: undefined,
  request(options) {
    let params = {
      url: null,
      body: null,
      contentType: 'application/json',
      asset: false,
      method: 'POST',
      withCredentials: false,
      authHeader: true,
    };

    params = {
      ...params,
      ...options,
    };

    let xhr = new XMLHttpRequest();

    return new Promise(function(resolve, reject) {
      xhr.withCredentials = params.withCredentials; // Allows cross domain authentication in our case.
      xhr.crossorigin = true;

      xhr.onreadystatechange = function() {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200 || xhr.status === 421) {
            let response;

            try {
              response = JSON.parse(xhr.responseText);
            } catch (e) {
              response = xhr.responseText;
            }

            resolve(response);
          } else if (xhr.status === 400) {
            reject({ error: 404 });
          } else {
            reject(xhr.status);
          }
        }
      };

      xhr.open(params.method, params.url);
      if (params.authHeader && typeof XhrClient.id_token !== 'undefined') {
        xhr.setRequestHeader('Authorization', `Bearer ${XhrClient.id_token}`);
        if (params.contentType !== null) {
          xhr.setRequestHeader('Content-Type', params.contentType);
        }

        if (params.body !== null) {
          if (params.asset) {
            xhr.send(params.body);
          } else {
            xhr.send(JSON.stringify(params.body));
          }
        } else {
          xhr.send();
        }

      } else {
        if (params.contentType !== null) {
          xhr.setRequestHeader('Content-Type', params.contentType);
        }

        if (params.body !== null) {
          if (params.asset) {
            xhr.send(params.body);
          } else {
            xhr.send(JSON.stringify(params.body));
          }
        } else {
          xhr.send();
        }
      }
    });
  },
  disableContextMenu() {
    if (document.addEventListener) {
      document.addEventListener(
        'contextmenu',
        function(e) {
          e.preventDefault();
        },
        false,
      );
    } else {
      document.attachEvent('oncontextmenu', function() {
        window.event.returnValue = false;
      });
    }
  },
};

export default XhrClient;
