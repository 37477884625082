<template>
  <ul class="radioButtons noDecoration m-0 p-0">
    <li v-for="(button, index) in buttons" :key="index">
      <Button :variant="index == selectedButtonIndex ? 'primary' : 'secondary'" @click="selectButton(button, index)"
        height="25px" :aria-checked="index == selectedButtonIndex ? 'true' : 'false'" :aria-label="button[textAttribute]"
        :title="button[textAttribute]" role="tab" :aria-selected="index == selectedButtonIndex ? 'true' : 'false'" :aria-controls="button[textAttribute]">
        <template v-slot:text>{{ button[textAttribute] }}</template>
      </Button>
    </li>
  </ul>
</template>
<script>
import Button from './Button.vue';
export default {
  name: 'RadioButtons',
  emits: ['selectedRadioButton'],
  data: () => ({
    selectedButtonIndex: null,
  }),
  props: {
    buttons: {
      type: Array,
      required: true,
    },
    textAttribute: {
      type: String,
      default: 'text',
    },
    selectedButtonId: {
      type: Number,
      default: null,
    },
  },
  methods: {
    selectButton(button, index) {
      if (this.selectedButtonIndex == index) {
        this.selectedButtonIndex = null;
      } else {
        this.selectedButtonIndex = index;
      }
      this.$emit("selectedRadioButton", button);
    },
  },
  components: {
    Button,
  },
}
</script>
<style scoped>
.radioButtons {
  display: flex;
  gap: 12px;
  width: 100%;
}
@media only screen and (max-width: 1650px) {
  .radioButtons {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1550px) {
  .radioButtons {
    font-size: 12px;
  }
}
@media only screen and (max-width: 1420px) {
  .radioButtons {
    font-size: 10px;
  }
}
@media only screen and (max-width: 1300px) {
  .radioButtons {
    font-size: 9px;
  }
}
</style>
