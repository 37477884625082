const languages = [
  {
    id: 'en',
    name: 'English',
    label: 'English',
  },
  {
    id: 'es',
    name: 'Spanish',
    label: 'Spanish',
  },
];

export default languages;
