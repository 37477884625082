<template>
  <div class="lab-book-body-inner">
    <div class="columns is-gapless if-fluid">
      <div class="column">
        <div class="lab-book-wrapper">
          <div class="lab-book-sessions">
            <div
              id="lab_book_sessions"
              class="lab-book-sessions-inner"
              ref="lab_book_sessions"
            >
              <div id="lab-book-sessions-wrapper">
                <div v-for="(session, index) in LAB_BOOK_ENTRIES" :key="index">
                  <LabBookSession :session="session" />
                </div>
              </div>
            </div>
          </div>
          <div class="quill-dock">
            <QuillEditor
              :LAB_BOOK_SESSIONS="LAB_BOOK_SESSIONS"
              @requestScrollDown="requestScrollDown"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import 'bulma/sass/grid/columns.sass';
import QuillEditor from './QuillEditor.vue';
import LabBookSession from './LabBookSession.vue';
export default {
  name: 'LabBook',
  data: () => ({
    scrollDownRequested: false,
  }),
  props: [],
  created() {},
  mounted() {},
  methods: {
    ...mapActions({}),
    requestScrollDown() {
      this.scrollDownRequested = true;
    },
    checkHeight() {
      let s = document.getElementById('lab-book-sessions-wrapper');
      let x = document.getElementById('lab_book_sessions');
      if (typeof s !== 'undefined' && s !== null) {
        x.scrollTop = s.scrollHeight;
      }
    },
  },
  computed: {
    ...mapGetters({
      LAB_BOOK_ENTRIES: 'LAB_BOOK_ENTRIES',
    }),
    LAB_BOOK_SESSIONS() {
      const LBE = this.LAB_BOOK_ENTRIES.filter((e) => {
        return e.actor_id === this.ACTIVE_LAB_BOOK;
      });
      if (!this.gotSessions) {
        this.gotSessions = true;
        setTimeout(() => {
          this.checkHeight();
        }, 1000);
      }
      if (this.scrollDownRequested) {
        this.scrollDownRequested = false;

        this.$nextTick(() => {
          setTimeout(() => {
            this.checkHeight();
          }, 1000);
        });
      }
      return LBE;
    },
  },
  components: {
    QuillEditor,
    LabBookSession,
  },
};
</script>
<style lang="scss">
.lab-book-body-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  padding-bottom: 10px;
  text-align: left;
}

.lab-book-columns {
  box-shadow: 0 3px 3px #00000029;
  background-color: #fff;
}

.columns,
.column {
  height: 100%;
}

.lab-book-labcam-header {
  background-color: #17469e14;
  color: #000;
  border-bottom: 1px solid #f6f6f6;
  width: 100%;
  position: relative;
  border-right: #fff 5px solid;
  border-left: #fff 5px solid;
}

.lab-book-labcam-action-wrapper {
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.lab-book-labcam-title {
  padding-left: 20px;
}

.lab-book-labcam-title-inner {
  margin-left: 10px;
}

.lab-book-labcam-action {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin: auto;
}

.lab-book-labcam-action-wrapper {
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.lab-book-wrapper {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}

.lab-book-sessions,
.lab-book-labcam,
.lab-book-actions {
  flex: 1 100%;
  overflow-y: hidden;
}

.lab-book-sessions-inner,
.lab-book-labcam-inner {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

#lab-book-sessions-wrapper,
#lab-book-labcam-wrapper {
  // Should report correct height for scrollTop
  width: 100%;
  position: relative;
  background-color: #fff;
}

.quill-dock {
  background-color: #fff;
  flex: 1;
  padding: 10px;
}

.lab-book-wrapper {
  word-break: break-all;

  .top-line,
  .bottom-line {
    margin-bottom: 8px;
  }

  .title-container {
    display: flex;
    flex-direction: row;
    white-space: nowrap;

    .time-stamp {
      margin-left: 4px;
    }

    .copy-data {
      display: flex;
      flex-direction: row;
      color: #17469e;
      border: 1px solid;
      border-radius: 5px;
      height: 27px;
      padding: 3px;
      margin-left: auto;
      // outline: none;
      position: relative;
      overflow: hidden;
      transition: background 400ms;

      .copy-img {
        width: 18px;
        height: 18px;
        pointer-events: none;
      }

      #copyText {
        pointer-events: none;
      }
    }

    span.ripple {
      position: absolute;
      border-radius: 50%;
      transform: scale(0);
      animation: ripple 600ms linear;
      background-color: rgba(0, 0, 0, 0.3);
    }

    @keyframes ripple {
      to {
        transform: scale(4);
        opacity: 0;
      }
    }

    .ripple {
      background-position: center;
      transition: background 0.8s;
    }

    .ripple:hover {
      background: #fff radial-gradient(circle, transparent 1%, #fff 1%)
        center/15000%;
    }

    .ripple:active {
      background-color: #17469e7c;
      background-size: 100%;
      transition: background 0s;
    }
  }

  .instructorNote {
    color: #b90000 !important;
  }

  .instructorNote strong {
    color: #b90000 !important;
  }

  #tableWrapper {
    max-width: 500px;
    max-height: 251px;
    overflow: scroll;

    #tableContainer {
      max-width: 435px;
      max-height: 223px;
      user-select: all;

      table,
      th,
      td {
        border: 1px solid black;
        border-collapse: collapse;
        text-align: center;
      }

      th {
        background-color: #f0f0f0;
      }

      td {
        min-width: 100px;
      }

      table {
        width: 100%;
        max-width: 435px;
        max-height: 223px;
        overflow: scroll;
      }
    }
  }

  .ml-auto {
    margin-left: auto;
  }

  .selectedButton {
    background: lightblue !important;
    border-radius: 5px;
  }

  .dateString {
    font-size: 0.75em;
    margin-left: 5px;
  }

  .pastePopup {
    position: absolute !important;
    bottom: 95px;
    width: fit-content;
    background: white;
    border: 1px solid;
    border-radius: 4px;
    z-index: 10;
  }

  img {
    max-width: 100%;
  }

  .colorSpectrum {
    padding-left: 32px;
  }

  @media only screen and (max-width: 1575px) {
    .colorSpectrum {
      padding-left: 7%;
      padding-right: 5%;
    }
  }
}
</style>
