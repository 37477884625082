<template>
  <nav class="menu" :class="panels" id="trayMenuPanels">
    <div class="sr-only-text">
      {{ hiddenMessage }}
    </div>
    <ul role="tablist" class="noDecoration p-0 m-0">
      <li role="presentation" v-if="procedure != null" class="menu_item procedure_item ripple" :aria-selected="panels == PanelConstants.PROCEDURES" @click="setPanel(PanelConstants.PROCEDURES)"
        tabindex="0" @keyup.enter="setPanel(PanelConstants.PROCEDURES)">
        <img src="@/assets/nav_tray_icons/Procedures_Icon_Up.svg" alt="" class="tab-btn-icon" />
        <p>{{ proceduresTrayTitle }}</p>
      </li>
      <li role="presentation" class="menu_item livedata_item ripple" :aria-selected="panels == PanelConstants.LIVEDATA" @click="setPanel(PanelConstants.LIVEDATA)" tabindex="0"
        @keyup.enter="setPanel(PanelConstants.LIVEDATA)">
        <img src="../assets/nav_tray_icons/Live_Data_Icon_Up.svg" alt="" class="tab-btn-icon" />
        <p>{{ liveDataTrayTitle }}</p>
      </li>
      <li role="presentation" class="menu_item species_item ripple" :aria-selected="panels == PanelConstants.SPECIES" @click="setPanel(PanelConstants.SPECIES)" tabindex="0"
        @keyup.enter="setPanel(PanelConstants.SPECIES)">
        <img src="../assets/nav_tray_icons/SpeciesTray_Icon.svg" alt="" class="tab-btn-icon" />
        <p>{{ speciesTrayTitle }}</p>
      </li>
      <li role="presentation" class="menu_item labbook_item ripple" :aria-selected="panels == PanelConstants.LABBOOK" @click="setPanel(PanelConstants.LABBOOK)" tabindex="0"
        @keyup.enter="setPanel(PanelConstants.LABBOOK)">
        <img src="../assets/nav_tray_icons/Lab_Book_Icon_Up.svg" alt="" class="tab-btn-icon" />
        <p>{{ labBookTrayTitle }}</p>
      </li>
      <li role="presentation" class="menu_item presets_item ripple" :aria-selected="panels == PanelConstants.PRESETS" @click="setPanel(PanelConstants.PRESETS)" tabindex="0"
        @keyup.enter="setPanel(PanelConstants.PRESETS)">
        <img src="../assets/nav_tray_icons/Presets_Icon_Up.svg" alt="" class="tab-btn-icon" />
        <p>{{ presetsTrayTitle }}</p>
      </li>
    </ul>
    <div>
      <div class="menu_item ripple" @click.exact="saveLab()" tabindex="0" @keyup.enter="saveLab()">
        <img src="../assets/nav_tray_icons/Save_State_Up.svg" alt="" class="tab-btn-icon" />
        <p>{{ saveLabBtnTitle }}</p>
      </div>
      <div class="menu_item ripple" @click="clearLab()" tabindex="0" @keyup.enter="clearLab()">
        <img src="../assets/nav_tray_icons/Clear_Lab_Up.svg" alt="" class="tab-btn-icon" />
        <p>{{ clearLabBtnTitle }}</p>
      </div>
    </div>
  </nav>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import * as StoreTypes from '../store/store-types';
import PanelConstants from '../enums/PanelConstants';
import BiologyLabEnum from '../enums/BiologyLabEnum';
import Constants from '../enums/Constants';
import EventNamesConstants from '../enums/EventNamesConstants';
import { entry } from '../services/xapi';
import xapiEntryTypes from '../enums/xapiEntryTypes';

export default {
  name: 'TrayMenu',
  data: function () {
    return {
      PanelConstants,
      hiddenMessage: this.getLocaleData(
        BiologyLabEnum.MICROSCOPY,
        Constants.TRAY_MENU_MESSAGE,
      ),
      proceduresTrayTitle: this.getLocaleData(
        BiologyLabEnum.MICROSCOPY,
        Constants.PROCEDURES_TRAY_TITLE,
      ),
      liveDataTrayTitle: this.getLocaleData(
        BiologyLabEnum.MICROSCOPY,
        Constants.LIVE_DATA_TRAY_TITLE,
      ),
      speciesTrayTitle: this.getLocaleData(
        BiologyLabEnum.MICROSCOPY,
        Constants.SPECIES_TRAY_TITLE,
      ),
      labBookTrayTitle: this.getLocaleData(
        BiologyLabEnum.MICROSCOPY,
        Constants.LAB_BOOK_TRAY_TITLE,
      ),
      presetsTrayTitle: this.getLocaleData(
        BiologyLabEnum.MICROSCOPY,
        Constants.PRESETS_TRAY_TITLE,
      ),
      saveLabBtnTitle: this.getLocaleData(
        BiologyLabEnum.MICROSCOPY,
        Constants.SAVE_LAB_BTN_TITLE,
      ),
      clearLabBtnTitle: this.getLocaleData(
        BiologyLabEnum.MICROSCOPY,
        Constants.CLEAR_LAB_BTN_TITLE,
      ),
    };
  },
  methods: {
    setPanel: function (panel) {
      this.$store.dispatch(StoreTypes.SET_PANEL_ACTION, panel);
      const extensions = {
        name: 'Tray',
        state: panel,
      };
      entry(xapiEntryTypes.STATE, {
        extensions: extensions,
      });
    },
    clearLab: function () {
      this.$emit(EventNamesConstants.CLEAR_LAB);
    },
    saveLab: function () {
      this.$store.dispatch(StoreTypes.SAVE_LAB_ACTION, true);
    },
  },
  computed: {
    ...mapState({}),
    ...mapGetters({
      panels: StoreTypes.PANELS,
      procedure: 'GET_PROCEDURE',
    }),
  },
};

</script>
 
<style scoped lang="scss">
.sections {
  position: absolute;
  background-color: aqua;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  margin-left: 74px;
  margin-right: -74px;
  z-index: 10;
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 72px;
  z-index: 10;
}

.menu_item {
  max-height: 63px;
  width: 100%;
  text-align: center;
  padding: 12px 0;
  cursor: pointer;
}

.ripple {
  background-position: center;
  transition: background 0.8s;

  &:hover {
    background: rgba(0, 0, 0, 0.1) radial-gradient(circle, transparent 1%, rgba(0, 0, 0, 0.1) 1%) center/15000%;
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.4);
    background-size: 100%;
    transition: background 0s;
  }
}

.PROCEDURES .procedure_item,
.LIVEDATA .livedata_item,
.SPECIES .species_item,
.LABBOOK .labbook_item,
.PRESETS .presets_item,
.STOCKROOM .stockroom_item {
  background-color: #fff;
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    -1px 2px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 0 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px 0 0 4px;
}

.menu_item.active {
  background-color: #fff;
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    -1px 2px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 0 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px 0 0 4px;
}

.menu_item img {
  width: 25px;
  height: 25px;
  margin: auto;
}

.menu_item p {
  font-size: 11px;
  width: 70px;
  color: #000;
  text-align: center;
  margin: 0;
  text-indent: 0 !important;
  letter-spacing: normal !important;
  text-transform: none !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
  cursor: none;
}

.dot {
  height: 10px;
  max-width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 135px;
  left: 38px;
  padding: 5px;
}
</style>
