<template>
  <header class="lab-header">
    <div class="header-container">
      <button v-if="!isWhiteLabel"  class="back" @click="closeWindow" tabindex="0" @keyup.enter="closeWindow">{{ backBtnText }}</button>
      <img :src="whiteLabelLogo" v-else class="whiteLabelLogo" />
      <img src="../assets/general_lab_icons/Microscopy32.png" alt="Microscopy" class="lab-logo" />
      <h1 class="lab-name">{{ labratory }}</h1>
      
      <div v-if="isWhiteLabel" id="whiteLabelBLLogoWrapper">
        <span id="whiteLabelBLLogoText">Powered by</span>
        <img src="../assets/general_lab_icons/BL_lab_bar_logo.svg" id="whiteLabelBLLogo" />
      </div>
      <BLTooltip :text="tooltip.text" align="left" class="capture-icon-wrapper">
        <img role="button" :src="screenCaptureIcons.up" alt="Snapshot" id="captureIcon" tabindex="0" @keyup.enter="callCapture()" />
      </BLTooltip>
    </div>
  </header>
</template>
<script>
import BiologyLabEnum from '../enums/BiologyLabEnum';
import Constants from '../enums/Constants';
import * as StoreTypes from '../store/store-types';
import { mapGetters } from 'vuex';
import { lab_book } from '../components/LabBook/scripts/LabBookMiddleware';
import BLTooltip from './utilities/BLTooltip.vue';
import Cap_Up from '../assets/site_icons/ScreenCap_Up_36dp.svg';
import Cap_Down from '../assets/site_icons/ScreenCap_Down_36dp.svg';
import Cap_Over from '../assets/site_icons/ScreenCap_Over_36dp.svg';

export default {
  name: 'TopNavBar',
  data: function () {
    return {
      screenshotPressed: false,
      labIcon: 'general_lab_icons/Density32.png',
      labTitle: this.getLocaleData(
        BiologyLabEnum.MICROSCOPY,
        Constants.LAB_TITLE_KEY,
      ),
      backBtnText: this.getLocaleData(
        BiologyLabEnum.COMMON,
        Constants.BACK_BTN_LABEL_KEY,
      ),
      screenCaptureIcons: {
        up: Cap_Up,
        down: Cap_Down,
        hover: Cap_Over,
      },
      tooltip: {
        text: this.getLocaleData(BiologyLabEnum.MICROSCOPY, Constants.SAVE_LAB_IMAGE),
        theme: {
          placement: Constants.LEFT,
        },
      },
    };
  },
  methods: {
    checkIfUserSavedProgress: function () { },
    closeWindow: function () {
      if (window.top == window.self) {
        window.close();
      } else {
        parent.postMessage({ action: 'goodbye' }, '*');
      }
    },
    callCapture() {
      this.$parent.$refs.tray.captureEntireScreen();
    },
  },
  mounted: function () {
    const captureIcon = document.getElementById('captureIcon');
    captureIcon.addEventListener('pointerover', () => {
      captureIcon.src = this.screenCaptureIcons.hover;
    });
    captureIcon.addEventListener('pointerout', () => {
      captureIcon.src = this.screenCaptureIcons.up;
    });
    captureIcon.addEventListener('pointerdown', () => {
      captureIcon.src = this.screenCaptureIcons.down;
      this.screenshotPressed = true;
    });
    captureIcon.addEventListener('pointerup', () => {
      captureIcon.src = this.screenCaptureIcons.up;
      this.callCapture();
      this.screenshotPressed = false;
    });
  },
  beforeMount: function () {
    window.addEventListener('beforeunload', () => {
      lab_book('close');
    });
  },
  computed: {
    ...mapGetters({
      labratory: StoreTypes.GET_CURRENT_LAB,
      whiteLabelLogo: 'GET_WHITE_LABEL_LOGO',
    }),
    isWhiteLabel() {
      return this.whiteLabelLogo !== '';
    },
  },
  components: { BLTooltip },
};

</script>
<style scoped></style>

<style scoped src="../styles/TopNavBar.scss" lang="scss"></style>
