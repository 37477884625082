// getters
export const IS_READY = 'IS_READY';
export const STOCKROOM_ITEMS = 'STOCKROOM_ITEMS';
export const PANELS = 'PANELS';
export const GET_CURRENT_LAB = 'GET_CURRENT_LAB';
export const GET_CURRENT_LANGUAGE = 'GET_CURRENT_LANGUAGE';
export const SHOW_RED_DOT = 'SHOW_RED_DOT';
export const GET_USER_UUID = 'GET_USER_UUID';
export const PRESETS_USER = 'PRESETS_USER';
export const PRESETS_SYSTEM = 'PRESETS_SYSTEM';
export const PRESETS_AUTOLOAD = 'PRESETS_AUTOLOAD';
export const GET_ACTIVE_TAB = 'GET_ACTIVE_TAB';
export const GET_UPDATED_LIVE_DATA_TRIGGER = 'GET_UPDATED_LIVE_DATA_TRIGGER';
export const GET_SPRITE_SHEET = 'GET_SPRITE_SHEET';
export const GET_CURRENT_LAB_TYPE = 'GET_CURRENT_LAB_TYPE';
export const GET_SAVED_DATA_STATE = 'GET_SAVED_DATA_STATE';
export const GET_LIVE_DATA_OBJECT = 'GET_LIVE_DATA_OBJECT';
export const GET_LIVE_DATA_CANVAS_HEIGHT = 'GET_LIVE_DATA_CANVAS_HEIGHT';
export const EXTERNAL_PRESET_LOAD = 'EXTERNAL_PRESET_LOAD';
export const GET_EMAIL = 'GET_EMAIL';
export const GET_SPECIES = 'GET_SPECIES';
export const GET_CATEGORY_LIST = 'GET_CATEGORY_LIST';
export const GET_CURRENT_SPECIES = 'GET_CURRENT_SPECIES';
export const GET_CURRENT_MEDIA = 'GET_CURRENT_MEDIA';
export const GET_SAVE_LAB = 'GET_SAVE_LAB';
export const GET_RESTORE_LAB = 'GET_RESTORE_LAB';
export const GET_IMAGE_DATA = 'GET_IMAGE_DATA';
export const GET_SPECIES_ZOOM_POPUP_OPEN = 'GET_SPECIES_ZOOM_POPUP_OPEN';
export const GET_SPECIES_SELECTOR_INFORMATION = 'GET_SPECIES_SELECTOR_INFORMATION';
export const GET_SPECIAL_SPECIES_FILTER = 'GET_SPECIAL_SPECIES_FILTER';
export const GET_SPECIAL_MEDIA_LIST = 'GET_SPECIAL_MEDIA_LIST';

// mutations
export const LAB_READY_MUTATION = 'LAB_READY_MUTATION';
export const SET_PANEL_MUTATION = 'SET_PANEL_MUTATION';
export const SET_CURRENT_LAB_MUTATION = 'SET_CURRENT_LAB_MUTATION';
export const SHOW_RED_DOT_ON_UNKNOWN_MUTATION =
  'SHOW_RED_DOT_ON_UNKNOWN_MUTATION';
export const SET_PRESET_MUTATION = 'SET_PRESET_MUTATION';
export const SET_PRESET_TAB_MUTATION = 'SET_PRESET_TAB_MUTATION';
export const UPDATE_PRESET_MUTATION = 'UPDATE_PRESET_MUTATION';
export const SET_USER_UUID_MUTATION = 'SET_USER_UUID_MUTATION';
export const PRESET_LIST_MUTATION = 'PRESET_LIST_MUTATION';
export const LOAD_SPRITE_SHEET_MUTATION = 'LOAD_SPRITE_SHEET_MUTATION';
export const CLEAR_DATA_MUTATION = 'CLEAR_DATA_MUTATION';
export const SET_LIVE_DATA_OBJECT_MUTATION = 'SET_LIVE_DATA_OBJECT_MUTATION';
export const SAVE_LAB_MUTATION = 'SAVE_LAB_MUTATION';
export const PRESET_RESTORE_MUTATION = 'PRESET_RESTORE_MUTATION';
export const RESTORE_LAB_MUTATION = 'RESTORE_LAB_MUTATION';
export const SET_AUTOLOAD_PRESET_LOADED_MUTATION =
  'SET_AUTOLOAD_PRESET_LOADED_MUTATION';
export const SET_LIVE_DATA_CANVAS_HEIGHT_MUTATION =
  'SET_LIVE_DATA_CANVAS_HEIGHT_MUTATION';
export const EXTERNAL_PRESET_LOAD_MUTATION = 'EXTERNAL_PRESET_LOAD_MUTATION';
export const SET_EMAIL_MUTATION = 'SET_EMAIL_MUTATION';
export const SET_STATE_FROM_MIDDLEWARE_MUTATION = 'SET_STATE_FROM_MIDDLEWARE_MUTATION';
export const SET_CURRENT_SPECIES_MUTATION = 'SET_CURRENT_SPECIES_MUTATION';
export const SET_CURRENT_MEDIA_MUTATION = 'SET_CURRENT_MEDIA_MUTATION';
export const SET_IMAGE_DATA_MUTATION = 'SET_IMAGE_DATA_MUTATION';
export const SET_SPECIES_ZOOM_POPUP_OPEN_MUTATION = 'SET_SPECIES_ZOOM_POPUP_OPEN_MUTATION';
export const SET_SPECIES_SELECTOR_INFORMATION_MUTATION = 'SET_SPECIES_SELECTOR_INFORMATION_MUTATION';
export const SET_SPECIAL_SPECIES_FILTER_MUTATION = 'SET_SPECIAL_SPECIES_FILTER_MUTATION';
export const SET_SPECIAL_MEDIA_LIST_MUTATION = 'SET_SPECIAL_MEDIA_LIST_MUTATION';

// actions
export const LAB_READY_ACTION = 'LAB_READY_ACTION';
export const SET_PANEL_ACTION = 'SET_PANEL_ACTION';
export const SET_CURRENT_LAB_ACTION = 'SET_CURRENT_LAB_ACTION';
export const SHOW_RED_DOT_ON_UNKNOWN_ACTION = 'SHOW_RED_DOT_ON_UNKNOWN_ACTION';
export const SET_PRESET_TAB_ACTION = 'SET_PRESET_TAB_ACTION';
export const UPDATE_PRESET_ACTION = 'UPDATE_PRESET_ACTION';
export const SET_USER_UUID_ACTION = 'SET_USER_UUID_ACTION';
export const PRESET_LIST_ACTION = 'PRESET_LIST_ACTION';
export const LOAD_SPRITE_SHEET_ACTION = 'LOAD_SPRITE_SHEET_ACTION';
export const CLEAR_DATA_ACTION = 'CLEAR_DATA_ACTION';
export const SET_LIVE_DATA_OBJECT_ACTION = 'SET_LIVE_DATA_OBJECT_ACTION';
export const SAVE_LAB_ACTION = 'SAVE_LAB_ACTION';
export const PRESET_RESTORE_ACTION = 'PRESET_RESTORE_ACTION';
export const RESTORE_LAB_ACTION = 'RESTORE_LAB_ACTION';
export const SET_AUTOLOAD_PRESET_LOADED_ACTION =
  'SET_AUTOLOAD_PRESET_LOADED_ACTION';
export const SET_LIVE_DATA_CANVAS_HEIGHT_ACTION =
  'SET_LIVE_DATA_CANVAS_HEIGHT_ACTION';
export const EXTERNAL_PRESET_LOAD_ACTION = 'EXTERNAL_PRESET_LOAD_ACTION';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_STATE_FROM_MIDDLEWARE_ACTION = 'SET_STATE_FROM_MIDDLEWARE_ACTION';
export const SET_CURRENT_SPECIES_ACTION = 'SET_CURRENT_SPECIES_ACTION';
export const SET_CURRENT_MEDIA_ACTION = 'SET_CURRENT_MEDIA_ACTION';
export const SET_IMAGE_DATA_ACTION = 'SET_IMAGE_DATA_ACTION';
export const SET_SPECIES_ZOOM_POPUP_OPEN_ACTION = 'SET_SPECIES_ZOOM_POPUP_OPEN_ACTION';
export const SET_SPECIES_SELECTOR_INFORMATION_ACTION = 'SET_SPECIES_SELECTOR_INFORMATION_ACTION';
export const SET_SPECIAL_SPECIES_FILTER_ACTION = 'SET_SPECIAL_SPECIES_FILTER_ACTION';
export const SET_SPECIAL_MEDIA_LIST_ACTION = 'SET_SPECIAL_MEDIA_LIST_ACTION';
