<template>
  <div class="tooltip-box" @mouseover="toggleVisible(true)" @mouseleave="toggleVisible(false)">
    <slot />
    <div v-if="visible" class="tooltip" @mouseover.stop="toggleVisible(false)" :class="align">
      <span class="text">{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bl-tooltip',
  props: {
    text: {
      type: String,
      required: true,
    },
    align: {
      type: String,
    },
  },
  data: function () {
    return {
      visible: false,
    };
  },
  methods: {
    toggleVisible: function (isvisible) {
      this.visible = isvisible;
    },
  },
};
</script>
<style scoped lang="scss">
.tooltip-box {
  position: relative;
  display: inline-block;
  height: fit-content;

  .tooltip {
    color: #fff;
    text-align: center;
    padding: 5px 5px;
    border-radius: 2px;
    white-space: nowrap;
    // bottom: 100%; // Need to make this dynamic to adjust for align
    opacity: 0;
    transition: opacity 0.5s;
    position: absolute;
    z-index: 1000;
    width: fit-content;
    background: #6E6E6E;
    cursor: default;
  }

  .tooltip.left {
    top: 0;
    right: 100%;
  }

  .tooltip.top {
    left: 0;
    bottom: 100%;
  }

  .tooltip.topLeft {
    right: 0;
    bottom: 100%;
  }

  &:hover {
    .tooltip {
      opacity: 1;
    }
  }

  // .left {
  //   left: 0;
  // }

  // .right {
  //   right: 0;
  // }
}
</style>
