const errorAndWarningConstants = {
  FAILED_TO_LOAD_STOCKROOM_ITEMS: 'Failed to load stockroom items',
  FAILED_TO_PARSE_SHEET: 'Failed to parse sprite sheet',
  SOUND_ALREADY_PLAYING: 'Current sound is already playing',
  SOUND_ALREADY_STOPPED: 'Current sound is already stopped',
  SOUND_ALREADY_PAUSED: 'Current sound is already paused',
  SOUND_DESTROYED: 'Current sound is destroyed',
  NO_DATA_EXISTS_FOR_CUR_LANG: 'No data exists for the current language',
  MODULE_DOES_NOT_EXISTS: 'The given module does not exist',
  INVALID_KEY: 'The given key is invalid',
  MODULE_ALREADY_EXISTS: 'The given module already exists',
  CANNOT_CREATE_HIT_AREA_OF_SHAPE:
    'Cannot create a hit area of this particular shape type',
  JSON_DATA_NOT_DEFINED: 'JSON data is not defined',
  IMAGE_DATA_NOT_DEFINED: 'Image data is not defined',
  TEXTURE_NOT_FOUND: 'Texture not found: ',
};

export default Object.freeze(errorAndWarningConstants);
